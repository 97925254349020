import React, { useEffect, useState } from 'react'
import { Container, Card, Button, InputGroup, Form, Row, Col, Modal } from 'react-bootstrap'
import { NavLink } from 'react-router-dom';
import Sidebar from './sidebar';
import Line from '../assests/line.svg';
import { BsSearch } from "react-icons/bs";
import { IoIosAddCircleOutline } from "react-icons/io";
import SidebarMob from './siderbar_mobile';
import { useNavigate } from 'react-router-dom';
import plusbtn from '../assests/plus.svg';
import delete_img from '../assests/delete.svg'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { Box ,Typography } from '@mui/material';
import dayjs from 'dayjs';
import Usermenu from './user_menu';
import { useParams } from 'react-router-dom';
import axios from 'axios';
function PartiesUser() {

    const nevigate = useNavigate()
    let {id} = useParams();
    const [date, setDate] = useState(new Date());
    const [selectedDate, setSelectedDate] = useState(dayjs());
    const [collection, setCollection] = useState(false);
    const [show, setShow] = useState(false);
    const [user, SetUser] = useState([]);
    const [data,setData] = useState({})
    const [parties, setParties] = useState([]);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    const handleClosecollection = () => setCollection(false);
    const handlecollection = () => setCollection(true);


    const handlepssbook = (id) => {
       nevigate(`/passbook/${id}`)
    }
    const handlemonth = () => {
       nevigate('/montlybal')
    }
    
    const handleDateChange = (newDate) => {
        setSelectedDate(newDate);
    };
    const onChange = (newDate) => {
        setDate(newDate);
    };
    
    const getparties = async () => {
        await axios.get('https://freight.pigc.ca/backend/public/getparties')
            .then((response) => {
                if (response.data === 'error') {
                    setParties([])
                }
                else
                    setParties(response.data)
            })
            .catch((error) => {
                console.log(error);
            })
    }
    const getparty = ()=>{
        axios.post(`https://freight.pigc.ca/backend/public/getbuiltyparty/${id}`)
        .then((response)=>{
          console.log("getbuiltyone",response.data)
          if(response.data==="error"){
            SetUser([])
          }else{

              var balance=0;
              var trips = 0;
              for(let i=0;i<response.data.length;i++){
                  
                  balance += parseInt(response.data[i].party_trip_balance)
                  console.log(response.data[i].status , 'status')
                  if(response.data[i].status==1){
                    trips+=1;
                  }
                }
                setData({
                    'balance':balance,
                    'trips':trips,
                })
                SetUser(response.data)
          }
        })
        .catch((error)=>{
            console.log("error",error)
        })
    }

    useEffect(()=>{
        getparties();
        getparty();
    },[])
    return (
        <div>
            <Container fluid className='px-0 vh-100 d-flex'>
                <Sidebar />
                <SidebarMob />
                <div className='m-0 p-0 w-100 padding-20'>
                    <Container fluid className='d-flex justify-content-between px-5 mt-4 align-items-center'>
                        {/* {JSON.stringify(user[0].party)} */}
                        <h1 className='text-blue fw-6'>{parties?parties.find(partyItem => partyItem.id === user[0]?.party)?.name || "":''}</h1>
                        <div className="d-flex justify-content-end align-items-center">
                            <Button className='btn bg-blue fs-5 fw-6 text-white me-3 h-50 rounded-4' onClick={handleShow}><IoIosAddCircleOutline className='me-2 fs-4 mb-1'  />Add Payment</Button>
                            <NavLink to='/useradd' className='btn bg-black fs-5 fw-6 text-white me-3 h-50 rounded-4'><IoIosAddCircleOutline className='me-2 fs-4 mb-1' />Add Trip</NavLink>
                            <Usermenu/>
                        </div>
                    </Container>
                    <Card className='d-flex justify-content-between  mt-4 align-items-center mx-5 flex-row shadow shadow-sm rounded-4 px-2 py-4     '>
                        <div className="d-flex">
                            <Button  className=' bg-blue fs-5 fw-6 text-white me-3 h-50 rounded-4'>Trips</Button>
                            <Button  className=' bg-white fs-5 fw-6 text-blue border border-dark me-3 h-50 rounded-4' onClick={()=>handlepssbook(id)}>Passbook</Button>
                            {/* <Button to='/useradd' className=' bg-white fs-5 fw-6 text-blue border border-dark me-3 h-50 rounded-4' onClick={handlemonth}>Monthly Balance</Button>
                            <Button to='/useradd' className=' bg-white fs-5 fw-6 text-blue border border-dark me-3 h-50 rounded-4'>Party Details</Button> */}
                        </div>

                             <div className="d-flex">
                                <Button to='/useradd' className=' bg-white fs-5 fw-6 text-dark border border-dark ms-4 me-2 h-50 rounded-4'>Total Party Balance : <span className='text-blue ms-2'> {data.balance} Rs</span></Button>
                                <Button to='/useradd' className=' bg-white fs-5 fw-6 text-dark border border-dark  h-50 rounded-4 me-2'>Total Active Trips : {data.trips}</Button>
                                <Button to='/useradd' className='bg-blue fs-5 fw-6 text-white border border-dark  h-50 rounded-4' onClick={handlecollection}>Collection Reminder</Button>
                            </div>


                    </Card>
                    <Card className=' mx-5 mt-4 pb-4 shadow shadow-md ' >
                        <div className='d-flex justify-content-end align-items-center mt-4 pe-3 border-bottom '>
                            {/* <div className="d-flex">
                                <Button to='/useradd' className=' bg-white fs-5 fw-6 text-dark border border-dark ms-4 me-2 h-50 rounded-4'>Total Party Balance : <span className='text-blue ms-2'> {data.balance} Rs</span></Button>
                                <Button to='/useradd' className=' bg-white fs-5 fw-6 text-dark border border-dark  h-50 rounded-4'>Total Active Trips : {data.trips}</Button>

                            </div> */}
                            <div className='text-end'>
                                <InputGroup className="mb-3 mt-3">
                                    <Form.Control
                                        placeholder="Search.."
                                        aria-label="search"
                                        aria-describedby="basic-addon2"
                                    />
                                    <InputGroup.Text id="basic-addon2"><BsSearch /></InputGroup.Text>
                                </InputGroup>
                            </div>
                        </div>
                        <div className="table-responsive px-2 pt-4">

                        <table className='table table-striped  table-hover'>
                            <thead>
                              <tr>
                                <th><h4>#</h4></th>
                                <th><h4>Start Date</h4></th>
                                <th><h4>Truck No</h4></th>
                                <th><h4>Route</h4></th>
                                <th><h4>Status</h4></th>
                                <th><h4>Party Balance</h4></th>
                                {/* <th><h4>Action</h4></th> */}
                              </tr>
                            </thead>
                            <tbody >
                                {user.length>0? user.slice().reverse().map((item,index)=>(

                                <tr className='pt-4' key={index}>
                                <td ><h5 className='mt-2'>{index+1}</h5></td>
                                <td><h5 className='mt-2'>{item.start_date}</h5></td>    
                                <td><h5 className='mt-2'>{item.reg_no}</h5></td>
                                <td><h5 className='mt-2'>{item.start_loc} to {item.destination}</h5></td>
                                <td><h5 className='mt-2'>{item.status==2?"Completed":"In process"}</h5></td>
                                <td><h5 className='mt-2'>{item.party_trip_balance} Rs</h5></td>
                                {/* <td><h5 className='btn text-blue '>View Detail</h5></td> */}
                                </tr>
                                )):<tr className='pt-4 text-center' >
                            
                                <td colSpan={6}><h5 className='mt-2'>No Trip Found</h5></td>
                            
                                </tr>}
                            </tbody>
                        </table>
                        </div>
            <div className="text-end">
            <Button  className=' bg-danger fs-5 fw-6 text-white me-3 rounded-4 mt-3'>View PDF</Button>
            </div>
                    </Card>
                </div>
            </Container>
         
            <Modal className='rounded-5' show={collection} onHide={handleClosecollection} animation={false} centered size='lg'>
                <Card className='py-5 ps-5 pe-3 '>
                    <Row>
                        <Col md={6} className='border-end pe-4'>
                            <h3 className='text-dark'>Collection Reminder</h3>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <Box className='date-calendar-container '>
                                    <Box className='date-calendar-header'>
                                        <Typography variant="h6" className='date-calendar-title  w-100'>
                                            {selectedDate.format('ddd, MMM D')}
                                        </Typography>
                                    </Box>
                                    <DateCalendar
                                        value={selectedDate}
                                        onChange={handleDateChange} />
                                    <Box className='date-calendar-footer d-flex justify-content-end '>
                                        <Button className='date-calendar-button me-2 bg-white fw-6'>Cancel</Button>
                                        <Button className='date-calendar-button bg-white text-blue fw-6'>OK</Button>
                                    </Box>
                                </Box>
                            </LocalizationProvider>

                        </Col>
                        <Col md={6}>
                            <div className='d-flex justify-content-between align-items-center w-100'>
                                <h5 className='text-dark '>Create Reminders</h5>
                                <img src={plusbtn} alt='image--' className='' height={30} />
                            </div>
                            <div className='d-flex mt-5 justify-content-between align-items-center w-100'>
                                <h5 className='text-dark '>Create Reminders</h5>
                                <img src={delete_img} alt='image--' className='' height={30} />
                            </div>
                        </Col>


                    </Row>
                </Card>

            </Modal>

            <Modal className='rounded-5 ' show={show} onHide={handleClose} centered>
                <Modal.Header className='rounded-top-4 bg-blue text-white' >
                    <h2 className='fs-1'>Payment Details</h2>
                </Modal.Header>
                <div className='px-3 mt-4'>
                    <Card className='px-4 py-4 mb-3 d-flex flex-row justify-content-between alignt-items-center shadow shadow-md rounded-4'>
                        <div>
                            <h4 className=' mb-0 fw-6 text-gray'>Party Name</h4>
                            <h3 className='fw-6 mb-3'>Ishan Sharma</h3>
                            <h4 className='mb-0 fw-6 text-gray'>Payment Date</h4>
                            <h3 className='fw-6 mb-3'>05 Aug 2024</h3>
                            <h4 className='mb-0 fw-6 text-gray'>Payment method</h4>
                            <h3 className='fw-6 mb-3'>UPI</h3>
                            <h4 className='mb-0 fw-6 text-gray'>Notes</h4>
                            <h3 className='fw-6 mb-3'>_</h3>
                        </div>
                        <div>
                            <h4 className='mb-0 fw-6 text-gray'>Payment Amount</h4>
                            <h3 className='fw-6 mb-3'>₹12,000</h3>
                            <h4 className='mb-0 fw-6'>Reference Number</h4>
                            <h3 className='fw-6 '>12</h3>
                        </div>
                    </Card>
                </div>
                <hr className='mb-0' />
                <div className='px-4  '>
                    <div className='d-flex justify-content-between p-3'>
                        <h4 className='mb-0 fw-6 text-gray'>Trip Route</h4>
                        <h4 className='mb-0 fw-6 text-gray'>Amount</h4>
                    </div>
                    <Card className='px-4 py-4 mb-3 d-flex flex-row justify-content-between alignt-items-center shadow shadow-md rounded-4'>
                        <div className='d-flex'>
                            <div className='d-flex flex-column'>
                                <h4 className='fw-6 mb-1'>Mumbai</h4>
                                <h6 className='fw-6 text-gray'>02 Aug 2024</h6>
                            </div>
                            <img src={Line} alt='image--' />
                            <div className='d-flex flex-column'>
                                <h4 className='fw-6 mb-1'>Raipur</h4>
                                <h6 className='fw-6 text-gray'>HR 03D 0324</h6>
                            </div>
                        </div>
                        <h4 className='fw-6 mt-3'>₹12,000</h4>

                    </Card>
                </div>
                <Modal.Footer className='border-0'>
                    <Button className=' bg-white text-blue border_btn' onClick={handleClose}>
                        Close
                    </Button>
                    <Button className='bg-danger' onClick={handleClose}>
                        Delete
                    </Button>
                </Modal.Footer>
            </Modal>

        </div>
    )
}

export default PartiesUser;