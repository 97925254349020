import React, { useEffect, useState } from 'react'
import { Button, Card, Col, Container, Row, Modal, Form } from 'react-bootstrap'
import { NavLink, useParams } from "react-router-dom";

import Sidebar from './sidebar';
import SidebarMob from './siderbar_mobile';


import user_logo from '../assests/user-logo.svg';
import truck from '../assests/truck2.svg';
import right_arrow from '../assests/right_arrow.svg'
import steering from '../assests/steering.svg';
import start_progress from '../assests/started_progress.svg';
import pod_complete_progress from '../assests/pod_complete_progress.svg';
import pod_recived_progress from '../assests/pod_received_progress.svg';
import completed_progress from '../assests/completed_progress.svg';
import settled_progress from '../assests/settled_progress.svg'
import navigation_bar from '../assests/navigation_bar.svg'
import upload_pod from '../assests/upload_pod.svg'
import mail_icon from '../assests/mail_icon.svg'
import Usermenu from './user_menu';
import axios from 'axios';
import DOMPurify from 'dompurify';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Bilty_LR from './bilty_lr';
import html2pdf from 'html2pdf.js';


function TripsOne() {
    const [currStepBtn, setCurrStepBtn] = useState('Complete Trip')
    const [completeTripPopUp, setCompleteTripPopUp] = useState(false)
    const [podReceivedPopUp, setPodReceivedPopUp] = useState(false)
    const [podSubmittedPopUp, setPodSubmittedPopUp] = useState(false)
    const [settleAmount, setSettleAmount] = useState(false)
    const [cyan, setCyan] = useState("trip_complete_btn")
    const [user, SetUser] = useState([]);
    const [party, Setparty] = useState([]);
    const [driver, Setdriver] = useState([]);
    const [trip, setTrip] = useState([]);
    const [imagePreview, setImagePreview] = useState(null);
    const [show, setShow] = useState(false);
    const [expenses, setExpenses] = useState([]);
    const [totalexpense, setTotalExpense] = useState();
    const [passbook, setpassbook] = useState([]);

    let { id } = useParams()
    const [form, setForm] = useState({ "builty_id": id });
    const [form2, setForm2] = useState({});
    const [LR, setLR] = useState('CONSIGNOR')
    const getUser = () => {
        axios.post(`https://freight.pigc.ca/backend/public/getbuiltyuser/${id}`)
            .then((response) => {
                //console.log("getbuilty",response.data[0])
                SetUser(response.data[0])
                // getparty(response.data[0].party, response.data[0].drivers)
                getparty(response.data[0].party)
                const sanitizedValue = DOMPurify.sanitize(response.data[0].party_trip_balance);
                setForm({ ...form, "settle_amount": sanitizedValue, "party_id": response.data[0].party });
            })
            .catch((error) => {
                console.log("error", error)
            })
    }
    // const getparty = (party, drivers) => {
    //     axios.post(`https://freight.pigc.ca/backend/public/getpartydriver/${party}/${drivers}`)
    //         .then((response) => {

    //             console.log("partyyydataaaa",response.data.party)
    //             Setparty(response.data.party[0])
    //             Setdriver(response.data.driver[0])
    //         })
    //         .catch((error) => {
    //             console.log("error", error)
    //         })
    // }
    const getparty = (party) => {
        axios.post(`https://freight.pigc.ca/backend/public/getpartiesone/${party}`)
            .then((response) => {

                // console.log("partyyydataaaa", response.data, party)
                Setparty(response.data[0])
                // Setdriver(response.data.driver[0])
            })
            .catch((error) => {
                console.log("error", error)
            })
    }

    const triggerFileInput = () => {
        document.getElementById('fileInput').click();
    };
    function handleTripSteps() {
        if (currStepBtn == 'Complete Trip') { setCompleteTripPopUp(true); }
        else if (currStepBtn == 'POD Received') { setPodReceivedPopUp(true); }
        else if (currStepBtn == 'POD Submitted') { setPodSubmittedPopUp(true); }
        else if (currStepBtn == 'Settle Amount') { setSettleAmount(true); }
    }
    function handleClose() {
        if (completeTripPopUp == true) {
            setCompleteTripPopUp(false);
            setCurrStepBtn('POD Received');
            setForm({
                ...form,
                'step': 1,
            })
        }
        else if (podReceivedPopUp == true) {
            setPodReceivedPopUp(false);
            setCurrStepBtn('POD Submitted');
        }
        else if (podSubmittedPopUp == true) {
            setPodSubmittedPopUp(false);
            setCurrStepBtn('Settle Amount');
        }
        else if (settleAmount == true) {
            setSettleAmount(false);
            setCurrStepBtn('View Bill');
            setCyan("btn-cyan");
        }
    }
    function handleShow() {
        setShow(true)

    }
    function handleClose() {
        setShow(false)

    }
    function handleClose1() {
        setCompleteTripPopUp(false)

    }
    function handleClose2() {
        setPodReceivedPopUp(false)

    }
    function handleClose3() {
        setPodSubmittedPopUp(false)

    }
    function handleClose4() {
        setSettleAmount(false)

    }
    const handleinput = (e) => {
        const { name, files, value, checked } = e.target;

        if (name === 'image' && files && files[0]) {
            const file = files[0];

            setForm({ ...form, [name]: file });

            const reader = new FileReader();
            reader.onloadend = () => {
                setImagePreview(reader.result);
            };
            reader.readAsDataURL(file);

        } else {
            const sanitizedValue = DOMPurify.sanitize(value);
            setForm({ ...form, [name]: sanitizedValue });

        }



    }
    const handleinput2 = (e) => {
        const { name, files, value, checked } = e.target;

        if (name === 'image' && files && files[0]) {
            const file = files[0];

            setForm2({ ...form2, [name]: file });

            const reader = new FileReader();
            reader.onloadend = () => {
                setImagePreview(reader.result);
            };
            reader.readAsDataURL(file);

        } else {
            const sanitizedValue = DOMPurify.sanitize(value);
            setForm2({ ...form2, [name]: sanitizedValue });

        }


    }
    const handlesubmit = (e, status) => {
        e.preventDefault()
        const newid = toast.loading("Please wait...")
        axios.post(`https://freight.pigc.ca/backend/public/addtripdetail/${id}/${status}`, form, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
            .then((response) => {
                if (response.data === "success") {
                    // setTimeout(() => {
                    toast.update(newid, {
                        render: "Updated Successfully !",
                        type: "success",
                        isLoading: false,
                        icon: true,
                        autoClose: 2000,
                        closeButton: true,
                        closeOnClick: true,
                    })
                    // }, 1000);

                    // notify()
                    setTimeout(() => {
                        // window.location.reload();
                        getUser();
                        getTripDetails();
                        getExpenses();
                        getPassBook();
                        getDriver();
                    }, 2000)
                } else {
                    setTimeout(() => {
                        toast.update(newid, {
                            render: "Something went wrong !!",
                            type: "error",
                            isLoading: false,
                            icon: true,
                            autoClose: 2000,
                            closeButton: true,
                            closeOnClick: true,
                        })
                    }, 1000);
                    // notify_error()
                }

            })
            .catch((error) => {

                console.log(error)
            })
    }
    const handleSubmit2 = (e) => {
        e.preventDefault()
        setShow(false)
        const newid = toast.loading("Please wait...")
        axios.post(`https://freight.pigc.ca/backend/public/addexpense/${id}`, form2, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
            .then((response) => {
                if (response.data === "success") {
                    // setTimeout(() => {
                    toast.update(newid, {
                        render: "Updated Successfully !",
                        type: "success",
                        isLoading: false,
                        icon: true,
                        autoClose: 2000,
                        closeButton: true,
                        closeOnClick: true,
                    })
                    // }, 1000);

                    // notify()
                    setTimeout(() => {
                        getExpenses();
                    }, 3000)
                } else {
                    setTimeout(() => {
                        toast.update(newid, {
                            render: "Something went wrong !!",
                            type: "error",
                            isLoading: false,
                            icon: true,
                            autoClose: 2000,
                            closeButton: true,
                            closeOnClick: true,
                        })
                    }, 1000);
                    // notify_error()
                }

            })
            .catch((error) => {

                console.log(error)
            })
    }

    const getTripDetails = () => {
        axios.get(`https://freight.pigc.ca/backend/public/gettripdetail/${id}`)
            .then((response) => {
                setTrip(response.data[0])
                if (response.data[0].step == 1) {
                    console.log("end trip completed")
                    setCompleteTripPopUp(false);
                    setCurrStepBtn('POD Received');
                } else if (response.data[0].step == 2) {
                    setPodReceivedPopUp(false);
                    setCurrStepBtn('POD Submitted');
                } else if (response.data[0].step == 3) {
                    setPodSubmittedPopUp(false);
                    setCurrStepBtn('Settle Amount');
                } else if (response.data[0].step == 4) {
                    setSettleAmount(false);
                    setCurrStepBtn('View Bill');
                    setCyan("btn-cyan");
                } else {

                    // setCompleteTripPopUp(true);
                    // setCurrStepBtn('Complete Trip');
                }
            })
            .catch((error) => {
                console.log("error", error)
            })
    }

    const getExpenses = () => {
        axios.get(`https://freight.pigc.ca/backend/public/getexpenses/${id}`)
            .then((response) => {

                if (response.data === 'error') {
                    setExpenses([])
                }
                else {

                    setExpenses(response.data)
                    var total_expense = 0;
                    for (let i = 0; i < response.data.length; i++) {
                        total_expense += parseInt(response.data[i].expense_amount)
                        // console.log(total_expense);
                    }
                    setTotalExpense(total_expense);
                }

            })
            .catch((error) => {
                console.log("error", error)
            })
    }
    const getPassBook = () => {
        axios.get(`https://freight.pigc.ca/backend/public/getpassbook/${id}`)
            .then((response) => {
                // console.log("passbook", passbook)
                if (response.data === 'error') {
                    setpassbook([])
                }
                else {

                    setpassbook(response.data)
                }

            })
            .catch((error) => {
                console.log("error", error)
            })
    }
    const getDriver = (e) => {
        axios.get(`https://freight.pigc.ca/backend/public/getdriver/`)
            .then((response) => {
                // console.log("driver", response.data)
                if (response.data === "error") {
                    Setdriver([])
                } else {

                    Setdriver(response.data)
                }
            })
            .catch((error) => {
                console.log(error)
            })
    }
    const generatePDF = () => {
        const element = document.getElementById('targetLR');
        if (!element) {
            console.error('Element with id "targetLR" not found.');
            return;
        }

        const opt = {
            margin: 0.1, // Adjust margins as needed
            filename: 'LR.pdf',
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { scale: 3, logging: true }, // Increase scale for better quality
            jsPDF: { unit: 'in', format: 'letter', orientation: 'landscape', pageBreak: { mode: 'avoid-all' } }, // Avoid breaking content if possible
            pagebreak: { mode: 'avoid-all' } // Avoid breaking content if possible
        };
        html2pdf().from(element).set(opt).save();
    };

    useEffect(() => {
        getUser();
        getTripDetails();
        getExpenses();
        getPassBook();
        getDriver();
    }, [])
    return (
        <div>
            <Container fluid className='px-0 d-flex vh-100'>
                <Sidebar />
                <SidebarMob />
                <ToastContainer
                    position="top-right"
                    autoClose={2000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable

                    theme="dark"
                />

                {/* {JSON.stringify(form)} */}
                <div className='m-0 p-0 w-100 padding-20'>
                    {/* <h3>{JSON.stringify(user)}</h3> */}
                    <Container fluid className='d-flex justify-content-between align-items-center px-5 mt-4  '>
                        <h1 className='text-blue fw-6'>Bilty</h1>
                        <Usermenu />

                    </Container>
                    <Container className='px-5 mt-0 '>
                        <Row>
                            <Col lg={8} className='overflowy' style={{ maxHeight: '82vh' }}>
                                <Row>
                                    <Col lg={6}>
                                        <Card className='ps-4 pe-4 py-1 mb-3 d-flex flex-row  align-items-center shadow shadow-md rounded-4 '>
                                            <img src={truck} alt='image--' className='driver-icon' height={45} />
                                            <div className='d-flex flex-column   w-100  ms-3 driver-info'>
                                                <h6 className='text-gray p-0 m-0'>Truck’s No.</h6>
                                                <h4 className='m-0 p-0 text-dark'>{user.reg_no}</h4>
                                            </div>
                                            <img src={right_arrow} alt='image--' className='' height={20} />
                                        </Card>
                                    </Col>
                                    <Col lg={6}>
                                        <Card className='ps-4 pe-4 py-1 mb-3 d-flex flex-row  align-items-center shadow shadow-md rounded-4'>
                                            <img src={steering} alt='image--' className='driver-icon' height={45} />
                                            <div className='d-flex flex-column w-100 ms-3 driver-info'>
                                                <h6 className='text-gray p-0 m-0'>Driver’s Name</h6>
                                                <h4 className='m-0 p-0 text-dark'>{driver.find(partyItem => partyItem.id === user.drivers)?.name || ""}</h4>
                                            </div>
                                            <img src={right_arrow} alt='image--' className='' height={20} />
                                        </Card>
                                    </Col>

                                    <Col lg={3}>
                                        <Card className='px-0  pt-3 pb-2 my-1 d-flex justify-content-center align-items-center shadow shadow-md rounded-4 party-name'>
                                            <img src={`https://smarto.s3.ap-south-1.amazonaws.com/freight/parties/${party.image}`} alt='image' className=' rounded-5' height={100} width={130} />
                                            <h5 className='text-gray mt-2 mb-0'>Party Name</h5>
                                            <h4 className='p-0 mt-0'>{party.name}</h4>
                                        </Card>
                                    </Col>
                                    <Col lg={3}>
                                        <Card className=' ps-2 pt-3 pb-2 my-1 d-flex justify-content-center align-items-center shadow shadow-md rounded-4 party-info'>
                                            <h5 className='text-gray  mb-1 w-100 fw-5'>Party Balance</h5>
                                            <h4 className='mb-3 w-100 text-dark'>Rs {party.balance}</h4>
                                            <h5 className='text-gray mb-1 w-100 fw-5'>LR Number</h5>
                                            <h4 className='mb-3 w-100 text-dark '>{user.lr_no}</h4>
                                            <h5 className='text-gray mb-1 w-100 fw-5'>Material</h5>
                                            <h4 className='mb-1 w-100 text-dark '>{user.material_name}</h4>
                                        </Card>
                                    </Col>
                                    <Col lg={6}>
                                        <Row>
                                            <Col lg={12}>
                                                <Card className='px-2 py-4 mb-2 mt-1 d-flex flex-row justify-content-between  align-items-center shadow shadow-md rounded-4'>
                                                    <div>
                                                        <h6 className='mb-1 text-dark'>{user.start_loc}</h6>
                                                        <h6 className='fs-4b text-gray fw-5'>{user.start_date}</h6>
                                                    </div>
                                                    <div>
                                                        <img src={navigation_bar} alt='image--' className='mb-2' />
                                                    </div>
                                                    <div>
                                                        <h6 className='mb-1  text-end text-dark'>{user.destination}</h6>
                                                        <h6 className='fs-4b text-gray fw-5'>{trip.end_date ? trip?.end_date : "In progress"}</h6>
                                                    </div>

                                                </Card>
                                            </Col>
                                            <Col lg={12}>
                                                <Card className='px-2 py-4 mb-3 d-flex flex-row justify-content-between  align-items-center shadow shadow-md rounded-4'>
                                                    <div className=''>
                                                        <h6 className='fs-4b text-gray fw-5 mb-0'>Starting KMs reading</h6>
                                                        <h6 className='mt-1'>{user.start_reading}Km</h6>

                                                    </div>
                                                    <div></div>
                                                    <div>
                                                        <h6 className='fs-4b text-gray fw-5 mb-0'>End KMs Reading</h6>
                                                        <h6 className='mt-1  text-end'>{trip.end_km ? trip?.end_km + "Km" : "In progress"}</h6>

                                                    </div>

                                                </Card>
                                            </Col>

                                        </Row>
                                    </Col>
                                    <Col lg={12}>
                                        <Card className='px-3 py-4 mb-1 mt-0 d-flex flex-column justify-content-between  align-items-center shadow shadow-md rounded-4 progress_bar'>
                                            <img src={start_progress} alt='Started' className={"started_progress " + (currStepBtn === 'Complete Trip' ? '' : 'd-none')} />
                                            <img src={completed_progress} alt='Started' className={"completed_progress " + (currStepBtn === 'POD Received' ? '' : 'd-none')} />
                                            <img src={pod_recived_progress} alt='Started' className={"pod_recived_progress " + (currStepBtn === 'POD Submitted' ? '' : 'd-none')} />
                                            <img src={pod_complete_progress} alt='Started' className={"pod_complete_progress " + (currStepBtn === 'Settle Amount' ? '' : 'd-none')} />
                                            <img src={settled_progress} alt='Started' className={"settled_progress " + (currStepBtn === 'View Bill' ? '' : 'd-none')} />

                                            <div className='d-flex flex-row justify-content-between  align-items-baseline  pt-3 mb-1 mt-0 gap-5 progress_bar_items'>
                                                <div className=' text-center'>
                                                    <h6 className='mb-1 text-dark'>Started</h6>
                                                    <h6 className=' text-gray fw-5'>2 August 2024</h6>
                                                </div>
                                                <div className=' text-center ps-3'>
                                                    <h6 className='mb-1   text-dark'> Completed</h6>
                                                    <h6 className='text-gray fw-5'>7 August 2024</h6>
                                                </div>
                                                <div className=' text-center'>
                                                    <h6 className='mb-1   text-dark'> POD Received</h6>
                                                </div>
                                                <div className=' text-center'>
                                                    <h6 className='mb-1   text-dark'> POD Submitted</h6>
                                                </div>
                                                <div className=' text-center'>
                                                    <h6 className='mb-1   text-dark'> Settled</h6>
                                                    <h6 className='text-gray fw-5'>7 August 2024</h6>
                                                </div>
                                            </div>

                                            <button className={`mt-2 rounded-3 fw-6 fs-3  ${cyan}`} onClick={handleTripSteps} >{currStepBtn}</button>
                                        </Card>
                                    </Col>
                                    <Col lg={12}>
                                        <Card className='px-3 py-3 mb-2 mt-1 d-flex flex-column justify-content-between   align-items-baseline shadow shadow-md rounded-4'>
                                            <h3 className=''>Payment History</h3>
                                            {/* <h3 className='text-dark'>₹ {user.freight_amount}</h3> */}
                                            <div className="container-fluid px-0">

                                                <Card className='card-color border-0 rounded-4'>

                                                    <table className='table'>
                                                        <thead>
                                                            <tr>
                                                                <th><h4 className='fw-6'>#</h4></th>
                                                                <th><h4 className='fw-6'>Payment Date</h4></th>
                                                                <th><h4 className='fw-6'>Payment Received</h4></th>
                                                                <th><h4 className='fw-6'>Payment Method</h4></th>
                                                                <th><h4 className='fw-6'>Balance</h4></th>

                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {passbook.length > 0 ?
                                                                passbook.slice().reverse().map((item, index) => (
                                                                    <tr key={index}>
                                                                        <td className=''><h6 className='fs-4b  fw-5'>{index + 1}</h6></td>
                                                                        <td className=''> <h6 className='fs-4b  fw-5'>{item.payment_date ? item.payment_date : "No Record Found"}</h6></td>
                                                                        <td className='text-center'> <h6 className='fs-4b  fw-5'>{item.payment_amount_paid ? item.payment_amount_paid : "No Record Found"}</h6></td>
                                                                        <td className='text-center'><h6 className='fs-4b  fw-5'>{item.payment_method ? item.payment_method : "No Record Found"}</h6></td>
                                                                        <td className='text-center'> <h6 className='fs-4b  fw-5'>{item.builty_balance ? item.builty_balance : "No Record Found"}</h6></td>
                                                                    </tr>

                                                                )) : <tr >
                                                                    <td colSpan={5} className='text-center'><h6 className='fs-4b  fw-5'>No Record Found</h6></td>
                                                                </tr>}
                                                        </tbody>
                                                    </table>


                                                </Card>
                                            </div>
                                            <div className='horizontal-line'></div>
                                            <h4 className='text-gray mt-2'>Pending Trip Party Balance</h4>
                                            <h3 className='text-dark'>₹ {user.party_trip_balance}</h3>
                                        </Card>
                                    </Col>

                                </Row>
                            </Col>
                            <Col lg={4}>
                                <Row>
                                    <Col lg={12}>
                                        <Card className='px-3 py-3 mb-2 mt-1 d-flex flex-column justify-content-between    align-items-baseline shadow shadow-md rounded-4'>
                                            <div className="d-flex justify-content-between px-0 w-100 mb-2">
                                                <h3 className='text-dark'>Trip Profit</h3>
                                                {user.status == 1 ? <Button className='btn btn-sm bg-blue  text-white fw-6  h-50 rounded-4' onClick={handleShow}>Add Expenses</Button> : ''}
                                                {/* <Button className='btn btn-sm bg-blue  text-white fw-6  h-50 rounded-4' onClick={handleShow}>Add Expenses</Button> */}
                                            </div>

                                            <div className='d-flex mb-1 justify-content-between align-items-center  w-100 profit'>
                                                <h4 className='text-dark fs-2'>(+) Revenue</h4>
                                                <h4 className='text-dark fs-2'>₹ {user.freight_amount}</h4>
                                            </div>
                                            <div className='d-flex justify-content-between align-items-center w-100 profit mb-2'>
                                                <h4 className='text-gray'>{party.name}</h4>
                                                <h4 className='text-gray '>₹ {user.party_trip_balance}</h4>
                                            </div>

                                            <div className='d-flex mb-3 justify-content-between align-items-center w-100 profit'>
                                                <h4 className='text-dark fs-2'>(-) Expenses</h4>
                                                <h4 className='text-dark fs-2'>₹ {parseInt(totalexpense)}</h4>
                                            </div>
                                            {expenses.map((item, index) => (
                                                <div className='d-flex justify-content-between align-items-center w-100 profit' key={index}>
                                                    <h4 className='text-gray'>{item.expense_type}</h4>
                                                    <h4 className='text-gray '>₹ {item.expense_amount}</h4>
                                                </div>
                                            ))}
                                            <div className='horizontal-line'></div>
                                            <div className='d-flex mt-3 mb-5 justify-content-between align-items-center w-100 profit'>
                                                <h4 className='text-gray-light fs-2'>Profit</h4>
                                                <h4 className='text-gray-light fs-2'>₹ {totalexpense ? parseInt(user.freight_amount) - parseInt(totalexpense) : parseInt(user.freight_amount)}</h4>
                                            </div>
                                        </Card>
                                    </Col>

                                    <Col lg={12}>
                                        <Card className='px-3 py-4 mb-2 mt-1 d-flex flex-row justify-content-between  align-items-center shadow shadow-md rounded-4'>
                                            <h4 className='  w-100'>POD Challan</h4>
                                            <div className='container-fluid d-flex justify-content-end  my-2 px-0 ' >
                                                <NavLink to='/' className='btn  fs-5 fw-5 bg-blue text-white rounded-4'>Add Pod</NavLink>
                                            </div>
                                        </Card>
                                    </Col>

                                    <Col lg={12}>
                                        <Card className='px-3 py-4 mb-2 mt-1 d-flex flex-row justify-content-between  align-items-center shadow shadow-md rounded-4'>
                                            <h4 className='  w-100'>Generate Bilty</h4>
                                            <div className='container-fluid d-flex justify-content-end  my-2 px-0 ' >
                                                <button type="button" className="btn btn-danger fs-5 fw-5 rounded-4" data-bs-toggle="modal" data-bs-target="#exampleModal">
                                                    Generate
                                                </button>
                                            </div>
                                        </Card>
                                    </Col>

                                </Row>
                            </Col>

                        </Row>
                    </Container>
                </div>
            </Container >
            <Modal show={completeTripPopUp} onHide={handleClose} centered>
                <Form onSubmit={(e) => handlesubmit(e, 1)}>
                    {/* <Modal.Header className='' > */}
                    {/* </Modal.Header> */}

                    <div className='p-4'>
                        <h3 className='text-dark'>Mark Delivered</h3>
                        <Row className='mt-4'>
                            <Col sm={6}>
                                <h6 className="fw-5 text-gray ">Trip End Date</h6>
                                <input type="date" className="form-control rounded-4 mb-1 " placeholder='Expiry Date' id="trip_end_date" name="end_date" onChange={handleinput} />
                            </Col>

                        </Row>
                    </div>
                    <Modal.Footer className='border-0'>
                        <Button className='bg-white fw-6 py-1 px-4 text-blue border_btn' onClick={handleClose1}>
                            Close
                        </Button>
                        <Button className='bg-blue fw-6 py-1 px-4' onClick={handleClose} type='submit'>
                            Mark Delivered
                        </Button>
                    </Modal.Footer>
                </Form >
            </Modal>

            <Modal show={podReceivedPopUp} onHide={handleClose} centered>
                <Form onSubmit={(e) => handlesubmit(e, 2)}>
                    <div className='p-4'>
                        <h3 className='text-dark'>POD Received</h3>
                        <Row className='mt-1 g-3'>
                            <Col sm={12}>
                                <h6 className="fw-5 text-gray ">POD Received On</h6>
                                <input type="date" className="form-control rounded-4 mb-1 " placeholder='Expiry Date' id="trip_end_date" name='pod_receive_date' onChange={handleinput} />
                            </Col>
                            <Col sm={12}>
                                {/* <img className='pointer' src={upload_pod} alt='image--' height={30} /> */}
                                <img
                                    src={imagePreview || upload_pod}
                                    alt="image-preview"
                                    className="rounded-5"
                                    style={{ cursor: 'pointer', maxWidth: "150px" }}
                                    onClick={triggerFileInput}
                                />
                                <input
                                    type="file"
                                    id="fileInput"
                                    style={{ display: 'none' }}
                                    accept="image/*"
                                    name="image"
                                    onChange={handleinput}
                                />
                            </Col>
                        </Row>
                    </div>
                    <Modal.Footer className='border-0'>
                        <Button className='bg-white fw-6 py-1 px-4 text-blue border_btn' onClick={handleClose2}>
                            Close
                        </Button>
                        <Button className='bg-blue fw-6 py-1 px-4' onClick={handleClose} type='submitted'>
                            POD Delivered
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>

            <Modal show={podSubmittedPopUp} onHide={handleClose} centered>
                <Form onSubmit={(e) => handlesubmit(e, 3)}>
                    <div className='p-4'>
                        <h3 className='text-dark'>Mark POD Submitted</h3>
                        <Row className='mt-1 g-3'>
                            <Col sm={12}>
                                <h6 className="fw-5 text-gray ">POD Submitted On</h6>
                                <input type="date" className="form-control rounded-4 mb-1 " placeholder='Expiry Date' id="trip_end_date" name='pod_submit_date' onChange={handleinput} />
                            </Col>
                            <Col sm={12}>
                                <img className='me-2' src={mail_icon} alt='image--' height={18} />
                                <span className="fw-5 me-2 text-dark">Payment Reminder After</span>
                                <select className='border-dark p-1 fw-5 rounded-3' name='pay_reminder_day' onChange={handleinput}>
                                    <option value="1">1 Day</option>
                                    <option value="3">3 Days</option>
                                    <option selected value="7">7 Days</option>
                                    <option value="15">15 Days</option>
                                    <option value="30">30 Days</option>
                                </select>
                            </Col>
                        </Row>
                    </div>
                    <Modal.Footer className='border-0'>
                        <Button className='bg-white fw-6 py-1 px-4 text-blue border_btn' onClick={handleClose3}>
                            Close
                        </Button>
                        <Button className='bg-blue fw-6 py-1 px-4' onClick={handleClose} type='submit'>
                            POD Submitted
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>

            <Modal show={settleAmount} onHide={handleClose} centered>

                <Form onSubmit={(e) => handlesubmit(e, 4)}>
                    <Modal.Header className='bg-blue text-white' >
                        <h3 className='mt-3 ms-3'>Settle Party Balance</h3>
                    </Modal.Header>

                    <div className='p-4'>
                        <div className="form-weight mt-1">
                            <h4 className='text-black fw-5'>Settle Amount</h4>
                            <label for="actual_weight" className='mt-2 me-2 fw-6 weight-label text-secondary'>
                                ₹
                            </label>
                            <input type="text" className='form-control rounded-4 mb-1 pe-4' id="actual_weight" value={user.party_trip_balance} name='settle_amount' readOnly onChange={handleinput} />
                        </div>

                        <h4 className="fw-5 mt-4 text-black">Payment Mode</h4>
                        <input type="radio" id="Cash" name="payment_mode" value="Cash" onClick={handleinput} />
                        <label className='ms-2 me-4 text-gray fw-5' for="Cash">Cash</label>
                        <input type="radio" id="Cheque" name="payment_mode" value="Cheque" onClick={handleinput} />
                        <label className='ms-2 me-4 text-gray fw-5' for="Cheque">Cheque</label>
                        <input type="radio" id="UPI" name="payment_mode" value="UPI" onClick={handleinput} />
                        <label className='ms-2 me-4 text-gray fw-5' for="UPI">UPI</label>
                        <br />
                        <input type="radio" id="Bank_Transfer" name="payment_mode" value="Bank Transfer" onClick={handleinput} />
                        <label className='ms-2 me-4 text-gray fw-5' for="Bank_Transfer">Bank Transfer</label>
                        <input type="radio" id="Fuel" name="payment_mode" value="Fuel" onClick={handleinput} />
                        <label className='ms-2 me-4 text-gray fw-5' for="Fuel">Fuel</label>
                        <input type="radio" id="Others" name="payment_mode" value="Others" onClick={handleinput} />
                        <label className='ms-2 me-4 text-gray fw-5' for="Others">Others</label>

                        <h4 className="fw-5 mt-4 text-black">Payment Date </h4>
                        <input type='date' className="form-control rounded-4 mb-1" id="date" name='payment_date' onChange={handleinput} />

                        <div className="form-weight mt-4">
                            <h4 className='text-black fw-5'>Type Note</h4>
                            <label for="actual_weight" className='mt-1 me-2 fw-6 weight-label text-secondary'>
                                ₹
                            </label>
                            <input type="text" className='form-control rounded-4 mb-1 pe-4' id="actual_weight" placeholder="Note" name='note' onChange={handleinput} />
                        </div>
                    </div>
                    <Modal.Footer className='border-0'>
                        <Button className='bg-white py-1 px-4 text-blue border_btn' onClick={handleClose4}>
                            Close
                        </Button>
                        <Button className='bg-blue py-1 px-4' onClick={handleClose} type='submit'>
                            Confirm
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>


            <Modal show={show} onHide={handleClose} centered >
                <Modal.Header className='bg-blue rounded-top-4'>
                    <Modal.Title className='fs-4 text-white'>Add Expenses</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={handleSubmit2}>

                        <Row className='px-3 g-2'>

                            <Col md={12}>
                                <h6 className="fw-5 text-gray ">Expenses Type</h6>
                                <select className='form-select border-dark p-1 fw-5 rounded-3' name='expense_type' onChange={handleinput2}>
                                    <option value="" selected>Select Expense Type</option>
                                    <option value="Brokerage">Brokerage</option>
                                    <option value="Detention Charges">Detention Charges</option>
                                    <option value="Driver Bhatta">Driver Bhatta</option>
                                    <option value="Driver Payment">Driver Payment</option>
                                    <option value="Fuel Expenses">Fuel Expenses</option>
                                    <option value="Loading Charges">Loading Charges</option>
                                    <option value="Police Expenses">Police Expenses</option>
                                    <option value="RTO Expenses">RTO Expenses</option>
                                    <option value="Repair Expenses">Repair Expenses</option>
                                    <option value="Toll Expenses">Toll Expenses</option>
                                    <option value="Union Charges">Union Charges</option>
                                    <option value="unloading Charges">unloading Charges</option>
                                    <option value="Weight Charges">Weight Charges</option>
                                </select>
                            </Col>
                            <Col sm={12}>
                                <h6 className="fw-5 text-gray ">Expenses Amount</h6>
                                <input type='number' className="form-control rounded-4 mb-1" id="date" name='expense_amount' placeholder="Expenses Amount" onChange={handleinput2} />

                            </Col>
                            <Col sm={12}>
                                <h6 className="fw-5 text-gray ">Expenses Date</h6>
                                <input type='date' className="form-control rounded-4 mb-1" id="date" name='expense_date' placeholder="Expenses Date" onChange={handleinput2} />

                            </Col>
                            <Col md={12}>
                                <div className="container-fluid d-flex justify-content-end my-2 px-0" >
                                    <Button className='btn bg-danger fs-5 fw-6 text-white me-3 rounded-4 px-4' onClick={handleClose}>Close</Button>
                                    <Button className='btn bg-blue fs-5 fw-6 text-white rounded-4 px-4' type="submit" >Save</Button>
                                </div>
                            </Col>
                        </Row>


                    </form>
                </Modal.Body>
            </Modal>


            <div className="modal fade" id="exampleModal" tabIndex="{-1}" aria-labelledby="exampleModalLabel">
                <div className="modal-dialog modal-xl">
                    <div className="modal-content border-0">

                        <div className="modal-header">
                            <h2 className="modal-title">Lorry Receipt (LR)</h2>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className='p-4'>
                            <Button className={LR == 'CONSIGNOR' ? 'active' : ''} onClick={() => setLR('CONSIGNOR')} variant="outline-primary">CONSIGNOR</Button>
                            <Button className={`${LR == 'CONSIGNEE' ? 'active' : ''} ms-2`} onClick={() => setLR('CONSIGNEE')} variant="outline-primary">CONSIGNEE</Button>
                            <Button className={`${LR == 'DRIVER' ? 'active' : ''} ms-2`} onClick={() => setLR('DRIVER')} variant="outline-primary">DRIVER</Button>
                            <Button className={`${LR == 'ACCOUNT' ? 'active' : ''} ms-2`} onClick={() => setLR('ACCOUNT')} variant="outline-primary">ACCOUNT</Button>
                        </div>
                        <div className={`modal-body ${LR == 'CONSIGNOR' ? 'bg-consignor' : LR == 'CONSIGNEE' ? 'bg-consignee' : ''} `} id='targetLR'>
                            <Bilty_LR LR={LR} id={id} />
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">
                                Close
                            </button>
                            <button type="button" className="btn btn-primary" onClick={generatePDF}>
                                Download
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}
export default TripsOne
