
import React, { useState } from 'react'
import { NavLink } from "react-router-dom";
import emi_pic from '../../assests/new2/emi_pic.svg'
import { Card, Col, Row } from 'react-bootstrap';
import { CiEdit } from "react-icons/ci";
import { RiDeleteBin6Line } from "react-icons/ri";
import { IoMdCheckmark } from "react-icons/io";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Form } from 'react-bootstrap';
import { LuBellRing } from "react-icons/lu";
import { GiSettingsKnobs } from "react-icons/gi";

export default function Emi_book() {
    const [show, setShow] = useState(false);
    const [paid, setPaid] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handlePaymentClose = () => setPaid(false);
    const handlePaymentShow = () => setPaid(true);
    const [validated, setValidated] = useState(false);
    const handleSubmit = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        setValidated(true);
    };
    return (
        <>
            <div className='h-100 offcanvas w-50 offcanvas-end md-w-100' tabIndex="-1" id="offcanvasExample6" aria-labelledby="offcanvasExampleLabel6">
                <div className="dropdown">
                    <h3 className='text-white fw-6 mb-3 bg-blue py-3 ps-5 '>EMI Book  <GiSettingsKnobs className='dropdown-toggle' type="button" data-bs-toggle="dropdown" aria-expanded="false" />


                        <ul className="dropdown-menu setting ">
                            <li><NavLink className="dropdown-item bg-white text-blue" href="#"><CiEdit /> Today</NavLink></li>
                            <li><NavLink className="dropdown-item bg-white text-blue" href="#"> <IoMdCheckmark /> This Week</NavLink></li>
                            <li><NavLink className="dropdown-item bg-white text-danger" href="#"><RiDeleteBin6Line /> last Week</NavLink></li>
                        </ul></h3>
                </div>
                <div className='d-flex justify-content-between px-5'>
                    <h5 className='fw-5 text-gray  mb-1'>Date Range</h5>
                    <h4 className='text-blue'>(HP 03 D 0324)</h4></div>
                <div className="dropdown ps-5">
                    <button className="btn month_btn py-1 " type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                        All Months
                    </button>
                    <ul className="dropdown-menu months " aria-labelledby="dropdownMenuButton1">
                        <li className='text-blue'> Date</li>
                        <li><NavLink className="dropdown-item" href="#">Today</NavLink></li>
                        <li><NavLink className="dropdown-item" href="#">This Week</NavLink></li>
                        <li><NavLink className="dropdown-item" href="#">last Week</NavLink></li>
                        <li><NavLink className="dropdown-item" href="#">This Month</NavLink></li>
                        <li><NavLink className="dropdown-item" href="#">This Year</NavLink></li>
                        <li><NavLink className="dropdown-item" href="#">All Months</NavLink></li>
                    </ul>
                </div>
                <Row className=' overflowScroll'>
                    <Col md={12}>
                        <Card className=" d-none  pt-2 px-3  mx-4 mt-2 shadow_trip_card rounded-4 border-0">
                            <div className='d-flex flex-row justify-content-between'>
                                <h3 className='fs-1 text-gray'>Bajaj</h3>
                                <div className=' mt-2 px-2 pt-1 h-75 bg-danger rounded-4 text-light d-flex justify-content-center align-items-center'><h6>Due</h6></div>
                            </div>
                            <div className='d-flex flex-row justify-content-between'>
                                <h3>₹ 20,000</h3>
                                <h6 className='text-gray'>on Thus, 22 Aug 2024</h6>
                            </div>
                        </Card>
                    </Col>
                    <Col md={12}>
                        <h5 className='d-none text-gray mt-3  mx-4 m-1'>Upcoming</h5>
                        <Card className=" d-none py-3 px-3  mx-4  shadow_trip_card rounded-4 border-0 d-flex flex-row justify-content-between" >
                            <div className=''>
                                <h3 className=' mb-1'>₹ 20,000</h3>
                                <div className='d-flex flex-row gap-2'>
                                    <div className='  px-2 pt-1 h-75 bg-danger rounded-4 text-light d-flex justify-content-center align-items-center'><h6>Due</h6></div>
                                    <h6 className='text-gray mt-1'>on Thus, 22 Aug 2024</h6>
                                </div>
                            </div>
                            <div>
                                <div className=' d-flex fs-1 driver_details rounded-2  px-2 pt-2 ' type='button' onClick={handlePaymentShow}>
                                    <IoMdCheckmark className='fs-2 me-1' />
                                    <h4 className='text-gray '>Mark Paid</h4>
                                </div>
                            </div>

                        </Card>
                    </Col>
                    <Col md={12}>
                        <Card className="d-none  py-3 px-4  mx-4 mt-3 shadow_trip_card rounded-4 border-0 d-flex flex-row justify-content-between" >
                            <div className=''>
                                <h3 className=' mb-1'>15 Jul 2024</h3>
                                <h6 className='text-gray mt-1 fs-3'>Paid on 15 Jul 2024</h6>
                            </div>
                            <div>
                                <div className='d-flex fs-2'>
                                    <div><CiEdit /> <RiDeleteBin6Line /></div>
                                </div>
                                <h4 className='text-gray mt-0 ms-1 fw-5'>₹5000</h4>
                            </div>

                        </Card>
                    </Col>
                </Row>
                <div className=' d-flex  flex-column justify-content-center align-items-center h-100 '>
                    <img src={emi_pic} alt='image--' className='driver-option-img' />
                    <h3 className='w-50 text-center mt-3 fw-5 text-gray driver-option-img-text'>Add your EMI to get monthly
                        reminders</h3>
                </div>

                <div className=' d-flex justify-content-end my-3  gap-3 buttons_postion'>
                    <button className="btn   py-2 border_btn px-4 rounded-4 " type="button" data-bs-toggle="offcanvas" href="#offcanvasExample6"  >
                        Close
                    </button>
                    <button className="btn btn-dark px-4  py-2 rounded-4 " onClick={handleShow}>
                        Add EMI
                    </button>
                </div>

            </div >

            <Modal show={paid} onHide={handlePaymentClose} centered>
                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                    <Modal.Header className='bg-blue text-white' >
                        <h2 className='fs-1'>EMI</h2>
                    </Modal.Header>

                    <div className='p-4'>
                        <label htmlFor="company_name" className="fw-5 text-gray">Amount Paid</label>
                        <input type="text" className="w-100 p-2  border-1 rounded-3 mb-4 " placeholder='20,000' id="company_name" value="" />
                        <label htmlFor="emi" className="fw-5 text-gray">Payment Date</label>
                        <input type="date" className="w-100 p-2  border-1 rounded-3 mb-4 " placeholder='Enter EMI' id="emi" value="" />
                    </div>
                    <Modal.Footer className='border-0'>
                        <Button className=' bg-white py-1 px-4 text-blue border_btn' onClick={handlePaymentClose}>
                            Close
                        </Button>
                        <Button className='bg-blue py-1 px-4' onClick={handlePaymentClose}>
                            Confirm
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>

            <Modal show={show} onHide={handleClose} centered>
                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                    <Modal.Header className='bg-blue text-white' >
                        <h2 className='fs-1'>AddEMI</h2>
                    </Modal.Header>

                    <div className='p-4'>
                        <label htmlFor="company_name" className="fw-5 text-gray">Finance Company</label>
                        <input type="text" className="w-100 p-2  border-1 rounded-3 mb-4 " placeholder='Enter Name' id="company_name" />
                        <label htmlFor="emi" className="fw-5 text-gray">Monthly EMI</label>
                        <input type="text" className="w-100 p-2  border-1 rounded-3 mb-4 " placeholder='Enter EMI' id="emi" />
                        <label htmlFor="date" className="fw-5 text-gray ">Finance Company</label>
                        <input type="date" className="w-100 p-2  border-1 rounded-3 mb-4 " placeholder='' id="date" />
                        <div className='d-flex gap-1 align-items-baseline'>
                            <div><LuBellRing className='' /></div>
                            <h6 className='text-dark'>Remind me on</h6>
                            <div className=' px-3 pt-1 border_btn rounded-3 ' >
                                <h6 >20/02/2002</h6>
                            </div>
                            <h6 className=' text-dark'>every month</h6>
                            <Form.Check type="switch" id="custom-switch" label="" className=' ms-2 mt-0 pt-0 ' />
                        </div>
                    </div>
                    <Modal.Footer className='border-0'>
                        <Button className=' bg-white py-1 px-4 text-blue border_btn' onClick={handleClose}>
                            Close
                        </Button>
                        <Button className='bg-blue py-1 px-4' onClick={handleClose}>
                            Confirm
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </>
    )
}
