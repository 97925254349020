import React, { useState, useEffect } from 'react'
import { Container, Card, Button, InputGroup, Form, Row, Col, Modal } from 'react-bootstrap'
import { NavLink } from 'react-router-dom';
import Sidebar from './sidebar';
import user_logo from '../assests/user-logo.svg'
import { BsSearch } from "react-icons/bs";
import { IoIosAddCircleOutline } from "react-icons/io";
import SidebarMob from './siderbar_mobile';
import { useNavigate } from 'react-router-dom';
import { RiEdit2Line } from "react-icons/ri";
import { MdDeleteOutline } from "react-icons/md";
import Usermenu from './user_menu';
import { useParams } from 'react-router-dom';
import axios from 'axios';

function DriverOne() {
    let { id } = useParams();
    const nevigate = useNavigate()
    const [show, setShow] = useState(false);
    const [show2, setShow2] = useState(false);
    const [show3, setShow3] = useState(false);
    const [driver, setDriver] = useState([]);

    const handleClose = () => setShow(false);
    const handleShow = () => {
        setShow(true);

    }

    const handleClose2 = () => setShow2(false);
    const handleShow2 = () => {
        setShow2(true);
        setShow(false);
    }
    const handleClose3 = () => setShow3(false);
    const handleShow3 = () => {
        setShow3(true);
    }


    const getDriver = (e) => {
        axios.get(`https://freight.pigc.ca/backend/public/getdriverpassbook/${id}`)
            .then((response) => {
                console.log("driver passbook", response.data)
                setDriver(response.data)
            })
            .catch((error) => {
                console.log(error)
            })
    }
    useEffect(() => {

        getDriver();
     
            
    }, [])



    return (
        <div>
            <Container fluid className='px-0 vh-100 d-flex'>
                <Sidebar />
                <SidebarMob />
                <div className='m-0 p-0 w-100'>
                    <Container fluid className='d-flex justify-content-between px-5 mt-4 align-items-center'>
                        <h1 className='text-blue fw-6'>Joe Rogan</h1>

                        <div className="d-flex justify-content-end align-items-center">
                            <Button className='btn bg-danger fs-5 fw-6 text-white me-3 h-50 rounded-4' onClick={handleShow2}>Driver Gave</Button>
                            <Button className='btn bg-cyan fs-5 fw-6 text-white me-3 h-50 rounded-4' onClick={handleShow3}>Driver Got</Button>

                            <Usermenu />
                        </div>
                    </Container>

                    <Card className=' mx-5 mt-4 pb-4 shadow shadow-md ' >
                        <div className='d-flex justify-content-between align-items-center mt-4 pe-3 border-bottom '>
                            <div className="d-flex">
                                <NavLink className='text-decoration-none border border-1 border-dark fs-5 fw-6 text-dark h-50 rounded-4 p-2 ms-2'>Collect From Driver <span className='text-blue ms-2'>37,50,750</span></NavLink>
                                <NavLink className='text-decoration-none bg-blue border border-1 border-dark fs-5 fw-6 text-white h-50 rounded-4 p-2 ms-2'>Settle Amount</NavLink>
                            </div>
                            <div className='text-end'>
                                <InputGroup className="mb-3 mt-3">
                                    <Form.Control
                                        placeholder="Search.."
                                        aria-label="search"
                                        aria-describedby="basic-addon2"
                                    />
                                    <InputGroup.Text id="basic-addon2"><BsSearch /></InputGroup.Text>
                                </InputGroup>
                            </div>
                        </div>
                        <div className="table-responsive px-2 pt-4">

                            <table className='table table-striped  table-hover'>
                                <thead>
                                    <tr>
                                        <th><h4>#</h4></th>
                                        <th><h4>Date</h4></th>
                                        <th><h4>Reason</h4></th>
                                        <th><h4>Driver Gave</h4></th>
                                        <th><h4>Driver Got</h4></th>
                                        <th><h4>Action</h4></th>
                                    </tr>
                                </thead>
                                <tbody >
                                    
                                    {driver? driver?.map((item, index) => (
                                        <tr className='pt-4' key={index}>
                                            <td ><h5 className='mt-2'>{index+1}</h5></td>
                                            <td><h5 className='mt-2'>{item.payment_date?item.payment_date :"-"}</h5></td>
                                            <td><h5 className='mt-2'>{item.reason?item.reason :"-"}</h5></td>
                                            <td><h5 className='mt-2'>{item.gave_amount?item.gave_amount :"-"}</h5></td>
                                            <td><h5 className='mt-2'>{item.got_amount?item.got_amount :"-"}</h5></td>
                                            <td><h5 className='btn text-blue '>
                                                <div className="d-flex">
                                                    <Button className='bg-blue p me-2'><RiEdit2Line className='' /></Button>
                                                    <Button className='bg-danger p' onClick={handleShow}><MdDeleteOutline /> </Button>
                                                </div>
                                            </h5>
                                            </td>
                                        </tr>
                                    )):   <tr className='pt-4' >
                                    <td ><h5 className='mt-2' colspan={6}>No Data Found</h5></td>
                                   
                                </tr>}

                           
                                </tbody>
                            </table>
                        </div>

                    </Card>
                </div>
            </Container>

            <Modal show={show2} onHide={handleClose2} centered >
                <Modal.Header className='bg-blue rounded-top-4'>
                    <Modal.Title className='fs-4 text-white'>Driver Gave (-)</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form>

                        <Row className='px-3 g-2'>

                            <Col md={12}>
                                <h6 className='text-blue fw-6'>Total Amount</h6>
                                <input type="Number" className='form-control mb-3  rounded-4' placeholder='Total Amount' />
                            </Col>
                            <Col md={12}>
                                <h6 className='text-blue fw-6'>Reason</h6>
                                <select className='form-select rounded-4'>
                                    <option value=" ">Fuel Expense</option>
                                    <option value=" ">Loading Charges</option>
                                    <option value=" ">Uploading Charges</option>
                                    <option value=" ">Detention Charges</option>
                                    <option value=" ">Union Charges</option>
                                    <option value=" ">Toll Charges</option>
                                    <option value=" ">Police Expense</option>
                                    <option value=" ">RTO Expense</option>
                                </select>
                            </Col>

                            <Col md={12}>
                                <h6 className='text-blue fw-6 mt-3'>Date</h6>
                                <input type="date" className='form-control  mb-3 rounded-4' placeholder='Date' />
                            </Col>
                            <Col md={12}>
                                <h6 className='text-blue fw-6 mt-3'>Type Note</h6>
                                <input type="text" className='form-control  mb-3 rounded-4' placeholder='Type Note' />
                            </Col>

                            <Col md={12}>
                                <div className="container-fluid d-flex justify-content-end my-2 px-0" >
                                    <Button className='btn bg-danger fs-5 fw-6 text-white me-3 rounded-4 px-4' onClick={handleClose2}>Close</Button>
                                    <Button className='btn bg-blue fs-5 fw-6 text-white rounded-4 px-4' onClick={handleShow2} >Save</Button>
                                </div>
                            </Col>
                        </Row>


                    </form>
                </Modal.Body>
            </Modal>
            <Modal show={show3} onHide={handleClose3} centered >
                <Modal.Header className='bg-blue rounded-top-4'>
                    <Modal.Title className='fs-4 text-white'>Driver Got (+)</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form>

                        <Row className='px-3 g-2'>

                            <Col md={12}>
                                <h6 className='text-blue fw-6'>Total Amount</h6>
                                <input type="Number" className='form-control mb-3  rounded-4' placeholder='Total Amount' />
                            </Col>
                            <Col md={12}>
                                <h6 className='text-blue fw-6'>Reason</h6>
                                <select className='form-select rounded-4'>
                                    <option value=" ">Trip Advance</option>
                                    <option value=" ">Trip Payment</option>
                                    <option value=" ">Party Advance</option>
                                    <option value=" ">Party Payment</option>
                                    <option value=" ">Driver Payment</option>

                                </select>
                            </Col>

                            <Col md={12}>
                                <h6 className='text-blue fw-6 mt-3'>Date</h6>
                                <input type="date" className='form-control  mb-3 rounded-4' placeholder='Date' />
                            </Col>
                            <Col md={12}>
                                <h6 className='text-blue fw-6 mt-3'>Type Note</h6>
                                <input type="text" className='form-control  mb-3 rounded-4' placeholder='Type Note' />
                            </Col>

                            <Col md={12}>
                                <div className="container-fluid d-flex justify-content-end my-2 px-0" >
                                    <Button className='btn bg-danger fs-5 fw-6 text-white me-3 rounded-4 px-4' onClick={handleClose3}>Close</Button>
                                    <Button className='btn bg-blue fs-5 fw-6 text-white rounded-4 px-4' onClick={handleShow3} >Save</Button>
                                </div>
                            </Col>
                        </Row>


                    </form>
                </Modal.Body>
            </Modal>
            <Modal show={show} onHide={handleClose} centered >
                <Modal.Header className='bg-danger rounded-top-4 ' style={{ height: '10px' }}>
                    <Modal.Title className='fs-4 text-white'></Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <div className="text-center">
                        <h2 className='text-danger'>Delete Transaction</h2>
                        <h4>Are you sure you want to delete ?</h4>
                        <div className="container-fluid d-flex justify-content-center mt-4 mb-2 px-0" >
                            <Button className='btn bg-secondary fs-5 fw-6 text-dark me-5 rounded-4 px-4' >Cancle</Button>
                            <Button className='btn bg-danger fs-5 fw-6 text-white rounded-4 px-4' >Delete</Button>
                        </div>
                    </div>

                </Modal.Body>
            </Modal>

        </div>
    )
}

export default DriverOne;