import React, { useEffect, useState } from 'react'
import { Button, Container, Card, Row, Col, InputGroup, Form } from 'react-bootstrap'
import { NavLink, useParams } from 'react-router-dom';

import Sidebar from './sidebar'
import SidebarMob from './siderbar_mobile'

import { IoIosAddCircleOutline } from "react-icons/io";
import { RiDeleteBin6Line } from "react-icons/ri";
import { LuClipboardEdit } from "react-icons/lu";

import user_logo from '../assests/user-logo.svg'
import driver_pic from '../assests/new2/driver_pic.svg'
import truck_yellow from '../assests/new2/truck_yellow.svg'
import petrol from '../assests/new2/petrol.svg';
import emi from '../assests/new2/emi.svg';
import document from '../assests/new2/emi.svg'
import maintenance from '../assests/new2/maintenance.svg'
import driver_other from '../assests/new2/driver_other.svg'
import diesel from '../assests/new2/diesel.svg'
import Addtrip from '../component/addtrip';
import Trip_book from '../component/truck_component/trip_book';
import Fuel_book from '../component/truck_component/fuel_book';
import Add_fuel_expense from '../component/truck_component/add_fuel_expense';
import Emi_book from '../component/truck_component/emi_book';
import Document_book from '../component/truck_component/document_book';
import Maintenance_book from '../component/truck_component/maintenance_book';
import Add_expense_purchase from '../component/truck_component/add_expense_purchase';
import Driver_other_expenses from '../component/truck_component/driver_other_expenses';
import Add_driver_expense from '../component/truck_component/Add_driver_other_expense';
import Usermenu from './user_menu';
import axios from 'axios';

export default function Truck_single() {
    const [activeCompleted, setActiveCompleted] = useState('Active');
    const [tripDetails, setTripDetails] = useState([]);
    const [truckDetails, setTruckDetails] = useState([]);
    const [truck, setTruck] = useState([]);
    const { id } = useParams()
    const [regno, setRegno] = useState(null);
    const [truckdata, setTruckData] = useState({})
    const getTruck = (e) => {
        axios.get('https://freight.pigc.ca/backend/public/gettruck2')
            .then((response) => {

                if (response.data === 'error') {
                    setTruck([])
                }
                else
                    setTruck(response.data);
                // return response.data

                var market = 0;
                var owned = 0;

                for (let i = 0; i < response.data.length; i++) {

                    if (response.data[i].owership === 'Market Truck') {
                        market += 1;

                    } else if (response.data[i].owership === 'My Truck') {
                        owned += 1;
                    }

                }
                setTruckData({
                    "all_truck": market + owned,
                    'market': market,
                    "owned": owned
                })

            })
            .catch((error) => {
                console.log(error);
            })
    }
    const getTripdetail = (e) => {
        axios.get(`https://freight.pigc.ca/backend/public/gettripdetailbyregno/${truckDetails?.reg_no}`)
            .then((response) => {

                if (response.data === 'error') {
                    setTripDetails([])
                }
                else
                    setTripDetails(response.data);

            })
            .catch((error) => {
                console.log(error);
            })
    }

    useEffect(() => {
        const fetchTruckData = async () => {
            try {

                // Find the registration number for the given id
                const foundTruck = truck.find(item => item.id === id);
                if (foundTruck) {
                    setTruckDetails(foundTruck);
                } else {
                    setTruckDetails(null); // Or handle the case where the id is not found
                }
            } catch (error) {
                console.error('Failed to fetch truck data', error);
            }
        };

        fetchTruckData();
    }, [id, truck])
    useEffect(() => {
        getTruck()

    }, [id])

    useEffect(() => {
        getTripdetail()
    }, [truckDetails])

    const [activeTrip, setactiveTrip] = useState()
    const [completedTrip, setcompletedTrip] = useState()
    const set = () => {
        if (tripDetails.length > 0) {
            setcompletedTrip(tripDetails?.filter((item) => item.status === '2'))
            setactiveTrip(tripDetails?.filter((item) => item.status === '1'))
        }
    }
    useEffect(() => {
        set()
    }, [tripDetails])
    return (
        <>

            {/* {console.log(tripDetails, 'truip')}
            {console.log(activeTrip, 'acttruip')}
            {console.log(completedTrip, 'cmpsacttruip')} */}
            <Container fluid className='px-0 vh-100 d-flex'>
                <Sidebar />
                <SidebarMob />
                <div className='m-0 p-0 w-100 padding-20'>
                    <Container fluid className='d-flex justify-content-between px-5  mt-4 align-items-center truck-driver-info'>
                        <h3 className='reg-no_headed text-blue fw-6 media-fs'>{truckDetails?.reg_no}</h3>
                        <div className="d-flex justify-content-end align-items-center truck-driver-header">
                            <button className='btn bg-black fs-5 fw-6 text-white me-3 h-50 rounded-4 margin-btn-add-trip ' data-bs-toggle="offcanvas" href="#offcanvasExample2" aria-controls="offcanvasExample2"><IoIosAddCircleOutline className='me-2 fs-4 mb-1' />Add Bilty</button>
                            {/* <img src={user_logo} alt="image--" className='rounded-5' height={90} /> */}
                            <Usermenu />
                        </div>
                    </Container>
                    <Card className=' mx-5 mt-4 pb-4 shadow shadow-md ' >
                        <div className='d-flex justify-content-between align-items-center mt-4 pe-3 border-bottom '>
                            <div className="d-flex mb-4">
                                <button className={`btn fs-5 fw-6 me-1 h-50 rounded-4 p-2 px-3 ms-2 ${activeCompleted === 'Active' ? 'bg-blue text-white' : ''}`} onClick={() => setActiveCompleted('Active')}>
                                    Active
                                </button>
                                <button className={`btn fs-5 fw-6 me-1 h-50 rounded-4 p-2 px-3 ms-2 ${activeCompleted === 'Completed' ? 'bg-blue text-white' : ''}`} onClick={() => setActiveCompleted('Completed')}>
                                    Completed
                                </button>
                            </div>

                        </div>
                        <div className="table-responsive px-2 pt-4">
                            <table className='table table-striped table-hover'>
                                <thead className='text-center align-middle'>
                                    <tr>
                                        <th rowSpan={2}><h4>G.R. No.</h4></th>
                                        <th rowSpan={2}><h4>No. of Pkgs</h4></th>
                                        <th colSpan={2}><h4>Station</h4></th>
                                        <th rowSpan={2}><h4>Description</h4></th>
                                        <th rowSpan={2}><h4>Consignor</h4></th>
                                        <th rowSpan={2}><h4>Consignee</h4></th>
                                        <th rowSpan={2}><h4>Collection Rs.</h4></th>
                                        <th rowSpan={2}><h4>Weight</h4></th>
                                    </tr>
                                    <tr>
                                        {/* <th><h4></h4></th>
                                        <th><h4></h4></th> */}
                                        <th className='border-0'><h4>From</h4></th>
                                        <th className='border-0'><h4>To</h4></th>
                                        {/* <th></th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th></th> */}
                                    </tr>
                                </thead>
                                <tbody className='text-center'>
                                    {activeCompleted==='Active'?      activeTrip?.map((item, index) =>
                                    (<tr className='pt-4' key={index}>
                                        <td><h4>{item?.lr_no}</h4></td>
                                        <td><h4>2</h4></td>
                                        <td><h4>{item?.start_loc}</h4></td>
                                        <td><h4>{item?.destination}</h4></td>
                                        <td><h4>{item?.description}</h4></td>
                                        <td><h4>{item?.consignor_name}</h4></td>
                                        <td><h4>{item?.consignee_name}</h4></td>
                                        <td><h4>{item?.freight_amount}</h4></td>
                                        <td><h4>{item?.party_quantity}</h4></td>
                                    </tr>
                                    )):completedTrip?.map((item, index) =>
                                        (<tr className='pt-4' key={index}>
                                            <td><h4>{item?.lr_no}</h4></td>
                                            <td><h4>2</h4></td>
                                            <td><h4>{item?.start_loc}</h4></td>
                                            <td><h4>{item?.destination}</h4></td>
                                            <td><h4>{item?.description}</h4></td>
                                            <td><h4>{item?.consignor_name}</h4></td>
                                            <td><h4>{item?.consignee_name}</h4></td>
                                            <td><h4>{item?.freight_amount}</h4></td>
                                            <td><h4>{item?.party_quantity}</h4></td>
                                        </tr>
                                        ))}
                              


                                    {/* (console.log(truck ,'truch'))
                                    {truck && truck.length > 0 ? (filteredUsers?.map((item, index) => (
                                        <tr className='pt-4' key={index}>
                                            <td ><h5 className='mt-2'>{index + 1}</h5></td>
                                            <td><h5 className='mt-2'>{item.reg_no}</h5></td>
                                            <td><h5 className='mt-2'>{item.type}</h5></td>
                                            <td><h5 className='mt-2'>{item.capacity} Tonne</h5></td>
                                            <td><h5 className='mt-2'>{item.owership}</h5></td>
                                            <td><h5 className='mt-2' >{supplier.find(Sup_id => Sup_id.id === item.supplier)?.name || ''}</h5></td>
                                            <td><h5 className='mt-2' >{driver.find(Sup_id => Sup_id.id === item.driver)?.name || ''}</h5></td>
                                            <td ><div className="d-flex justify-content-start align-items-center mt-2">
                                                {item.bilty_weight === "0" ? <><img src={gdot} alt="image..." />
                                                    <h5 className='mb-0 ms-1'>Available</h5></> : <> <img src={bdot} alt="image..." />
                                                    <h5 className='mb-0 ms-1'>Ontrip</h5></>}
                                            </div></td>
                                            <td><Button className='mt-2 bg-blue text-white btn-sm rounded-4' onClick={()=>{handletruck(item.id)}}>View Details</Button></td>
                                        </tr>
                                    ))) : (
                                        <tr className='pt-4' >
                                            <td colSpan={7} className='text-center'><h5 className='mt-2'>No Truck Found</h5></td>
                                        </tr>
                                    )} */}
                                </tbody>
                            </table>
                        </div>

                    </Card>
                </div>
            </Container>
            <Addtrip />
            <Trip_book />
            <Fuel_book />
            <Add_fuel_expense />
            <Emi_book />
            <Document_book />
            <Maintenance_book />
            <Add_expense_purchase />
            <Driver_other_expenses />
            <Add_driver_expense />
        </>
    )
}
