import axios from 'axios';
import React,{useEffect, useState} from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Table from 'react-bootstrap/Table';

export default function Bilty_LR({LR,id}) {
    const [user, SetUser] = useState([]);
    const [charges, SetCharges] = useState([]);
    const [insaurance, SetInsau] = useState([]);

    const getUser = () => {
        axios.post(`https://freight.pigc.ca/backend/public/getbuiltyuser/${id}`)
            .then((response) => {
                //console.log("getbuilty",response.data[0])
                SetUser(response.data[0])
                // getparty(response.data[0].party, response.data[0].drivers)
            })
            .catch((error) => {
                console.log("error", error)
            })
    }
    const getCharges = () => {
        axios.get(`https://freight.pigc.ca/backend/public/getcharges/${id}`)
            .then((response) => {

                SetCharges(response.data[0])
            })
            .catch((error) => {
                console.log("error", error)
            })
    }
    const getInsaurance = () => {
        axios.get(`https://freight.pigc.ca/backend/public/getinsaurance/${id}`)
            .then((response) => {

                SetInsau(response.data[0])
            })
            .catch((error) => {
                console.log("error", error)
            })
    }
    function numberToWords(num) {
        const belowTwenty = [
            'Zero', 'One', 'Two', 'Three', 'Four', 'Five', 'Six', 'Seven', 'Eight', 'Nine',
            'Ten', 'Eleven', 'Twelve', 'Thirteen', 'Fourteen', 'Fifteen', 'Sixteen', 'Seventeen', 'Eighteen', 'Nineteen'
        ];
        const tens = [
            '', '', 'Twenty', 'Thirty', 'Forty', 'Fifty', 'Sixty', 'Seventy', 'Eighty', 'Ninety'
        ];
        const thousands = ['', 'Thousand', 'Million', 'Billion'];
    
        if (num === 0) return 'Zero';
    
        let word = '';
    
        function helper(n) {
            if (n < 20) return belowTwenty[n];
            else if (n < 100) return tens[Math.floor(n / 10)] + (n % 10 !== 0 ? ' ' + belowTwenty[n % 10] : '');
            else return belowTwenty[Math.floor(n / 100)] + ' Hundred' + (n % 100 !== 0 ? ' ' + helper(n % 100) : '');
        }
    
        let i = 0;
    
        while (num > 0) {
            if (num % 1000 !== 0) {
                word = helper(num % 1000) + (thousands[i] ? ' ' + thousands[i] : '') + (word ? ' ' + word : '');
            }
            num = Math.floor(num / 1000);
            i++;
        }
    
        return word.trim();
    }

    const word = numberToWords(user.party_trip_balance)

    useEffect(()=>{
        getUser();
        getCharges();
        getInsaurance();
    },[])
  return (

     <>
        <Container className='fw-semibold'>
            <Row>
                <div className='d-flex align-items-center'>
                    <Col sm={2}>
                        <div className='d-flex justify-content-center align-items-center'>
                            <div className='border border-dark rounded-4 p-2'>LOGO</div>
                        </div>
                    </Col>
                    <Col sm={7} className='text-center'>
                        <div>Subject to Raipur Jurisdiction Only</div>
                        <h2 className='fw-bold text-danger mb-0'>JALANDHAR RAIPUR LOGISTICS</h2>
                        <div className='fw-bold'>FLEET OWNERS & TRANSPORT CONTRACTORS</div>
                        <div className='fw-bold'>Transporter ID: 22KBSPS4910Q1ZP</div>
                        <div className='fw-bold'>EMAIL: jalandharripurlogistics000@gmail.com</div>
                    </Col>
                    <Col sm={3}>
                        <Row className='border border-dark rounded-4'>
                            <Col sm={2} className='fw-bold p-0'>H.O:</Col>
                            <Col sm={10} className='p-0'>Transport Nagar, Rawabhata RAIPUR (C.G.)</Col>
                            
                            <Col sm={2} className='fw-bold p-0'>Mob:</Col>
                            <Col sm={10} className='p-0'>07415064457, 09302726051, 09302244457</Col>
                        </Row>
                    </Col>
                </div>
            </Row>

            <Row className='mt-1 border border-dark rounded-5 text-center'>
                <Col sm={4} className='border-end border-bottom border-dark'>
                    <div>Booking Office</div>
                </Col>
                <Col sm={4} className='border-end border-bottom border-dark'>
                    <div className='text-danger fxw-bold'>{LR} COPY</div>
                </Col>
                <Col sm={4} className='border-bottom border-dark'>
                    <div className='fw-bold'>CONSIGNMENT NOTE</div>
                </Col>
                
                <Col sm={4} className='border-end border-bottom border-dark'>
                    <div className='d-flex row h-100'>
                        <div className='border-bottom border-dark'>{user.consignor_address}</div>
                        <div>ADDRESS AND DELIVERY OFFICE</div>
                        <div>{user.consignor_address}</div>
                    </div>
                </Col>
                <Col sm={4} className='border-end border-bottom border-dark'>
                    <Row className='h-100'>
                        <Col sm={12} className='border-bottom border-dark'>AT OWNER'S RISK</Col>
                        <Col sm={12}>
                            <Col sm={12} className='fs-3'>The Customer has stated that he has not insured the consignment OR He has insured the Consignment</Col>
                            <Col sm={12} className='text-start fw-bold'>Company : {insaurance.company} </Col>
                            <Row className='text-start justify-content-between'>
                                <Col sm={7} className='fw-bold'>Policy No. : {insaurance.policyno}</Col>
                                <Col sm={5} className='fw-bold'>Date : {insaurance.date}</Col>
                            </Row>
                            <Row className='text-start justify-content-between'>
                                <Col sm={7} className='fw-bold'>Amount : {insaurance.amount} </Col>
                                <Col sm={5} className='fw-bold'>Risk : {insaurance.risk}</Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
                <Col sm={4} className='border-bottom border-dark'>
                    <Row>
                        <Col className='text-start border-bottom border-dark fs-2' sm={12}>No. {user.id}</Col>
                        <Col className='text-start border-bottom border-dark' sm={12}>DATE: {user.start_date}</Col>
                        <div>
                            <div>CAUTION</div>
                            <p className='fs-3 mb-0'>This consignment will not detained, deliverted, re-routed or re-booked without consignee Bank's written permission will be delivered at the destination.</p>
                        </div>
                    </Row>
                </Col>

                <Col sm={8} className='text-start border-end border-bottom border-dark'>
                    <Row>
                        <Col sm={12}>CONSIGNOR NAME & ADDRESS : {user.consignor_address},{user.consignor_city},{user.consignor_state},{user.consignor_country}.{user.consignor_pin}</Col>
                        <Col sm={12}>CONSIGNOR GSTIN : {user.consignor_gst}</Col>
                    </Row>
                </Col>
                <Col sm={4} className='text-start border-bottom border-dark'>
                    <Row>
                        <Col sm={12}>FROM : {user.consignor_name}</Col>
                    </Row>
                </Col>

                <Col sm={8} className='text-start border-end border-bottom border-dark'>
                    <Row>
                        <Col sm={12}>CONSIGNEE NAME & ADDRESS : {user.consignee_address},{user.consignee_city},{user.consignee_state},{user.consignee_country}.{user.consignee_pin}</Col>
                        <Col sm={12}>CONSIGNEE GSTIN : {user.consignee_gst}</Col>
                    </Row>
                </Col>
                <Col sm={4} className='text-start border-bottom border-dark'>
                    <Row>
                        <Col sm={12}>TO : {user.consignee_name}</Col>
       
                    </Row>
                </Col>


                <Col sm={2} className='border-end border-bottom border-dark'>PACKAGES</Col>
                <Col sm={4} className='border-end border-bottom border-dark'></Col>
                <Col sm={1} className='border-end border-bottom border-dark'>WEIGHT (Tonne)</Col>
                <Col sm={2} className='border-end border-bottom border-dark'>RATE</Col>
                <Col sm={3} className='border-bottom border-dark'>
                    <Row>
                        <Col sm={12} className='border-bottom border-dark'>TO PAY</Col>
                        <Col sm={8} className='border-end border-dark'>Rs.</Col>
                        <Col sm={4}>P.</Col>
                    </Row>
                </Col>

                <Col sm={2} className='border-end border-bottom border-dark'>
                    <Row>
                        <Col sm={12} className=''>&nbsp;</Col>
                        <Col sm={12} className=''>{user.package_type}</Col>
                        <Col sm={12} className=''>&nbsp;</Col>
                        <Col sm={12} className=''>&nbsp;</Col>
                        <Col sm={12} className='border-top border-bottom border-dark'>P. Mark</Col>
                        <Col sm={12} className=''>&nbsp;</Col>
                        <Col sm={12} className=''>{user.description}</Col>
                        <Col sm={12} className=''>&nbsp;</Col>
                       
                    </Row>
                </Col>
                <Col sm={4} className='text-start border-end border-bottom border-dark'>
                    <Row>
                        <Col sm={12}>&nbsp;</Col>
                        <Col sm={12}>&nbsp;</Col>
                        <Col sm={12}>&nbsp;</Col>
                        <Col sm={12}>&nbsp;</Col>
                        <Col sm={12}>&nbsp;</Col>
                        <Col sm={12}>
                            <span className=''>GST Paid by : {user.gstpaidby}</span>
                            <div className=''><span></span> GST PAID UNDER RCM</div>
                        </Col>
                    </Row>
                </Col>
                <Col sm={1} className='border-end border-bottom border-dark'>
                    <Row>
                        <Col sm={12} className='border-bottom border-dark'>{user.actual_weight} </Col>
                        <Col sm={12} className=''>&nbsp;</Col>
                        <Col sm={12} className=''>&nbsp;</Col>
                        <Col sm={12} className=''>&nbsp;</Col>
                        <Col sm={12} className='p-0'>CHARGED</Col>
                    </Row>
                </Col>
                <Col sm={2} className='text-start border-end border-bottom border-dark'>
                    <Row>
                        <Col sm={12} className='border-bottom border-dark'>&nbsp;</Col>
                        <Col sm={12} className='border-bottom border-dark'>FREIGHT</Col>
                        <Col sm={12} className='border-bottom border-dark'>CARTAGE</Col>
                        <Col sm={12} className='border-bottom border-dark'>HAMALI</Col>
                        <Col sm={12} className='border-bottom border-dark'>ADM. CH</Col>
                        <Col sm={12} className='border-bottom border-dark'>PF</Col>
                        <Col sm={12} className='border-bottom border-dark'>HANDLING CH</Col>
                        <Col sm={12} className=''>ST. CH.</Col>
                    </Row>
                </Col>
                <Col sm={2} className='border-end border-bottom border-dark'>
                    <Row>
                        <Col sm={12} className='border-bottom border-dark'>{parseInt(user.party_trip_balance)- parseInt(charges.total_charges)}</Col>
                        <Col sm={12} className='border-bottom border-dark'>{charges.freight}</Col>
                        <Col sm={12} className='border-bottom border-dark'>{charges.cartage_charges}</Col>
                        <Col sm={12} className='border-bottom border-dark'>{charges.hamali_charges}</Col>
                        <Col sm={12} className='border-bottom border-dark'>{charges.adm_charges}</Col>
                        <Col sm={12} className='border-bottom border-dark'>{charges.pf_charges}</Col>
                        <Col sm={12} className='border-bottom border-dark'>{charges.handling_charges}</Col>
                        <Col sm={12} className=''>{charges.st_charges}</Col>
                    </Row>
                </Col>
                <Col sm={1} className='border-bottom border-dark'>
                    <Row>
                        <Col sm={12} className='border-bottom border-dark'>&nbsp;</Col>
                        <Col sm={12} className='border-bottom border-dark'>&nbsp;</Col>
                        <Col sm={12} className='border-bottom border-dark'>&nbsp;</Col>
                        <Col sm={12} className='border-bottom border-dark'>&nbsp;</Col>
                        <Col sm={12} className='border-bottom border-dark'>&nbsp;</Col>
                        <Col sm={12} className='border-bottom border-dark'>&nbsp;</Col>
                        <Col sm={12} className='border-bottom border-dark'>00</Col>
                        <Col sm={12}>00</Col>
                    </Row>
                </Col>

                <Col sm={7} className='text-start text-danger border-end border-bottom border-dark'>
                    <div className='ms-2'>Note: </div>
                </Col>
                <Col sm={1} className='border-end border-bottom border-dark'></Col>
                <Col sm={1} className='border-end border-bottom border-dark'>Total</Col>
                <Col sm={2} className='border-end border-bottom border-dark'> {user.party_trip_balance}</Col>
                <Col sm={1} className='border-bottom border-dark'></Col>

                <Col sm={12} className='text-start'>                    
                    <div className='ms-2'>Goods Booked and Carried Subject to Condition Printed Overleaf.</div>
                </Col>

                <Col sm={6} className='text-start text-danger'>
                    <div className='ms-2'>Company will not be responsible for leakage & breakage.</div>
                </Col>
                <Col sm={6} className='text-start'>
                    <Row>
                        <Col sm={6}>Value :  {user.party_trip_balance}</Col>
                        <Col sm={6}>Signature of Booking Clerk</Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    </>
  )
}