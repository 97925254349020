import React, { useEffect, useState } from 'react'
import { Container, Card, Button, InputGroup, Form, Row, Col, Modal,Dropdown } from 'react-bootstrap'
import { NavLink, useNavigate } from 'react-router-dom';
import Sidebar from './sidebar';
import user_img from '../assests/user_image.svg'
import upload from '../assests/upload.svg'
import { BsSearch } from "react-icons/bs";
import { IoIosAddCircleOutline } from "react-icons/io";
import SidebarMob from './siderbar_mobile';
import Addtrip from './addtrip';
import Usermenu from './user_menu';
import DOMPurify from 'dompurify';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Addtrip1 from './addtrip_edit';
import parties_dropdown from '../assests/new3/parties_dropdown.svg'
import edit_party from '../assests/new3/edit_party.svg'
import delete_party from '../assests/new3/delete_party.svg'
import opening_balance from '../assests/new3/opening_balance.svg'



function Parties() {

    const nevigate = useNavigate()
    const userid = sessionStorage.getItem('id')
    const usertype = sessionStorage.getItem('user_type')
    const [show, setShow] = useState(false);
    const [show2, setShow2] = useState(false);
    const [show3, setShow3] = useState(false);
    const [show4, setShow4] = useState(false);
    const [display, setDisplay] = useState('none');
    const [display2, setDisplay2] = useState('block');
    const [form, setForm] = useState({'balance':0,'userid':userid})
    const [form2, setForm2] = useState({'balance':0,'userid':userid})
    const [deleteid, setDeleteId] = useState(-1)
    const [imagePreview, setImagePreview] = useState(null);
    const [changeimage, setChangeImage] = useState(false)
    const [parties, setParties] = useState([]);
    const [partiesone, setPartiesOne] = useState([]);
    const [partytrip, setPartyTrip] = useState([]);
    const [balance, setbalance] = useState(0);
    const [partyData, setPartyData] = useState({});
    const [search, setSearch] = useState('')
    const [user, setUser] = useState([]);

    const notify = () => toast("Party Added Successfully !")
    const notify_error = () => toast("No Trip Found !!")

    const handleClose = () => setShow(false) ;
    const handleShow = () => setShow(true);

    const handleClose2 = () => setShow2(false);
    const handleClose3 = () => setShow3(false);
    const handleClose4 = () => setShow4(false);
  

    const handleShow2 = (id,status) => {
        console.log(status,"stasfafs");
       if(status==0){
           setShow2(true)
       }else if(status==1){
        setShow3(true)
       }
        axios.post(`https://freight.pigc.ca/backend/public/getpartiesone/${id}`)
        .then((response) => {
            if (response.data === 'error') {
                setPartiesOne([])
            }
            else
            setPartiesOne(response.data)
          
        })
        .catch((error) => {
            console.log(error);
        })
    };
    const handleShow3 = (id) => {
        setShow3(true)
        console.log(id)
        axios.post(`https://freight.pigc.ca/backend/public/getpartiesone/${id}`)
        .then((response) => {
            if (response.data === 'error') {
                setForm2({})
            }
            else
            setForm2(response.data[0])
           
        })
        .catch((error) => {
            console.log(error);
        })
    };
    const handleShow4 = (id) => {
        
        setShow4(true)
        setDeleteId(id)

    }
    const [id, setId] = useState('')
    const [Partyid, setPartyId] = useState('')



    const handletrips = (id) => {
        
        axios.post(`https://freight.pigc.ca/backend/public/getbuiltyparty/${id}`)
        .then((response) => {
                if (response.data === 'error') {
                    // toast.error('Trip Not Found !', {
                    //     position: "top-right",
                    //     autoClose: 2000,
                    //     hideProgressBar: false,
                    //     closeOnClick: true,
                    //     : true,
                    //     draggable: true,
                    //     progress: undefined,
                    //     theme: "dark",
                    //     });      
                    alert("trip not found");                  
                } else {
                    setDisplay2('none');
                    setDisplay("block")
                    setPartyTrip(response.data)
                }

            })
            .catch((error) => {

                console.log(error)
            })
    }
    const handledelete = () => {
        setShow4(false)
        axios.post(`https://freight.pigc.ca/backend/public/deleteparties/${deleteid}`)
            .then((response) => {
                console.log("deleted noti", response)
                if (response.data === "success") {
                    notify();
                    setTimeout(()=>{
                        getparty()  
                    },3000)
                } else {
                    notify_error()
                }

            })
            .catch((error) => {
                console.log(error);
            })
    }
    const handleuser = (id) => {
        nevigate(`/partiesuser/${id}`)
    }

    const triggerFileInput = () => {
        document.getElementById('fileInput').click();
    };


    const handleinput = (e) => {
        const { name, files, value, checked } = e.target;

        if (name === 'image' && files && files[0]) {
            const file = files[0];
            console.log(file)
            setForm({ ...form, [name]: file });
            setChangeImage(true)
            const reader = new FileReader();
            reader.onloadend = () => {
                setImagePreview(reader.result);
            };
            reader.readAsDataURL(file);
            console.log(form);
        } else {
            const sanitizedValue = DOMPurify.sanitize(value);
            setForm({ ...form, [name]: sanitizedValue });
            console.log(form);

        }


    }
    const handleinputpayment = (e) => {
        const { name, files, value, checked } = e.target;
        const sanitizedValue = DOMPurify.sanitize(value);
        setForm({ ...form, [name]: sanitizedValue });
        console.log(form);
    }

   
    const handlesubmit = (e) => {
        e.preventDefault()
        axios.post('https://freight.pigc.ca/backend/public/addparties', form, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
            .then((response) => {
                handleClose()
                if (response.data === "success") {
                    notify();
                } else {
                    notify_error()
                }

            })
            .catch((error) => {

                console.log(error)
            })
    }
    const handlesubmitpayment = (e) => {
        e.preventDefault();
        toast.loading("Please wait...");
        setShow2(false);
    
        axios.post(`https://freight.pigc.ca/backend/public/addpartiespayment/${partiesone[0].id}`, form, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
        .then((response) => {
            if (response.data === "success") {
                toast.dismiss(); // Dismiss the loading toast
                // toast.success("Updated Successfully!", {
                //     autoClose: 3000,
                //     closeButton: true,
                //     closeOnClick: true,
                // });
                setTimeout(() => {
                    getparty();
                }, 2000);
            } else {
                toast.dismiss(); // Dismiss the loading toast
                toast.error("Something went wrong!!", {
                    autoClose: 3000,
                    closeButton: true,
                    closeOnClick: true,
                });
            }
        })
        .catch((error) => {
            toast.dismiss(); // Dismiss the loading toast
            toast.error("An error occurred!", {
                autoClose: 3000,
                closeButton: true,
                closeOnClick: true,
            });
            console.error(error);
        });
    };
    const [activetrip, setActivetrip] = useState([])

    const handleinput3 = (e) => {
        const { name, files, value, checked } = e.target;
        console.log(name,value,'valll')
        if (name === 'image' && files && files[0]) {
            const file = files[0];
            console.log(file)
            setForm2({ ...form2, [name]: file });
            setChangeImage(true)
            const reader = new FileReader();
            reader.onloadend = () => {
                setImagePreview(reader.result);
            };
            reader.readAsDataURL(file);

        } else {
            const sanitizedValue = DOMPurify.sanitize(value);
            console.log(sanitizedValue)
            setForm2({ ...form2, [name]: sanitizedValue });
        }
    }
    const handlesubmit2 = (e) => {
        e.preventDefault()
        axios.post(`https://freight.pigc.ca/backend/public/editparties/${form2.id}`, form2, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
        .then((response) => {
                setShow3(false)
                if (response.data === "success") {
                    // notify()
                    getparty()
                    // setTimeout(() => {
                    // }, 2000);
                } else {
                    notify_error()
                }

            })
            .catch((error) => {

                console.log(error)
            })
    }
    const getparty = () => {
        setChangeImage(false)
        axios.get(`https://freight.pigc.ca/backend/public/getparties/`)
            .then((response) => {
                if (response.data === 'error') {
                    setParties([])

                }
                else
                    setParties(response.data)

                    var count =0;
                    for(let i=0;i<response.data.length;i++){
                       count+=parseInt(response.data[i].balance)
                    }
                    setbalance(count);
            })
            .catch((error) => {
                console.log(error);
            })
    }
    // const getparty = () => {
    //     setChangeImage(false)
    //     axios.get(`https://freight.pigc.ca/backend/public/getpartiesdetail/${userid}`)
    //         .then((response) => {
    //             if (response.data === 'error') {
    //                 setParties([])

    //             }
    //             else
    //                 setParties(response.data)

    //                 var count =0;
    //                 for(let i=0;i<response.data.length;i++){
    //                    count+=parseInt(response.data[i].balance)
    //                 }
    //                 setbalance(count);
    //         })
    //         .catch((error) => {
    //             console.log(error);
    //         })
    // }

    const getPartyData  = (id)=>{
        axios.post(`https://freight.pigc.ca/backend/public/getbuiltyparty/${id}`)
        .then((response)=>{
          console.log("getbuiltyone",response.data)
          if(response.data==="error"){
            // SetUser([])
          }else{

              var balance=0;
              var trips = 0;
              for(let i=0;i<response.data.length;i++){
                  
                  balance += parseInt(response.data[i].party_trip_balance)
                  console.log(response.data[i].status , 'status')
                  if(response.data[i].status==1){
                    trips+=1;
                  }
                }
                setPartyData(prevData => ({ ...prevData, [id]: { trips, balance } }));
                // SetUser(response.data)
          }
        })
        .catch((error)=>{
            console.log("error",error)
        })
    }
    const getuser = () => {
        axios.get(`https://freight.pigc.ca/backend/public/getuserone/${userid}`)
    
          .then((response) => {
            delete response.data[0].password

            console.log("userdata", response.data);
            setUser(response.data[0])
          })
          .catch((error) => {
            console.log(error)
          })
      }
    useEffect(() => {
        // getparties()
        getparty()
        getuser()

    }, [])
    useEffect(() => {
        parties.forEach(party => {
            getPartyData(party.id);
        });
    }, [parties]);
    const filteredUsers = parties.filter(parties =>
        parties.name.toLowerCase().includes(search.toLowerCase()) ||
        parties.phone.includes(search)
    );
    return (
        <div>
             <ToastContainer
                position="top-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                
                theme="dark"

            />
   
            <Container fluid className='px-0 vh-100 d-flex'>
                <Sidebar />
                <SidebarMob />
                <div className='m-0 p-0 w-100 padding-20'>
                    <Container fluid className='d-flex justify-content-between px-5 mt-4 align-items-center'>
                        <h1 className='text-blue fw-6'>Parties</h1>

                        <div className="d-flex justify-content-end align-items-center">
                            <button className='btn bg-blue fs-5 fw-6 text-white me-3 h-50 rounded-4' onClick={handleShow}><IoIosAddCircleOutline className='me-2 fs-4 mb-1' />Add Party</button>
                            <button className='btn bg-black fs-5 fw-6 text-white me-3 h-50 rounded-4' data-bs-toggle="offcanvas" href="#offcanvasExample2" role="button" aria-controls="offcanvasExample2" onClick={() => setId('Fixed')}><IoIosAddCircleOutline className='me-2 fs-4 mb-1' />Add Bilty</button>
                            <Usermenu />
                        </div>
                    </Container>
                    <Container fluid className='d-flex justify-content-between px-5 mt-4 align-items-center'>
                        <div className='m-0 p-0'>

                            <NavLink className='text-decoration-none border border-1 border-dark fs-5 fw-6 text-dark me-3 h-50 rounded-4 p-2'>Total Party Balance <span className='text-blue ms-2'>{balance} Rs</span></NavLink>
                
                        </div>

                        <div className="d-flex justify-content-end align-items-center">

                            <InputGroup className="mb-3 mt-3 rounded-4">
                                <Form.Control
                                    placeholder="Search.."
                                    aria-label="search"
                                    aria-describedby="basic-addon2"
                                    value={search}
                                    onChange={e => setSearch(e.target.value)}
                                />
                                <InputGroup.Text id="basic-addon2"><BsSearch /></InputGroup.Text>
                            </InputGroup>
                        </div>
                    </Container>
                    <Card className=' mx-5 mt-4 pb-4 shadow shadow-md ' >
                       
                        <Row className='mx-3 mt-2 g-3 overflowScroll' style={{ maxHeight: '62vh' }}>
                            {parties && parties.length > 0 ? (
                            
                                filteredUsers.slice().reverse().map((item, index) => (
                                    <Col sm={12} lg={4} key={index}>
                                        <Card className=' shadow shadow-md parties  p-3 rounded rounded-4  flex-wrap' >
                                            {/* <div className="btn p-0 d-flex align-items-start justify-content-start" onClick={()=>handleuser(item.id )}>
                                                <img src={`https://smarto.s3.ap-south-1.amazonaws.com/freight/parties/${item.image}`} className="card-img" height='108px' alt="" srcSet="" />
                                                <div className="card-body border-0 py-0  align-items-start d-flex flex-column justify-content-center h-100">
                                                    <h4 className='card-title fw-6'>{item.name}</h4>
                                                    <div className="card text border-0 py-0" >
                                                        <h6 className='fw-6'>{item.phone}</h6>
                                                    </div>
                                                </div>
                                            </div> */}
                                              <div className='d-flex justify-content-between'>
                                                <div className="btn p-0 d-flex align-items-start justify-content-start" onClick={()=>handleuser(item.id)}>
                                                    <img src={`https://smarto.s3.ap-south-1.amazonaws.com/freight/parties/${item.image}`} className="card-img" height='108px' alt="" srcSet="" />
                                                    <div className="card-body border-0 py-0  d-flex row justify-content-center text-start">
                                                        <h4 className='card-title fw-6'>{item.name}</h4>
                                                        <div className="card text border-0 py-0 " >
                                                            <h6 className='fw-6'>{item.phone}</h6>
                                                        </div>
                                                    </div>
                                                </div> 
                                                {usertype==='1'&& user.partyedit==='0'?'':<><Dropdown>
                                                    <Dropdown.Toggle className='mb-0 parties_dropdown_menu_btn' variant="" id="dropdown-basic">
                                                        <img src={parties_dropdown} alt=":" />
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu className='mt-0 parties_dropdown_menu'>
                                                        <Dropdown.Item className='btn '  onClick={()=>handleShow3(item.id)}><img src={edit_party} className='me-2' alt='.' /> Edit Party</Dropdown.Item>
                                                        <Dropdown.Item className='btn' onClick={()=>handleShow4(item.id)}><img src={delete_party}className='me-2' alt='.'  /> Delete Party</Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown></>}
                                          
                                            </div> 
                                            <div className="card-footer bg-transparent border-0 px-0 d-flex flex-column  justify-content-end">
                                                <div className='d-flex justify-content-between '><h4 className='fw-6'>Active Trips Count</h4><p className='m-0 p-0'>{partyData[item.id]?.trips || 0}</p></div>
                                                <div className='d-flex justify-content-between '><h4 className='fw-6'>Party Balance</h4><p className='m-0 p-0' >{item.balance}</p></div>
                                                <div className='d-flex justify-content-between mt-2'>
                                                    <button className='btn bg-white border border-dark rounded-4 text-blue fw-6  h-100 w-100 me-3' data-bs-toggle="offcanvas" href="#offcanvasExample3"  aria-controls="offcanvasExample2" onClick={() => setPartyId(item.id)}>Add Bilty</button>
                                                    <button className='btn text-white bg-blue rounded-4 view-btn h-100 w-100' onClick={()=>handleShow2(item.id,0)}>Payment</button>
                                                </div>
                                            </div>
                                        </Card>
                                    </Col> 
                                    
                                ))
                            ) : (
                                <div className="container-fluid text-center">
                                <h3>No Party found</h3>
                            </div>
                            )}
                        </Row>
                    </Card>
                </div>
            </Container>
            {/* modal Componet */}
            <Modal show={show} onHide={handleClose} centered >
                <Modal.Header className='bg-blue rounded-top-4'>
                    <Modal.Title className='fs-4 text-white'>Add Party Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={handlesubmit}>
                        <Row className='px-3 g-2'>
                            <Col md={12} className=''>
                                {/* <img src={upload} alt="image--" className='rounded-5' height={250} /> */}
                                <div className='text-center mb-2' >
                                    <img
                                        src={imagePreview || upload} // Use the preview or default image
                                        alt="image-preview"
                                        className="rounded-5"
                                        height={170}

                                        style={{ cursor: 'pointer', maxWidth: "300px" }} // Make the image look clickable
                                        onClick={triggerFileInput}

                                    />
                                    <input
                                        type="file"
                                        id="fileInput"
                                        style={{ display: 'none' }}
                                        accept="image/*"
                                        name="image"
                                        // onChange={handleinput}
                                        onChange={handleinput}
                                        required
                                    />

                                </div>
                            </Col>

                            <Col md={12}>
                                <h6 className='text-blue fw-6'>Party Name</h6>
                                <input type="text" className='form-control mb-3   rounded-4' name="name" placeholder='Enter Name' value={form.name||""} onChange={handleinput}  />
                            </Col>
                            <Col md={6}>
                                <h6 className='text-blue fw-6'>Opening Balance</h6>
                                <input type="Number" className='form-control  mb-3   rounded-4' name="balance" placeholder='Opening Balance' value={form.balance||""}   onChange={handleinput}  />
                            </Col>
                            <Col md={6}>
                                <h6 className='text-blue fw-6'>Date</h6>
                                <input type="date" className='form-control  mb-3   rounded-4' name="date" placeholder='Date' value={form.date||""} onChange={handleinput}  />
                            </Col>

                            <Col md={12}>
                                <h6 className='text-blue fw-6'>Mobile Number</h6>
                                <input type="Number" className='form-control  mb-3   rounded-4' name="phone" placeholder='+91 Enter Mobile Number' value={form.phone||""} onChange={handleinput}  />
                            </Col>

                            <Col md={12}>
                                <div className="container-fluid d-flex justify-content-end my-2 px-0" >
                                    <Button className='btn bg-danger fs-5 fw-6 text-white me-3 rounded-4 px-4' onClick={handleClose}>Close</Button>
                                    <Button className='btn bg-blue fs-5 fw-6 text-white rounded-4 px-4' type="submit">Save</Button>
                                </div>
                            </Col>
                        </Row>


                    </form>
                </Modal.Body>
            </Modal>
            <Modal show={show3} onHide={handleClose3} centered >
              
                <Modal.Header className='bg-blue rounded-top-4'>
                    <Modal.Title className='fs-4 text-white'>Edit Party Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {form2? <form onSubmit={handlesubmit2}>
                        <Row className='px-3 g-2'>
                            <Col md={12} className=''>
                                {/* <img src={upload} alt="image--" className='rounded-5' height={250} /> */}
                                <div className='text-center mb-2' >
                                   
                                     <div className='text-center'>
                                        {changeimage ? <img
                                            src={imagePreview} 
                                            alt="image-preview"
                                            className="rounded-5"
                                            height={250}
                                            style={{ cursor: 'pointer', maxWidth: "300px" }} 
                                            onClick={triggerFileInput}

                                        /> : <img
                                            src={form2.image? `https://smarto.s3.ap-south-1.amazonaws.com/freight/parties/${form2.image}` : upload} 
                                            alt="image-preview"
                                            className="rounded-5"
                                            height={250}
                                            style={{ cursor: 'pointer', maxWidth: "300px" }} // Make the image look clickable
                                            onClick={triggerFileInput}

                                        />}
                                        <input
                                            type="file"
                                            id="fileInput"
                                            style={{ display: 'none' }}
                                            accept="image/*"
                                            name='image'
                                            onChange={handleinput3}
                                        />
                                    </div>

                                </div>
                            </Col>

                            <Col md={12}>
                                <h6 className='text-blue fw-6'>Party Name</h6>
                                <input type="text" className='form-control mb-3   rounded-4' name="name" placeholder='Enter Name' value={form2.name||''} onChange={handleinput3}  />
                            </Col>
                            <Col md={6}>
                                <h6 className='text-blue fw-6'>Opening Balance</h6>
                                <input type="Number" className='form-control  mb-3   rounded-4' name="balance" placeholder='Opening Balance' value={form2.balance||''}   onChange={handleinput3}  />
                            </Col>
                            <Col md={6}>
                                <h6 className='text-blue fw-6'>Date</h6>
                                <input type="date" className='form-control  mb-3   rounded-4' name="date" placeholder='Date' value={form2.date||''} onChange={handleinput3}  />
                            </Col>

                            <Col md={12}>
                                <h6 className='text-blue fw-6'>Mobile Number</h6>
                                <input type="Number" className='form-control  mb-3   rounded-4' name="phone" placeholder='+91 Enter Mobile Number' value={form2.phone||''} onChange={handleinput3}  />
                            </Col>

                            <Col md={12}>
                                <div className="container-fluid d-flex justify-content-end my-2 px-0" >
                                    <Button className='btn bg-danger fs-5 fw-6 text-white me-3 rounded-4 px-4' onClick={handleClose3}>Close</Button>
                                    <Button className='btn bg-blue fs-5 fw-6 text-white rounded-4 px-4' type="submit">Save</Button>
                                </div>
                            </Col>
                        </Row>


                    </form>:   <form onSubmit={handlesubmit2}>
                        <Row className='px-3 g-2'>
                            <Col md={12} className=''>
                                {/* <img src={upload} alt="image--" className='rounded-5' height={250} /> */}
                                <div className='text-center mb-2' >
                                   
                                     <div className='text-center'>
                                     
                                        <input
                                            type="file"
                                            id="fileInput"
                                            style={{ display: 'none' }}
                                            accept="image/*"
                                            name='image'
                                            onChange={handleinput}
                                        />
                                    </div>

                                </div>
                            </Col>

                            <Col md={12}>
                                <h6 className='text-blue fw-6'>Party Name</h6>
                                <input type="text" className='form-control mb-3   rounded-4' name="name" placeholder='Enter Name'  onChange={handleinput}  />
                            </Col>
                            <Col md={6}>
                                <h6 className='text-blue fw-6'>Opening Balance</h6>
                                <input type="Number" className='form-control  mb-3   rounded-4' name="balance" placeholder='Opening Balance'    onChange={handleinput}  />
                            </Col>
                            <Col md={6}>
                                <h6 className='text-blue fw-6'>Date</h6>
                                <input type="date" className='form-control  mb-3   rounded-4' name="date" placeholder='Date'  onChange={handleinput}  />
                            </Col>

                            <Col md={12}>
                                <h6 className='text-blue fw-6'>Mobile Number</h6>
                                <input type="Number" className='form-control  mb-3   rounded-4' name="phone" placeholder='+91 Enter Mobile Number' onChange={handleinput}  />
                            </Col>

                            <Col md={12}>
                                <div className="container-fluid d-flex justify-content-end my-2 px-0" >
                                    <Button className='btn bg-danger fs-5 fw-6 text-white me-3 rounded-4 px-4' onClick={handleClose3}>Close</Button>
                                    <Button className='btn bg-blue fs-5 fw-6 text-white rounded-4 px-4' type="submit">Save</Button>
                                </div>
                            </Col>
                        </Row>


                    </form>}
                 
                </Modal.Body>
            </Modal>
            {/* modal Componet */}
            <Modal show={show2} onHide={handleClose2} centered size="md">


                <Modal.Header className='bg-blue rounded-top-4'>
                    <Modal.Title className='fs-4 text-white'>Add Party Payment</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form style={{ display: `${display2}` }}>
                       
                        <Row className='px-2 g-2'>

                            <Col md={12}>
                                <div className="d-flex justify-content-between rounded-5 bg-secondary p-3 mb-2">
                                    <div className=''>
                                        <h6 className='text-dark'>Party Name</h6>

                                        <h4>{partiesone?partiesone[0]?.name:''}</h4>
                                        {/* <h4>{partiesone?partiesone[0]?.id:''}</h4> */}
                                    </div>
                                    <div className='text-end'>
                                        <h6 className='text-dark'>Total Balance</h6>
                                        <h4>{partiesone?partiesone[0]?.balance:''}</h4>
                                    </div>
                                </div>
                            </Col>
                            <Col md={6}>
                                <h6 className='text-blue fw-6'>Payment Amount</h6>
                                <input type="Number" className='form-control mb-3  rounded-4' placeholder='Payment Amount' name='amount' onChange={handleinputpayment} />
                            </Col>
                            <Col md={6}>
                                <h6 className='text-blue fw-6'>Payment Date</h6>
                                <input type="date" className='form-control  mb-3 rounded-4' placeholder='Payment Date' name='payment_date' onChange={handleinputpayment}/>
                            </Col>
                            <Col md={12}>
                                <h6 className='text-blue fw-6'>Payment Method</h6>
                                <select className='form-select rounded-4 ' name='payment_method' onChange={handleinputpayment}>
                                    <option selected value="" >Select Payment Method</option>
                                    <option  value="Cash">Cash</option>
                                    <option value="Online">Online</option>
                                    <option value="UPI">UPI</option>
                                    <option value="Bank Transfer">Bank Transfer</option>
                                </select>
                            </Col>
                            {/* <Col md={6}>
                                <div className="d-flex align-items-center mt-3" >
                                    <h6 className='text-blue fw-6 me-2'>Received By Driver</h6>
                                    <div className="form-check form-switch mb-2">
                                        <input className="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked={isDriverSelected}
                                            onChange={handleCheckboxToggle} />
                                    </div>
                                </div>
                            </Col> */}
                            {/* <Col md={12}>
                                {isDriverSelected && (
                                    <>
                                        <h6 className='text-blue fw-6'>Select Driver</h6>
                                        <select className='form-select rounded-4' name='driver' onChange={handleinputpayment}>
                                            <option selected value="">Select Driver</option>
                                            <option value="1">Joe Rogan</option>
                                            <option value="2">Jane Doe</option>
                                            <option value="3">John Smith</option>
                                            <option value="4">Alice Johnson</option>
                                        </select>
                                    </>
                                )}

                            </Col> */}

                            <Col md={12}>
                                <div className="container-fluid d-flex justify-content-end my-2 px-0" >
                                    <Button className='btn bg-danger fs-5 fw-6 text-white me-2 rounded-4 px-4' onClick={handleClose2}>Close</Button>
                                    <Button className='btn bg-blue fs-5 fw-6 text-white rounded-4 px-4' onClick={()=>handletrips(partiesone?partiesone[0]?.id:'')}>Choose Trips</Button>
                                </div>
                            </Col>
                        </Row>


                    </form>

                    <form style={{ display: `${display}` }} onSubmit={handlesubmitpayment}>
                        <Row>
                            <Col md={12}>
                                <div className="d-flex justify-content-between rounded-5 bg-secondary p-3 mb-4">
                                <div className=''>
                                        <h6 className='text-dark'>Party Name</h6>

                                        <h4>{partiesone?partiesone[0]?.name:''}</h4>
                                    </div>
                                    <div className='text-end'>
                                        <h6 className='text-dark'>Total Balance</h6>
                                        <h4>{partiesone?partiesone[0]?.balance:''}</h4>
                                    </div>
                                </div>
                            </Col>
                            <Col md={12}>
                                <h6>Choose Trips / Opening Balance for Payment</h6>

                                {partytrip.map((item,index)=>(

                                <div className="d-flex justify-content-between align-items-center rounded-5 border border-dark p-3 mb-2 ">
                                    <div className='d-flex align-items-center ' key={index}>
                                        <div className="form-check mb-2 me-4">
                                            <input className="form-check-input" type="radio" value={item.id} id="flexCheckChecked" name="bilty_id"  onClick={handleinputpayment}/>
                                        </div>
                                        <div className='me-3'>
                                            <h5 className='text-dark mb-0 '>{item.start_loc}</h5>
                                            <h5>{item.start_date}</h5>
                                        </div>
                                        <h5>--</h5>
                                        <div className='ms-3'>
                                            <h5 className='text-dark mb-0'>{item.destination}</h5>
                                            <h5>{item.end_date?item?.end_date:"In Process"}</h5>
                                        </div>

                                    </div>
                                    <div className='text-end '>

                                        {/* <h4>{parseInt(item.freight_amount)-parseInt(partiesone?partiesone[0]?.balance:'')}/{item.freight_amount}</h4> */}
                                        <h5 className='text-dark mb-0'>Trip Balance</h5>
                                        <h5>{item?.party_trip_balance}</h5>
                                    </div>
                                </div>
                                ))}
                            </Col>
                            <Col md={12}>
                                <div className="container-fluid d-flex justify-content-end my-2 px-0" >
                                    <Button className='btn bg-danger fs-5 fw-6 text-white me-2 rounded-4 px-4' onClick={handleClose2}>Close</Button>
                                    <Button className='btn bg-blue fs-5 fw-6 text-white rounded-4 px-4' type='submit'>Choose Trips</Button>
                                </div>
                            </Col>
                        </Row>
                    </form>
                </Modal.Body>
            </Modal>
            <Modal show={show4} onHide={handleClose4} centered >
                <Modal.Header className='bg-danger rounded-top-4 ' style={{ height: '10px' }}>
                    <Modal.Title className='fs-4 text-white'></Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <div className="text-center">
                        <h2 className='text-danger'>Delete User</h2>
                        <h4>Are you sure you want to delete ?</h4>
                        <div className="container-fluid d-flex justify-content-center mt-4 mb-2 px-0" >
                            <Button className='btn bg-secondary fs-5 fw-6 text-dark me-5 rounded-4 px-4' onClick={handleClose4} >Cancle</Button>
                            <Button className='btn bg-danger fs-5 fw-6 text-white rounded-4 px-4' onClick={handledelete} >Delete</Button>
                        </div>
                    </div>

                </Modal.Body>
            </Modal>

            <Addtrip id={id} Partyid={Partyid} />
            <Addtrip1 id={id} Partyid={Partyid} />
        </div>
    )
}

export default Parties;