// AuthContext.js
import axios from 'axios';
import React, { createContext, useState, useContext, useEffect } from 'react';

// Create a Context
const AuthContext = createContext();

// Create a Provider Component
export const AuthProvider = ({ children }) => {
    const [token, setToken] = useState(sessionStorage.getItem('token') || null);
    const [userData, setUserData] = useState(null);
    const [isAuth, setIsAuth] = useState(null)
    const [isLoading, setIsLoading] = useState(false);
    const newtoken = sessionStorage.getItem('token')
    // setToken(sessionStorage.getItem('token'))
    const validateToken = async (token) => {
        setIsLoading(true);
        // console.log('fa', 'vaild data')
        // console.log(token, 'vaild data')
        try {
            const response = await axios.get('https://freight.pigc.ca/backend/public/validatetoken', {
                params: {
                    token: newtoken
                }
            });
            if (response.data) {
                setUserData(response.data.data.data)
                setIsAuth(true)
                sessionStorage.setItem('user_type', response.data.data.data.user_type);
                sessionStorage.setItem('user_verify', response.data.data.data.is_verified);
                sessionStorage.setItem('id', response.data.data.data.id);
                sessionStorage.setItem('isAuth', true)
                return true;
            } else {
                setUserData(null)
                setIsAuth(false)
                sessionStorage.removeItem('isAuth')
                sessionStorage.removeItem('user_type');
                sessionStorage.removeItem('id');
                sessionStorage.removeItem('user_verify');
                return false;
            }
        } catch (error) {
            console.error('Error validating token:', error);
            setUserData(null)
            setIsAuth(false)
            sessionStorage.removeItem('isAuth')
            return false;
        }
        finally {
            setIsLoading(false); // End loading
        }
    };
    useEffect(() => {

        validateToken(token);

    }, [token, setToken]);
    return (
        <AuthContext.Provider value={{ token, isLoading, setToken, userData, setUserData, validateToken, isAuth, setIsAuth }}>
            {children}
        </AuthContext.Provider>
    );
};

// Create a custom hook to use the context
export const useAuth = () => useContext(AuthContext);