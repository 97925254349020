import React from 'react'
import { NavLink } from "react-router-dom";
import driver_expense from '../../assests/new2/driver_expense.svg'
import { Card, Col, Row } from 'react-bootstrap';
import { CiEdit } from "react-icons/ci";
import { RiDeleteBin6Line } from "react-icons/ri";
export default function Driver_other_expenses() {
    return (
        <>
            <div className='h-100 offcanvas w-50 offcanvas-end md-w-100' tabIndex="-1" id="offcanvasExample10" aria-labelledby="offcanvasExampleLabel10">
                <h3 className='text-white fw-6 mb-3 bg-blue py-3 ps-5 '>
                    Driver & Other Expenses
                </h3>
                <div className='d-flex justify-content-between px-5'>
                    <h5 className='fw-5 text-gray mb-1 '>Date Range</h5>
                    <h4 className='text-blue'>(HP 03 D 0324)</h4>
                </div>
                <div className="dropdown ps-5 ">
                    <button className="btn month_btn py-1 " type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                        All Months
                    </button>
                    <ul className="dropdown-menu months " aria-labelledby="dropdownMenuButton1">
                        <li className='text-blue'> Date</li>
                        <li><NavLink className="dropdown-item" href="#">Today</NavLink></li>
                        <li><NavLink className="dropdown-item" href="#">This Week</NavLink></li>
                        <li><NavLink className="dropdown-item" href="#">last Week</NavLink></li>
                        <li><NavLink className="dropdown-item" href="#">This Month</NavLink></li>
                        <li><NavLink className="dropdown-item" href="#">This Year</NavLink></li>
                        <li><NavLink className="dropdown-item" href="#">All Months</NavLink></li>
                    </ul>
                </div>
                <div className='d-flex flex-column justify-content-center align-items-center h-100'>
                    <img src={driver_expense} alt='image--' className='driver-option-img' />
                    <h3 className='w-75 text-center mt-3 fw-5 text-gray driver-option-img-text'>
                        Keep a track of Driver & other expenses
                    </h3>
                </div>
                <div className='p-4 d-none'>
                    <Row className='overflowScroll'>
                        <Col md={12}>
                            <Card className=" py-3 px-4  mx-4 mt-3 shadow_trip_card rounded-4 border-0 d-flex flex-column justify-content-between" >
                                <h4 className='text-gray  ms-1 fw-5'>Total Cost</h4>
                                <h2 className='fs-7 fw-6'>₹ 5,200</h2>
                            </Card>
                        </Col>

                        <Col md={12}>
                            <Card className=" py-3 px-4  mx-4 mt-3 shadow_trip_card rounded-4 border-0 d-flex flex-column justify-content-between" >
                                <div className=' d-flex justify-content-between '>
                                    <h3 className='text-dark mb-0'>Tyre Purchase</h3>
                                    <div className='d-flex fs-2 '>
                                        <div><CiEdit /> <RiDeleteBin6Line className='text-danger' /></div>
                                    </div>
                                </div>

                                <div className=' d-flex justify-content-between mt-1'>
                                    <h5 className='text-gray  fs-3'>06 Aug 20234</h5>
                                    <h4 className='text-gray  ms-1 fw-5'>₹5000</h4>
                                </div>

                            </Card>
                        </Col>
                    </Row>
                </div>
                <div className=' d-flex justify-content-end my-3 pe-5 gap-3'>
                    <button className="btn py-2 border_btn px-4 rounded-4 " type="button" data-bs-toggle="offcanvas" href="#offcanvasExample10" role="button" aria-controls="offcanvasExample10">
                        Close
                    </button>
                    <button className="btn btn-dark px-4  py-2 rounded-4 " type="button" data-bs-toggle="offcanvas" href="#offcanvasExample11" role="button" aria-controls="offcanvasExample11" >
                        Add Expenses
                    </button>
                </div>

            </div>
        </>
    )
}
