import logo from "./logo.svg";
import "./App.css";
import { useState, useEffect } from "react";
import Sidebar from "./component/sidebar";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import User from "./component/user";
import UserAdd from "./component/user_add";
import Parties from "./component/parties";
import PartiesUser from "./component/parties_user";
import Trips from "./component/trips";
import Supplier from "./component/supplier";
import Passbook from "./component/passbook";
import MontlyBal from "./component/montlybal";
import Driver from "./component/driver";
import DriverOne from "./component/driver_one";
import Truck from "./component/truck";
import TruckOne from "./component/truckone";
import TripsOne from "./component/tripsone";
import Test from "./component/test";
import UserEdit from "./component/user_edit";
import Login from "./component/login";
import Admin from "./component/admin";
import ForgetPass from "./component/forget_pass";
import ForgetPass1 from "./component/forget_pass verifyopt";
import ForgetPass2 from "./component/forget_pass  newpassword";
import Expenses from "./component/expenses/Expenses";
import ExpPassbook from "./component/expenses/ExpPassbook";
import ExpPassbook2 from "./component/expenses/ExpPassbook copy";
import ExpPassbook3 from "./component/expenses/ExpPassbook copy 2";
import Notverify from "./component/Notverify";
import axios from "axios";
import { useAuth } from "./component/AuthContext";
import Spinner from "./component/Spinner";
function App() {
  // const { isAuth ,userData } = useAuth();

  const isAuth = sessionStorage.getItem("isAuth");
  const usertype = sessionStorage.getItem("user_type");
  const user_verify = sessionStorage.getItem("user_verify");
  const { isLoading, userData } = useAuth();
  const userid = sessionStorage.getItem("id");
  const [user, setUser] = useState([]);
  const getuser = () => {
    axios
      .get(
        `https://freight.pigc.ca/backend/public/getuserone/${parseInt(userid)}`
      )

      .then((response) => {
        delete response.data[0].password;
        console.log("userdata", response.data);
        setUser(response.data[0]);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    getuser();
  }, []);
  if (isLoading) {
    return <Spinner />;
  } else {
    return (
      <div className="App">
        {/* {JSON.stringify(userData)} */}
        {/* {usertype === "1" ? JSON.stringify(userData) : ""} */}
        <BrowserRouter>
          <Routes>
            <Route path="/login" element={<Login />} />
            {/* {isAuth&&<>
              <Route path='/' element={<Trips />} />
              <Route path='/useradd' element={<UserAdd />} />
              <Route path='/admin' element={<Admin />} />
              <Route path='/parties' element={<Parties />} />
              <Route path='/partiesuser/:id' element={<PartiesUser />} />
              <Route path='/user' element={<User />} />
              <Route path='/tripsone/:id' element={<TripsOne />} />
              <Route path='/supplier' element={<Supplier />} />
              <Route path='/passbook/:id' element={<Passbook />} />
              <Route path='/montlybal' element={<MontlyBal />} />
              <Route path='/driver' element={<Driver />} />
              <Route path='/driverone/:id' element={<DriverOne />} />
              <Route path='/truck' element={<Truck />} />
              <Route path='/truckone/:id' element={<TruckOne />} />
              <Route path='/test' element={<Test />} />
              <Route path='/useredit/:id' element={<UserEdit />} /></>} */}
            {isAuth ? (
              <>
                <Route path="/" element={<Trips />} />
                <Route path="/tripsone/:id" element={<TripsOne />} />
                {user_verify === "1" || usertype === "0" ? (
                  <>
                    <Route path="/admin" element={<Admin />} />
                    {/* {usertype === "1" ? (
                      userData?.parties == "1" ? (
                        <Route path="/parties" element={<Parties />} />
                      ) : (
                        <Route path="*" element={<Navigate to="/" />} />
                      )
                    ) : (
                      <Route path="/parties" element={<Parties />} />
                    )} */}
                    <Route
                      path="/parties"
                      element={
                        usertype === "1" ? (
                          userData?.parties === "1" ? (
                            <Parties />
                          ) : (
                            <Navigate to="/" />
                          )
                        ) : (
                          <Parties />
                        )
                      }
                    />
                    <Route
                      path="/partiesuser/:id"
                      element={
                        usertype === "1" ? (
                          userData?.parties === "1" ? (
                            <PartiesUser />
                          ) : (
                            <Navigate to="/" />
                          )
                        ) : (
                          <PartiesUser />
                        )
                      }
                    />
                    <Route
                      path="/driver"
                      element={
                        usertype === "1" ? (
                          userData?.drivers === "1" ? (
                            <Driver />
                          ) : (
                            <Navigate to="/" />
                          )
                        ) : (
                          <Driver />
                        )
                      }
                    />
                    <Route
                      path="/driverone/:id"
                      element={
                        usertype === "1" ? (
                          userData?.drivers === "1" ? (
                            <DriverOne />
                          ) : (
                            <Navigate to="/" />
                          )
                        ) : (
                          <DriverOne />
                        )
                      }
                    />
                    <Route
                      path="/supplier"
                      element={
                        usertype === "1" ? (
                          userData?.supplier === "1" ? (
                            <Supplier />
                          ) : (
                            <Navigate to="/" />
                          )
                        ) : (
                          <Supplier />
                        )
                      }
                    />
                    <Route
                      path="/passbook/:id"
                      element={
                        usertype === "1" ? (
                          userData?.parties === "1" ? (
                            <Passbook />
                          ) : (
                            <Navigate to="/" />
                          )
                        ) : (
                          <Passbook />
                        )
                      }
                    />
                    <Route
                      path="/truck"
                      element={
                        usertype === "1" ? (
                          userData?.trucks === "1" ? (
                            <Truck />
                          ) : (
                            <Navigate to="/" />
                          )
                        ) : (
                          <Truck />
                        )
                      }
                    />
                    <Route
                      path="/truckone/:id"
                      element={
                        usertype === "1" ? (
                          userData?.trucks === "1" ? (
                            <TruckOne />
                          ) : (
                            <Navigate to="/" />
                          )
                        ) : (
                          <TruckOne />
                        )
                      }
                    />
                    <Route path="/montlybal" element={<MontlyBal />} />
                    {/* <Route path="/driver" element={<Driver />} />
                    <Route path="/driverone/:id" element={<DriverOne />} />
                    <Route path="/parties" element={<Parties />} />
                    <Route path="/partiesuser/:id" element={<PartiesUser />} /> */}
                    {/*<Route path="/supplier" element={<Supplier />} />
                    <Route path="/passbook/:id" element={<Passbook />} />
                    <Route path="/montlybal" element={<MontlyBal />} /> */}

                    {/* <Route path="/truck" element={<Truck />} />
                    <Route path="/truckone/:id" element={<TruckOne />} />*/}
                    {/* <Route path="/test" element={<Test />} /> */}
                    {usertype === "1" ? (
                      <Route path="*" element={<Navigate to="/" />} />
                    ) : (
                      <>
                        <Route path="/user" element={<User />} />
                        <Route path="/useradd" element={<UserAdd />} />
                        <Route path="/expenses" element={<Expenses />} />
                        <Route path="/expenses/:id" element={<ExpPassbook />} />
                        <Route
                          path="/expenses/supplier/:id"
                          element={<ExpPassbook2 />}
                        />
                        <Route
                          path="/expenses/other/:id"
                          element={<ExpPassbook3 />}
                        />
                        <Route path="/useredit/:id" element={<UserEdit />} />
                      </>
                    )}
                  </>
                ) : (
                  <Route path="*" element={<Navigate to="/notverify" />} />
                )}
                <Route path="/notverify" element={<Notverify />} />
              </>
            ) : (
              <>
                <Route path="/forgetpass" element={<ForgetPass />} />
                {/* <Route path="/forgot_password_verify_otp/:id" element={<ForgetPass1 />} />
                <Route path="/forgetpass" element={<ForgetPass2 />} /> */}
                <Route path="*" element={<Navigate to="/login" />} />
              </>
            )}
          </Routes>
        </BrowserRouter>
      </div>
    );
  }
}

export default App;
