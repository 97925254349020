import React, { useEffect, useState } from 'react'
import { Container, ListGroup, Row, Col } from 'react-bootstrap'
import { NavLink, useLocation } from "react-router-dom";
import logo from '../assests/logo.svg'
import { TiThMenu } from "react-icons/ti";
import axios from 'axios';
function SidebarMob() {
    const user_type = sessionStorage.getItem('user_type')
    const userid = sessionStorage.getItem('id')
    let location = useLocation();

    const currPage = location.pathname

    const [form, setForm] = useState({})
    const getuser = () => {
        axios.get(`https://freight.pigc.ca/backend/public/getuserone/${userid}`)

            .then((response) => {
                delete response.data[0].password

                console.log("userdata", response.data);
                setForm(response.data[0])
            })
            .catch((error) => {
                console.log(error)
            })
    }
    useEffect(() => {
        getuser();
    }, [])
    return (
        <div>
            <a className="btn bg-blue sidebarmob mt-5 ms-2" data-bs-toggle="offcanvas" href="#offcanvasExample" role="button" aria-controls="offcanvasExample" style={{ display: 'none' }}>
                <TiThMenu className='text-white' />
            </a>
            <aside className='sidebar m-0  h-100 offcanvas offcanvas-start' tabIndex="-1" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel">
                <Container className='text-center pt-4'>
                    <img src={logo} alt="" />
                </Container>
                {user_type === '0' ? <>

                    <ListGroup variant="flush" className='nav ps-3 pe-2 '>
                        <ListGroup.Item className={`py-0 menu_item ${currPage === '/' ? 'menu_item_active' : ''}`}>
                            <NavLink to={'/'} className={`menu_link nav-img3 fs-5 ${currPage === '/' ? 'menu_link_active nav_img3_active' : ""}`}>Bilty</NavLink>
                        </ListGroup.Item>
                        <ListGroup.Item className={`py-0 menu_item ${currPage === '/user' ? 'menu_item_active' : ''}`}>
                            <NavLink to={'/user'} className={`menu_link nav-img1 fs-5 ${currPage === '/user' ? 'menu_link_active nav_img1_active' : ""}`}>Users</NavLink>
                        </ListGroup.Item>
                        <ListGroup.Item className={`py-0 menu_item ${currPage === '/parties' ? 'menu_item_active' : ''}`}>
                            <NavLink to={'/parties'} className={`menu_link nav-img2 fs-5 ${currPage === '/parties' ? 'menu_link_active nav_img2_active' : ""}`}>Parties</NavLink>
                        </ListGroup.Item>
                        <ListGroup.Item className={`py-0 menu_item ${currPage === '/expenses' ? 'menu_item_active' : ''}`}>
                            <NavLink to={'/expenses'} className={`menu_link nav-img2 fs-5 ${currPage === '/expenses' ? 'menu_link_active nav_img2_active' : ""}`}>Expenses</NavLink>
                        </ListGroup.Item>
                        <ListGroup.Item className={`py-0 menu_item ${currPage === '/supplier' ? 'menu_item_active' : ''}`}>
                            <NavLink to={'/supplier'} className={`menu_link nav-img4 fs-5 ${currPage === '/supplier' ? 'menu_link_active nav_img4_active' : ""}`}>Suppliers</NavLink>
                        </ListGroup.Item>
                        <ListGroup.Item className={`py-0 menu_item ${currPage === '/driver' ? 'menu_item_active' : ''}`}>
                            <NavLink to={'/driver'} className={`menu_link nav-img5 fs-5 ${currPage === '/driver' ? 'menu_link_active nav_img5_active' : ""}`}>Drivers</NavLink>
                        </ListGroup.Item>
                        <ListGroup.Item className={`py-0 menu_item ${currPage === '/truck' ? 'menu_item_active' : ''}`}>
                            <NavLink to={'/truck'} className={`menu_link nav-img6 fs-5 ${currPage === '/truck' ? 'menu_link_active nav_img6_active' : ""}`}>Trucks</NavLink>
                        </ListGroup.Item>
                    </ListGroup></> :

                    <ListGroup variant="flush" className='nav ps-3 pe-2 '>

                        <ListGroup.Item className={`py-0 menu_item ${currPage === '/' ? 'menu_item_active' : ''}`}>
                            <NavLink to={'/'} className={`menu_link nav-img3 fs-5 ${currPage === '/' ? 'menu_link_active nav_img3_active' : ""}`}>Bilty</NavLink>
                        </ListGroup.Item>
                        {form.parties === '1' ?
                            <ListGroup.Item className={`py-0 menu_item ${currPage === '/parties' ? 'menu_item_active' : ''}`}>
                                <NavLink to={'/parties'} className={`menu_link nav-img2 fs-5 ${currPage === '/parties' ? 'menu_link_active nav_img2_active' : ""}`}>Parties</NavLink>
                            </ListGroup.Item>
                            :
                            ""}
                        {form.supplier === '1' ?
                            <ListGroup.Item className={`py-0 menu_item ${currPage === '/supplier' ? 'menu_item_active' : ''}`}>
                                <NavLink to={'/supplier'} className={`menu_link nav-img4 fs-5 ${currPage === '/supplier' ? 'menu_link_active nav_img4_active' : ""}`}>Suppliers</NavLink>
                            </ListGroup.Item> : ""}

                        {form.drivers === '1' ?
                            <ListGroup.Item className={`py-0 menu_item ${currPage === '/driver' ? 'menu_item_active' : ''}`}>
                                <NavLink to={'/driver'} className={`menu_link nav-img5 fs-5 ${currPage === '/driver' ? 'menu_link_active nav_img5_active' : ""}`}>Drivers</NavLink>
                            </ListGroup.Item> : ""}
                        {form.trucks === '1' ?
                            <ListGroup.Item className={`py-0 menu_item ${currPage === '/truck' ? 'menu_item_active' : ''}`}>
                                <NavLink to={'/truck'} className={`menu_link nav-img6 fs-5 ${currPage === '/truck' ? 'menu_link_active nav_img6_active' : ""}`}>Trucks</NavLink>
                            </ListGroup.Item> : ""}
                    </ListGroup>}


                {/* <Row className='nav ps-3 pe-2 '>
                        <Col sm={12} className="py-0 menu_item ">
                        <NavLink to={'/'} className="menu_link nav-img1 fs-5">Users</NavLink>
                        </Col>
                        <Col sm={12} className="py-0 menu_item ">
                        <NavLink to={'/parties'} className='menu_link nav-img2 fs-5'>Parties</NavLink>
                        </Col>
                        <Col  sm={12} className="py-0 menu_item ">
                        <NavLink to={'/'} className="menu_link nav-img1 fs-5">Users</NavLink>
                        </Col>
                        <Col sm={12} className="py-0 menu_item ">
                        <NavLink to={'/'} className="menu_link nav-img1 fs-5">Users</NavLink>
                        </Col>
                        <Col sm={12} className="py-0 menu_item ">
                        <NavLink to={'/'} className="menu_link nav-img1 fs-5">Users</NavLink>
                        </Col>
                        <Col sm={12} className="py-0 menu_item ">
                        <NavLink to={'/'} className="menu_link nav-img1 fs-5">Users</NavLink>
                        </Col>
                    </Row> */}
            </aside>
        </div>
    )
}

export default SidebarMob