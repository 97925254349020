import React, { useEffect, useState } from 'react'
import { Container, Card, Button, InputGroup, Form, Row, Col, Modal } from 'react-bootstrap'

import Sidebar from './sidebar';
import SidebarMob from './siderbar_mobile';

import { BsSearch } from "react-icons/bs";
import { IoIosAddCircleOutline } from "react-icons/io";

import Addtrip from './addtrip';
import Usermenu from './user_menu';

import axios from 'axios';
import DOMPurify from 'dompurify';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Supplier() {
    const userid = sessionStorage.getItem('id')
    const [search, setSearch] = useState('')
    const [show, setShow] = useState(false);
    const [form, setForm] = useState({ 'userid': userid });
    const [supplier, setSupplier] = useState([]);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleinput = (e) => {
        const sanitizedValue = DOMPurify.sanitize(e.target.value);
        setForm({
            ...form,
            [e.target.name]: sanitizedValue,

        })
    }
    const handleSubmit = (e) => {
        e.preventDefault()
        setShow(false)
        const newid = toast.loading("Please wait...")
        axios.post('https://freight.pigc.ca/backend/public/addsupplier', form)
            .then((response) => {
                if (response.data === "success") {
                    // setTimeout(() => {
                    toast.update(newid, {
                        render: " Updated Successfully !",
                        type: "success",
                        isLoading: false,
                        icon: true,
                        autoClose: 3000,
                        closeButton: true,
                        closeOnClick: true,
                    })
                    getsupplier()
                    // window.location.reload();
                } else {
                    setTimeout(() => {
                        toast.update(newid, {
                            render: "Something went wrong !!",
                            type: "error",
                            isLoading: false,
                            icon: true,
                            autoClose: 3000,
                            closeButton: true,
                            closeOnClick: true,
                        })
                    }, 1000);
                    // notify_error()
                }

            })
            .catch((error) => {

                console.log(error)
            })
    }

    //   const getsupplier = () => {
    //         axios.get(`https://freight.pigc.ca/backend/public/getsupplierdetails/${userid}`)
    //             .then((response) => {
    //                 console.log(response.data, 'resposne')
    //                 if (response.data === 'error') {
    //                     setSupplier([])
    //                 }
    //                 else
    //                 setSupplier(response.data)
    //             })
    //             .catch((error) => {
    //                 console.log(error);
    //             })
    //     }
    const getsupplier = () => {
        axios.get(`https://freight.pigc.ca/backend/public/getsupplier/`)
            .then((response) => {
                console.log(response.data, 'resposne')
                if (response.data === 'error') {
                    setSupplier([])
                }
                else
                    setSupplier(response.data)
            })
            .catch((error) => {
                console.log(error);
            })
    }
    useEffect(() => {
        getsupplier();
    }, [])
    const filteredUsers = supplier.filter(user =>
        user.name.toLowerCase().includes(search.toLowerCase()) || user.phone.includes(search)

    );
    return (
        <div>
            <ToastContainer
                position="top-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                
                theme="dark"

            />
            <Container fluid className='px-0 vh-100 d-flex'>
                <Sidebar />
                <SidebarMob />
                <div className='m-0 p-0 w-100 padding-20'>
                    <Container fluid className='d-flex justify-content-between px-5 mt-4 align-items-center'>
                        <h1 className='text-blue fw-6'>Suppliers</h1>

                        <div className="d-flex justify-content-end align-items-center">
                            <button className='btn bg-blue fs-5 fw-6 text-white me-3 h-50 rounded-4' onClick={handleShow}><IoIosAddCircleOutline className='me-2 fs-4 mb-1' />Add Supplier</button>
                            <button className='btn bg-black fs-5 fw-6 text-white me-3 h-50 rounded-4' data-bs-toggle="offcanvas" href="#offcanvasExample2" aria-controls="offcanvasExample2"><IoIosAddCircleOutline className='me-2 fs-4 mb-1' />Add Bilty</button>
                            <Usermenu />
                        </div>
                    </Container>
                    <Card className=' mx-5 mt-4 pb-4 shadow shadow-md overflowy' style={{ maxHeight: "80vh" }}>
                        <div className='d-flex justify-content-end align-items-center mt-4 pe-3 border-bottom '>

                            <div className='text-end'>
                                <InputGroup className="mb-3 mt-3">
                                    <Form.Control
                                        placeholder="Search.."
                                        aria-label="search"
                                        aria-describedby="basic-addon2"
                                        value={search}
                                        onChange={e => setSearch(e.target.value)}
                                    />
                                    <InputGroup.Text id="basic-addon2"><BsSearch /></InputGroup.Text>
                                </InputGroup>
                            </div>
                        </div>
                        <div className="table-responsive px-2 pt-4">

                            <table className='table table-striped  table-hover'>
                                <thead>
                                    <tr>
                                        <th><h4>#</h4></th>
                                        <th><h4>Supplier Name</h4></th>
                                        <th><h4>Mobile Number</h4></th>
                                        <th><h4>Active Trips</h4></th>
                                        <th><h4>Total Trucks</h4></th>
                                    </tr>
                                </thead>
                                <tbody >
                                    {supplier.length > 0 ? filteredUsers.slice().reverse().map((item, index) => (

                                        <tr className='pt-4' key={index}>
                                            <td ><h5 className='mt-2'>{index + 1}</h5></td>
                                            <td><h5 className='mt-2'>{item.name}</h5></td>
                                            <td><h5 className='mt-2'>{item.phone}</h5></td>
                                            <td><h5 className='mt-2'>{item.active_trip}</h5></td>
                                            <td><h5 className='btn text-blue '>{item.truck_no}</h5></td>
                                        </tr>
                                    )) : <tr className='pt-4 text-center' >

                                        <td colSpan={6}><h5 className='mt-2'>No Supplier Found</h5></td>

                                    </tr>}
                                </tbody>
                            </table>
                        </div>

                    </Card>
                </div>
            </Container>
            <Addtrip />

            <Modal show={show} onHide={handleClose} centered >
                <Modal.Header className='bg-blue rounded-top-4'>
                    <Modal.Title className='fs-4 text-white'>Add Supplier Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={handleSubmit}>

                        <Row className='px-3 g-2'>

                            <Col md={12}>
                                <h6 className='text-blue fw-6'>Supplier Name</h6>
                                <input type="text" className='form-control mb-3   rounded-4' placeholder='Enter Name' name='name' onChange={handleinput} />
                            </Col>

                            <Col md={12}>
                                <h6 className='text-blue fw-6'>Mobile Number</h6>
                                <input type="Number" className='form-control  mb-3   rounded-4' placeholder='+91 Enter Mobile Number' name='phone' onChange={handleinput} />
                            </Col>

                            <Col md={12}>
                                <div className="container-fluid d-flex justify-content-end my-2 px-0" >
                                    <Button className='btn bg-danger fs-5 fw-6 text-white me-3 rounded-4 px-4' onClick={handleClose}>Close</Button>
                                    <Button className='btn bg-blue fs-5 fw-6 text-white rounded-4 px-4' type='submit'>Save</Button>
                                </div>
                            </Col>
                        </Row>


                    </form>
                </Modal.Body>
            </Modal>

        </div>
    )
}

export default Supplier;