import React, { useState } from 'react'
import { Card, Col, Container, Row } from 'react-bootstrap'
import Form from 'react-bootstrap/Form';
import camera_pic from '../../assests/new2/camera.svg'

export default function Add_driver_expense() {
    const [truckRegNo, settruckRegNo] = useState('HR-03S-0421');
    const [selectParty, setselectParty] = useState('Joe Rogan');

    const handleSelectChangeReg = (e) => {
        settruckRegNo(e.target.value);
    };
    return (
        <>
            <div className='h-100 offcanvas w-50 offcanvas-end md-w-100' tabIndex="-1" id="offcanvasExample11" aria-labelledby="offcanvasExampleLabel11">
                <h3 className='text-white fw-6 mb-3 bg-blue py-3 ps-5 '>Add Driver / Other Expense</h3>
                <div className='px-5 mt-2 overflowScroll'>
                    <Row >
                        <Col md={12}>
                            <h6 className='text-gray mt-1 fw-6'>Expense Type</h6>
                            <input type="text" className='form-control rounded-4  mb-1' placeholder='Expense Type' />
                        </Col>
                        <Col md={12}>
                            <h6 className='text-gray mt-2 fw-6'>Amount Paid</h6>
                            <input type="text" className='form-control rounded-4  mb-1' placeholder='Amount Paid' />
                        </Col>
                        <Col md={12}>
                            <h6 className='text-gray mt-2 fw-6'>Expense Date</h6>
                            <input type="date" className='form-control rounded-4  mb-1' />
                        </Col>
                        <Col md={6}></Col>
                        <Col md={12}>
                            <div className='my-2'>
                                <h6 className='text-gray mt-2 fw-6'>Payment Mode</h6>
                                <div className='d-flex gap-3 mt-2 flex-wrap'>
                                    <input type="radio" name='payment_mode' className='form-control rounded-4 d-none mb-1' placeholder='Enter Amount' id='cash1' />
                                    <label form='cash1' className='cash_bg px-3 py-2'>Cash</label>
                                    <input type="radio" name='payment_mode' className='form-control rounded-4 d-none mb-1' placeholder='Enter Amount' id='cash2' />
                                    <label form='cash2' className='cash_bg px-3 py-2'>Credit</label>
                                    <input type="radio" name='payment_mode' className='form-control rounded-4 d-none mb-1' placeholder='Enter Amount' id='cash3' />
                                    <label form='cash3' className='cash_bg px-3 py-2'>Payed By Driver</label>
                                    <input type="radio" name='payment_mode' className='form-control rounded-4 d-none mb-1' placeholder='Enter Amount' id='cash4' />
                                    <label form='cash4' className='cash_bg px-3 py-2'>Online</label>
                                </div>
                            </div>
                        </Col>
                        <Col md={12}>
                            <h6 className='text-gray mt-2 fw-6'>Current KM Reading</h6>
                            <input type="text" className='form-control rounded-4  mb-2' placeholder='Current KM Reading' />
                        </Col>
                        <Col md={12}>
                            <h6 className='text-gray mt-2 fw-6'>Notes</h6>
                            <textarea className='w-100 form-control rounded-4 mb-2' placeholder='Type Here'></textarea>
                        </Col>
                        <Col md={12}>
                            <div className='d-flex gap-1 my-2'>
                                <img src={camera_pic} alt='image--' className='' />
                                <h6 className='text-gray pt-2'>Upload here</h6>
                            </div>
                        </Col>
                        <Col md={12}>
                            <div className=' d-flex justify-content-end my-3 pe-0 gap-3'>
                                <button className="btn   py-2 border_btn px-4 rounded-4 " type="button" data-bs-toggle="offcanvas" href="#offcanvasExample11" role="button" aria-controls="offcanvasExample11" >
                                    Close
                                </button>
                                <button className="btn bg-blue text-white px-4 py-2 rounded-4 " type="button" >
                                    Save
                                </button>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div >
        </>
    )
}
