import React, { useState, useEffect } from 'react'
import Form from 'react-bootstrap/Form';
import { Container, ListGroup, Row, Col, Button, Modal } from 'react-bootstrap'
import { NavLink, useNavigate } from "react-router-dom";
import axios from 'axios';
import DOMPurify from 'dompurify';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { RiH1 } from 'react-icons/ri';
import { IoIosAddCircleOutline } from "react-icons/io";
import upload from '../assests/upload.svg'
import mini from '../assests/mini.svg';
import openbody from '../assests/opentruck.svg';
import closed from '../assests/closedtruck.svg';
import bus from '../assests/bus.svg';
import heavy from '../assests/heavy.svg';
import tank from '../assests/tank.svg';

function AddtripEdit({ id }) {

    const [parties, setParties] = useState([]);
    const [driver, setDriver] = useState([]);
    const [manualFreight, setManualFreight] = useState(false);
    const [show, setShow] = useState(false);
    const [show2, setShow2] = useState(false);
    const [active, setActive] = useState(false);
    const [truck, setTruck] = useState([]);
    const [form, setForm] = useState({ billing_type: "Fixed", freight_amount: '' })
    const [form2, setForm2] = useState({})
    const [form3, setForm3] = useState({})
    const [form4, setForm4] = useState({})
    const [step, setStep] = useState(0);
    const [imagePreview, setImagePreview] = useState(null);
    const [show3, setShow3] = useState(false);
    const [show4, setShow4] = useState(false);
    const [show5, setShow5] = useState(false);
    const [supplier, setSupplier] = useState([]);
    const [readonly, setReadOnly] = useState(false);
    const [readonly2, setReadOnly2] = useState(false);
    const [readonly3, setReadOnly3] = useState(false);
    const [display, setDisplay] = useState("none");
    const [display2, setDisplay2] = useState("none");
    const [charge, setCharge] = useState([]);
    const [maxDate, setMaxDate] = useState('');
    const nevigate = useNavigate()
    const [quantityPara, setQuantityPara] = useState('Quantity')
    const [show6, setShow6] = useState(false);

    const handleClose4 = () => setShow4(false);
    const handleShow4 = () => setShow4(true);

    const handleClose3 = () => setShow3(false);
    const handleShow3 = () => setShow3(true);

    const handleClose5 = () => setShow5(false);
    const handleShow5 = () => setShow5(true);

    const handleClose = () => setShow(true);
    const handleClose2 = () => setShow2(false);

    const handleShow6 = () => setShow6(true);
    const handleClose6 = () => setShow6(false);
    const handleinsau = () => {
        setShow(true);
    };


    const handletype = (type) => {
        setForm({
            ...form,
            'type': type
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        const newid = toast.loading("Please wait...")
        setShow4(false)
        axios.post('https://freight.pigc.ca/backend/public/adddriver', form)
            .then((response) => {
                if (response.data === "success") {
                    // setTimeout(() => {
                    toast.update(newid, {
                        render: "Driver Added Successfully !",
                        type: "success",
                        isLoading: false,
                        icon: true,
                        autoClose: 3000,
                        closeButton: true,
                        closeOnClick: true,
                    })
                    // }, 1000);

                    // notify()
                    setTimeout(() => {
                        nevigate("/driver");
                    }, 3000)
                } else {
                    setTimeout(() => {
                        toast.update(newid, {
                            render: "Something went wrong !!",
                            type: "error",
                            isLoading: false,
                            icon: true,
                            autoClose: 3000,
                            closeButton: true,
                            closeOnClick: true,
                        })
                    }, 1000);
                    // notify_error()
                }

            })
            .catch((error) => {

                console.log(error)
            })
    }


    const triggerFileInput = () => {
        document.getElementById('fileInput').click();
    };

    const notify = () => toast("Trip Added Successfully !")
    const notify_error = () => toast("Something Went Wrong !!")


    const handleinput = (e) => {
        const { name, value } = e.target;
        const sanitizedValue = DOMPurify.sanitize(value);
        if (name === "billing_type" && sanitizedValue === "Fixed") {
            setActive(false);
        } else {
            setActive(true);
        }
        const updatedForm = { ...form, [name]: sanitizedValue };
        if (name === "party_rate" || name === "party_quantity") {
            const rate = parseInt(updatedForm.party_rate) || 0;
            const quantity = parseInt(updatedForm.party_quantity) || 0;
            updatedForm.freight_amount = rate * quantity;
        }
        if (name === "party_quantity") {
            const weight = parseInt(updatedForm.party_quantity) || 0;
            updatedForm.actual_weight = weight;
        }
        setForm(updatedForm);
    };

    // const handlebilling = (e) => {
    //     if (e.target.name === "billing_type" && e.target.value === "Fixed") {
    //         setActive(false);
    //     }
    //     else {
    //         setActive(true);
    //     }
    //     setForm({
    //         ...form,
    //         [e.target.name]: e.target.value,
    //     })
    // }
    const handlesubmit = (e) => {
        e.preventDefault()
        axios.post(`https://freight.pigc.ca/backend/public/edittrip/${form.id}`, form, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
            .then((response) => {

            })
            .catch((error) => {

                console.log(error)
            })
    }
    const handleinputinsau = (e) => {

        const sanitizedValue = DOMPurify.sanitize(e.target.value);

        setForm4({
            ...form4,
            [e.target.name]: sanitizedValue,
        })


    }
    const handlesubmitinsau = (e) => {
        e.preventDefault()
        axios.post(`https://freight.pigc.ca/backend/public/editinsau/${form.id}`, form4, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
            .then((response) => {

            })
            .catch((error) => {

                console.log(error)
            })
    }
    const handleSubmitCharge = (e) => {
        e.preventDefault()
        const newid = toast.loading("Please wait...")
        axios.post(`https://freight.pigc.ca/backend/public/editcharges/${form.id}`, form3, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
            .then((response) => {
                if (response.data === "success") {
                    // setTimeout(() => {
                    toast.update(newid, {
                        render: "Bilty Updated Successfully !",
                        type: "success",
                        isLoading: false,
                        icon: true,
                        autoClose: 3000,
                        closeButton: true,
                        closeOnClick: true,
                    })

                    setTimeout(() => {
                        // window.location.reload();
                    }, 3000)
                } else {
                    setTimeout(() => {
                        toast.update(newid, {
                            render: "Something went wrong !!",
                            type: "error",
                            isLoading: false,
                            icon: true,
                            autoClose: 3000,
                            closeButton: true,
                            closeOnClick: true,
                        })
                    }, 1000);
                    // notify_error()
                }

            })
            .catch((error) => {

                console.log(error)
            })
    }

    const getparties = async () => {
        await axios.get('https://freight.pigc.ca/backend/public/getparties')
            .then((response) => {
                if (response.data === 'error') {
                    setParties([])
                }
                else
                    setParties(response.data)
            })
            .catch((error) => {
                console.log(error);
            })
    }
    const gettruck = async () => {
        await axios.get('https://freight.pigc.ca/backend/public/gettruck2')
            .then((response) => {

                if (response.data === 'error') {
                    setTruck([])
                }
                else
                    setTruck(response.data)
            })
            .catch((error) => {
                console.log(error);
            })
    }
    const getdriver = async () => {
        await axios.get('https://freight.pigc.ca/backend/public/getdriver')
            .then((response) => {

                if (response.data === 'error') {
                    setDriver([])
                }
                else
                    setDriver(response.data)
            })
            .catch((error) => {
                console.log(error);
            })
    }
    // const [newAmount,setnewAmount]=useState()
    const getBuilty = async (id) => {

        await axios.post(`https://freight.pigc.ca/backend/public/getbuiltyuser/${id}`).then((response) => {

            if (response.data === 'error') {
                setForm([])
            }
            else {
                setForm(response.data[0])
                // setnewAmount(response.data[0])
            }
        })
            .catch((error) => {
                console.log(error);
            })
    }
    const getcharges = async (id) => {

        await axios.get(`https://freight.pigc.ca/backend/public/getcharges/${id}`).then((response) => {

            if (response.data === 'error') {
                setForm3([])
            }
            else {
                console.log("charges", response.data[0])
                setForm3(response.data[0])
            }
        })
            .catch((error) => {
                console.log(error);
            })
    }
    const getinsau = async (id) => {


        await axios.get(`https://freight.pigc.ca/backend/public/getinsaurance/${id}`).then((response) => {

            if (response.data === 'error') {
                setForm4([])
            }
            else {
                console.log("charges", response.data[0])
                setForm4(response.data[0])
            }
        })
            .catch((error) => {
                console.log(error);
            })
    }

    const handleinput2 = (e) => {
        const { name, files, value, checked } = e.target;

        if (name === 'image' && files && files[0]) {
            const file = files[0];

            setForm2({ ...form2, [name]: file });

            const reader = new FileReader();
            reader.onloadend = () => {
                setImagePreview(reader.result);
            };
            reader.readAsDataURL(file);

        } else {
            const sanitizedValue = DOMPurify.sanitize(value);
            setForm2({ ...form2, [name]: sanitizedValue });

        }


    }
    const handlesubmit2 = (e) => {
        e.preventDefault()
        handleClose3()
        axios.post('https://freight.pigc.ca/backend/public/addparties', form2, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
            .then((response) => {
                if (response.data === "success") {
                    getparties()
                } else {
                    notify_error()
                }

            })
            .catch((error) => {

                console.log(error)
            })
    }
    const handleinput3 = (e) => {
        const sanitizedValue = DOMPurify.sanitize(e.target.value)
        setForm({
            ...form,
            [e.target.name]: sanitizedValue
        })
        if (e.target.name === 'ownership') {
            if (sanitizedValue === 'Market Truck') {
                setDisplay2('block');
                setDisplay('none');
            } else {
                setDisplay2('none');
                setDisplay('block');
            }
        }
    }

    const handlesubmit3 = (e) => {
        e.preventDefault()
        handleClose4()
        axios.post('https://freight.pigc.ca/backend/public/addtruck', form, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
            .then((response) => {
                if (response.data === "success") {
                    notify()
                    // setTimeout(() => {
                    //     handleClose5()
                    // }, 2000)
                } else {
                    notify_error()
                }
            })
            .catch((error) => {

                console.log(error)
            })
    }

    const handleinput4 = (e) => {

        const sanitizedValue = DOMPurify.sanitize(e.target.value);

        setForm({
            ...form,
            [e.target.name]: sanitizedValue,
        })

    }
    const handleSubmit4 = (e) => {
        e.preventDefault()
        handleClose5()
        axios.post('https://freight.pigc.ca/backend/public/adddriver', form)
            .then((response) => {

            })
            .catch((error) => {
                console.log(error)
            })
    }
    const handleinput5 = (e) => {

        const sanitizedValue = DOMPurify.sanitize(e.target.value);

        const updatedForm = { ...form3, [e.target.name]: sanitizedValue };

        const freight = parseInt(updatedForm.freight) || 0;
        const cartage_charges = parseInt(updatedForm.cartage_charges) || 0;
        const hamali_charges = parseInt(updatedForm.hamali_charges) || 0;
        const adm_charges = parseInt(updatedForm.adm_charges) || 0;
        const pf_charges = parseInt(updatedForm.pf_charges) || 0;
        const handling_charges = parseInt(updatedForm.handling_charges) || 0;
        const st_charges = parseInt(updatedForm.st_charges) || 0;

        updatedForm.total_charges = freight + cartage_charges + hamali_charges + adm_charges + pf_charges + handling_charges + st_charges;


        // Update the form state with the new values
        setForm3(updatedForm);

    }
    const handleSubmit5 = (e) => {
        e.preventDefault()
        handleClose5()
        axios.post('https://freight.pigc.ca/backend/public/adddriver', form)
            .then((response) => {

            })
            .catch((error) => {
                console.log(error)
            })
    }

    const handleFormSubmit = (e) => {

        handlesubmit(e);
        handlesubmitinsau(e)
        handleSubmitCharge(e)
    }
    const handlebilling = (e) => {
        if (e.target.name === "billing_type" && e.target.value === "Fixed") {
            setActive(false);
        }
        else {
            setActive(true);
        }
        setForm({
            ...form,
            [e.target.name]: e.target.value,
        })
        if (e.target.name === "billing_type") {
            switch (e.target.value) {
                case 'Per Tonne':
                    setQuantityPara('Tonne');
                    break;
                case 'Per Kg':
                    setQuantityPara('Kg');
                    break;
                case 'Per Km':
                    setQuantityPara('Km');
                    break;
                case 'Per Trip':
                    setQuantityPara('Trip');
                    break;
                case 'Per Day':
                    setQuantityPara('Day');
                    break;
                case 'Per Hour':
                    setQuantityPara('Hour');
                    break;
                case 'Per Litre':
                    setQuantityPara('Litre');
                    break;
                case 'Per Bag':
                    setQuantityPara('Bag');
                    break;
                default:
                    setQuantityPara('');
                    break;
            }
        }
    }
    const getsupplier = () => {
        axios.get('https://freight.pigc.ca/backend/public/getsupplier')
            .then((response) => {

                if (response.data === 'error') {
                    setSupplier([])
                }
                else
                    setSupplier(response.data)
            })
            .catch((error) => {
                console.log(error);
            })
    }
    function getCurrentDateAndMonth() {
        const now = new Date();
        const day = now.getDate();
        const month = now.getMonth() + 1;
        const year = now.getFullYear();
        const lastTwoDigitsOfYear = year % 100;
        const paddedDay = day.toString().padStart(2, '0');
        const paddedMonth = month.toString().padStart(2, '0');
        return {
            day: paddedDay,
            month: paddedMonth,
            year: lastTwoDigitsOfYear.toString().padStart(2, '0')
        };
    }
    function generateRandomNumber(length) {
        const min = Math.pow(10, length - 1);
        const max = Math.pow(10, length) - 1;
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    const Generate = (id) => {
        let format;
        const { day, month, year } = getCurrentDateAndMonth();
        if (id == 0) {
            // console.log(generateRandomNumber(4))
            format = "LRN-" + day + month + generateRandomNumber(4);
            setForm({
                ...form,
                "lr_no": format
            })
            setReadOnly(true)
        } else if (id == 1) {
            let bilty_id = 10;
            format = day + month + year + generateRandomNumber(4) + bilty_id;
            setForm({
                ...form,
                "invoice_no": format
            })
            setReadOnly2(true)
        } else if (id == 2) {
            format = "ORD" + day + generateRandomNumber(2) + month + generateRandomNumber(3);
            setForm({
                ...form,
                "ewaybill_no": format
            })
            setReadOnly3(true)
        }

    }

    const Date_modify = () => {
        const today = new Date();
        const formattedDate = today.toISOString().split('T')[0];
        setMaxDate(formattedDate);
    }
    useEffect(() => {
        gettruck()
        getparties()
        getdriver()
        getsupplier();
        Date_modify();
    }, [])

    useEffect(() => {

        getBuilty(id);
        getcharges(id);
        getinsau(id);
        setStep(0)
    }, [id])


    const handleStep = () => {
        if (step === 2) {
            setForm({
                ...form,
                total_freight_amount: (parseFloat(form.party_rate) || 0) * (parseInt(form.party_quantity, 10) || 0),
            })
            // form.total_freight_amount = (parseFloat(form.party_rate) || 0) * (parseInt(form.party_quantity, 10) || 0);
        }
        setStep(step + 1);
    }
    const handleprev = () => {
        setStep(step - 1);
    }
    return (

        <div className='h-100 offcanvas w-50 offcanvas-end' tabIndex="-1" id="offcanvasExample3" aria-labelledby="offcanvasExampleLabel3">
            <ToastContainer
                position="top-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable

                theme="dark"

            />

            <form className=''>
                {step === 0 ? <div >

                    <Row className=' add-trip '>
                        <h3 className='text-white fw-6 mb-3 bg-blue py-3 ps-4 '>Edit Bilty</h3>
                        <Row className='trip-details ps-4 '>
                            <h4 className='pt-0 ' >Trip Details</h4>
                            <Col md={6}>
                                <div className="d-flex px-0 justify-content-between align-items-center">
                                    <h6 className='text-blue fw-6 '>Select Party</h6>
                                    <Button data-bs-toggle="offcanvas" href="#offcanvasExample2" className='btn btn-sm bg-blue mb-1 ' onClick={handleShow3}><h6 className='mb-0 text-white'>Add Party</h6></Button>
                                </div>
                                <Form.Select className='rounded-4' name="party" value={form.party || ''} onChange={handleinput} >
                                    <option  >select party</option>
                                    {parties.map((item, index) => (
                                        <option key={index} value={item.id}>{item.name}</option>
                                    ))}

                                </Form.Select>
                            </Col>
                            <Col md={6} className='mt-2'>
                                <h6 className='text-blue fw-6'>Start Date</h6>
                                <input className="rounded-4 form-control mb-3 " value={form.start_date || ''} type="date" name="start_date" id="dat" max={maxDate} onChange={handleinput} />
                            </Col>

                        </Row>
                        {/* <Row className='trip-details ps-4'>
                            <h4 className=' pt-2'>Truck</h4>
                            <Col md={6}>

                                <div className="d-flex px-0 justify-content-between align-items-center">
                                    <h6 className='text-blue fw-6'>Truck Registration No.</h6>
                                    <Button data-bs-toggle="offcanvas" href="#offcanvasExample2" className='btn btn-sm bg-blue mb-1' onClick={handleShow4}><h6 className='mb-0 text-white'>Add Truck</h6></Button>
                                </div>
                                <Form.Select className='rounded-4' name="reg_no" value={form.reg_no || ''} onChange={handleinput}>
                                    <option defaultValue="">Select Truck</option>
                                    {truck.map((item, index) => (
                                        <option value={item.reg_no} key={index}>{item.reg_no}</option>
                                    ))}
                                </Form.Select>
                            </Col>
                            <Col md={6}>
                                <div className="d-flex px-0 justify-content-between align-items-center">
                                    <h6 data-bs-toggle="offcanvas" href="#offcanvasExample2" className='text-blue fw-6 '>Select Driver</h6>
                                    <Button data-bs-toggle="offcanvas" href="#offcanvasExample2" className='btn btn-sm bg-blue mb-1 ' ><h6 className='mb-0 text-white' onClick={handleShow5}>Add Driver</h6></Button>
                                </div>
                                <Form.Select className='rounded-4' name="drivers" value={form.drivers || ''} onChange={handleinput} >
                                    <option>select Driver</option>
                                    {driver.map((item, index) => (
                                        <option key={index} value={item.id}>{item.name}</option>
                                    ))}

                                </Form.Select>
                            </Col>

                        </Row> */}
                        <Row className='trip-details ps-4'>
                            <h4 className=' pt-2'>Route</h4>
                            <Col md={6}>
                                <h6 className='text-blue fw-6'>Start</h6>
                                <input type="text" className='form-control rounded-4 mb-1' value={form.start_loc || ''} name="start_loc" placeholder='Enter Starting Location' onChange={handleinput} />
                                {/* <Form.Select className='rounded-4' value={start} onChange={handleSelectChangeRoute}>
                <option>Joe Rogan</option>
                <option value="route1">start1</option>
                <option value="route2">start2</option>
                <option value="route3">start3</option>
                <option value="route4">start4</option>
                <option value="route5">start5</option>
            </Form.Select> */}
                            </Col>
                            <Col md={6}>
                                <h6 className='text-blue fw-6'>Destination</h6>
                                <input type="text" className='form-control rounded-4 mb-1' value={form.destination || ''} name="destination" placeholder='Enter Destination Location' onChange={handleinput} />
                                {/* <Form.Select className='rounded-4' value={destination} onChange={handleSelectChangDestination}>
                <option>HR-03S-0421</option>
                <option value="route1">HR-03S-0421</option>
                <option value="route2">HR-03S-0422</option>
                <option value="route3">HR-03S-0423</option>
                <option value="route4">HR-03S-0424</option>
                <option value="route5">HR-03S-0425</option>
            </Form.Select> */}
                            </Col>
                        </Row>
                        <Row className='ps-4 mb-2'>
                            <h4 className='pb-1 pt-2'>Material Information</h4>
                            <Col md={6}>
                                <h6 className='text-blue fw-6'>Material Name</h6>
                                <input type="text" value={form.material_name || ''} className='form-control rounded-4  mb-3' name="material_name" placeholder='Material Name' onChange={handleinput} />

                            </Col>
                            <Col md={6}>
                                <h6 className='text-blue fw-6'>Package Type</h6>
                                <input type="text" value={form.package_type || ''} className='form-control rounded-4  mb-3' name="package_type" placeholder='Package Type' onChange={handleinput} />

                            </Col>

                            <Col md={12}>
                                <h6 className='text-blue fw-6'>Description</h6>
                                <input type="text" value={form.description || ''} className='form-control rounded-4  mb-3' name="description" placeholder='Material Description' onChange={handleinput} />

                            </Col>

                        </Row>
                        <Row className='ps-4 '>
                            <h4 className='pb-1 pt-2'>More Information</h4>
                            <Col md={6}>
                                <div className="d-flex px-0 justify-content-between align-items-center">
                                    <h6 className='text-blue fw-6 '>LR No.</h6>
                                    <Button className='btn btn-sm bg-blue mb-1 ' onClick={() => Generate(0)}><h6 className='mb-0 text-white'>Generate</h6></Button>
                                </div>
                                <input type="text" className='form-control rounded-4  mb-2' name="lr_no" value={form.lr_no || ''} placeholder='LR NO ' onChange={handleinput} readOnly={readonly} />

                            </Col>
                            <Col md={6}>
                                <h6 className='text-blue fw-6'>LR Date</h6>
                                <input type="date" className='form-control rounded-4  mb-2' name="lr_date" value={form.lr_date || ''} placeholder='LRN Date' max={maxDate} onChange={handleinput} />

                            </Col>

                        </Row>

                        <Col md={12}>
                            <div className={`container-fluid d-flex justify-content-end  px-0 ${active ? "mt-0" : "mt-3"}`} >
                                <Button className='btn bg-white fs-5 fw-6 cancel me-3 rounded-4' data-bs-dismiss="offcanvas" aria-label="Close">Cancel</Button>
                                <Button className='btn  bg-blue fs-5 fw-6 text-white me-4 rounded-4' onClick={handleStep} >Next</Button>
                            </div>
                        </Col>
                    </Row>

                </div> : step === 1 ? <div>

                    <Row className=' add-trip '>
                        <h3 className='text-white fw-6 mb-3 bg-blue py-3 ps-4 '>Add Bilty</h3>
                        <Row className='trip-details ps-4 mb-3'>
                            <h4 className='pt-0 ' >Consignor Detail</h4>
                            <Col md={6} className='mt-2'>
                                <h6 className='text-blue fw-6 '>Consignor Name</h6>
                                <input className="rounded-4 form-control mb-1 " type="text" value={form.consignor_name || ''} name="consignor_name" id="dat" placeholder='Consignor Name' onChange={handleinput} />
                            </Col>
                            <Col md={6} className='mt-2'>
                                <h6 className='text-blue fw-6'>Consignor Number</h6>
                                <input className="rounded-4 form-control mb-2" type="Number" value={form.consignor_no || ''} name="consignor_no" id="dat" placeholder='Consignor Number' onChange={handleinput} />
                            </Col>
                            <Col md={3} className=''>
                                <h6 className='text-blue fw-6'>City</h6>
                                <input className="rounded-4 form-control mb-2 " type="text" value={form.consignor_city || ''} name="consignor_city" id="dat" placeholder='city' onChange={handleinput} />
                            </Col>
                            <Col md={3} className=''>
                                <h6 className='text-blue fw-6'>State</h6>
                                <input className="rounded-4 form-control mb-2 " type="text" value={form.consignor_state || ''} name="consignor_state" id="dat" placeholder='State' onChange={handleinput} />
                            </Col>
                            {/* <Col md={3} className=''>
                                <h6 className='text-blue fw-6'>Country</h6>
                                <input className="rounded-4 form-control mb-2 " type="text" value={form.consignor_country || ''} name="consignor_country" id="dat" placeholder='Country' onChange={handleinput} />
                            </Col> */}
                            <Col md={3} className=''>
                                <h6 className='text-blue fw-6'>Pincode</h6>
                                <input className="rounded-4 form-control mb-2 " type="Number" value={form.consignor_pin || ''} name="consignor_pin" id="dat" placeholder='Pincode' onChange={handleinput} />
                            </Col>
                            <Col md={8} className=''>
                                <h6 className='text-blue fw-6'>Address</h6>
                                <input className="rounded-4 form-control mb-2 " type="text" value={form.consignor_address || ''} name="consignor_address" id="dat" placeholder='Address' onChange={handleinput} />
                            </Col>
                            <Col md={4} className=''>
                                <h6 className='text-blue fw-6'>GST No.</h6>
                                <input className="rounded-4 form-control mb-2 " type="text" name="consignor_gst" value={form.consignor_gst || ''} id="dat" placeholder='GST No' onChange={handleinput} />
                            </Col>

                        </Row>
                        <Row className='trip-details ps-4 mb-4'>
                            <h4 className='pt-0 ' >Consignee Detail</h4>
                            <Col md={6} className='mt-2'>
                                <h6 className='text-blue fw-6 '>Consignee Name</h6>
                                <input className="rounded-4 form-control mb-1 " type="text" name="consignee_name" value={form.consignee_name || ''} id="dat" placeholder='Consignor Name' onChange={handleinput} />
                            </Col>
                            <Col md={6} className='mt-2'>
                                <h6 className='text-blue fw-6'>Consignee Number</h6>
                                <input className="rounded-4 form-control mb-2" type="Number" name="consignee_no" value={form.consignee_no || ''} id="dat" placeholder='Consignor Number' onChange={handleinput} />
                            </Col>
                            <Col md={3} className=''>
                                <h6 className='text-blue fw-6'>City</h6>
                                <input className="rounded-4 form-control mb-2 " type="text" value={form.consignee_city || ''} name="consignee_city" id="dat" placeholder='city' onChange={handleinput} />
                            </Col>
                            <Col md={3} className=''>
                                <h6 className='text-blue fw-6'>State</h6>
                                <input className="rounded-4 form-control mb-2 " type="text" value={form.consignee_state || ''} name="consignee_state" id="dat" placeholder='State' onChange={handleinput} />
                            </Col>
                            {/* <Col md={3} className=''>
                                <h6 className='text-blue fw-6'>Country</h6>
                                <input className="rounded-4 form-control mb-2 " type="text" value={form.consignee_country || ''} name="consignee_country" id="dat" placeholder='Country' onChange={handleinput} />
                            </Col> */}
                            <Col md={3} className=''>
                                <h6 className='text-blue fw-6'>Pincode</h6>
                                <input className="rounded-4 form-control mb-2 " type="Number" value={form.consignee_pin || ''} name="consignee_pin" id="dat" placeholder='Pincode' onChange={handleinput} />
                            </Col>
                            <Col md={8} className=''>
                                <h6 className='text-blue fw-6'>Address</h6>
                                <input className="rounded-4 form-control mb-2 " type="text" value={form.consignee_address || ''} name="consignee_address" id="dat" placeholder='Address' onChange={handleinput} />
                            </Col>
                            <Col md={4} className=''>
                                <h6 className='text-blue fw-6'>GST No.</h6>
                                <input className="rounded-4 form-control mb-2 " type="text" value={form.consignee_gst || ''} name="consignee_gst" id="dat" placeholder='GST No' onChange={handleinput} />
                            </Col>
                            <Col md={12} className=''>
                                <h6 className='pt-0 fw-6' >Freight Paid By</h6>
                                <div className="d-flex">
                                    <div className="form-check me-4">
                                        <input type="radio" id="flexCheckParties" name="frieght_paidby" defaultValue='consignor' checked={form.frieght_paidby === 'consignor' ? true : false} className="form-check-input" onClick={handleinput} />
                                        <label className="form-check-label" htmlFor="flexCheckParties">Consignor</label>
                                    </div>
                                    <div className="form-check me-4">
                                        <input type="radio" id="flexCheckParties" name="frieght_paidby" defaultValue='consignee' className="form-check-input" checked={form.frieght_paidby === 'consignee' ? true : false} onClick={handleinput} />
                                        <label className="form-check-label" htmlFor="flexCheckTrips">Consignee</label>
                                    </div>
                                    <div className="form-check me-4">
                                        <input type="radio" id="flexCheckParties" name="frieght_paidby" defaultValue='agent' className="form-check-input" checked={form.frieght_paidby === 'agent' ? true : false} onClick={handleinput} />
                                        <label className="form-check-label" htmlFor="flexCheckSuppliers">Agent</label>
                                    </div>

                                </div>
                            </Col>

                        </Row>


                        <Col md={12}>
                            <div className={`container-fluid d-flex justify-content-end  px-0 ${active ? "mt-0" : "mt-2"}`} >
                                <Button className='btn bg-white fs-5 fw-6 cancel me-3 rounded-4' onClick={handleprev}>Prev</Button>
                                <Button className='btn  bg-blue fs-5 fw-6 text-white me-4 rounded-4' onClick={handleStep}>Next</Button>
                            </div>
                        </Col>
                    </Row>

                </div> : step === 2 ? <div>

                    <Row className=' add-trip '>
                        <h3 className='text-white fw-6 mb-1 bg-blue py-3 ps-4 '>Add Bilty</h3>
                        {/* <Row className='trip-details ps-4'>
                            <h4 className=' pt-2'>Billing Information</h4>
                       
                            <Col md={6}>
                                <h6 className='text-blue fw-6'>Party Billing Type</h6>
                                <Form.Select className='rounded-4' name="billing_type" value={form.billing_type || ''} onChange={handlebilling} >
                                    <option value="Fixed">Fixed</option>
                                    <option value="Per Tonne">Per Tonne</option>
                                    <option value="Per Kg">Per Kg</option>
                                    <option value="Per Km">Per Km</option>
                                    <option value="Per Trip">Per Trip</option>
                                    <option value="Per Day">Per Day</option>
                                    <option value="Per Hour">Per Hour</option>
                                    <option value="Per Litre">Per Litre</option>
                                    <option value="Per Bag">Per Bag</option>
                                </Form.Select>
                            </Col>
                            <Col md={6}>
                                <h6 className='text-blue fw-6'>Party Freight Amount</h6>

                                <input type="text" className='form-control rounded-4 mb-1' value={form.freight_amount || ''} name="freight_amount" placeholder='Party Freight Amount' onChange={handleinput} />
                            </Col>
                            <Col md={12} className={`${active ? 'd-block' : 'd-none'}`}>
                                <Row>
                                    <Col md={3}>
                                        <h6 className='text-blue mt-1 fw-6'>Rate/Quantity</h6>
                                        <input type="text" className='form-control rounded-4  mb-1' value={form.party_rate || ''} name="party_rate" placeholder='Rate' onChange={handleinput} />
                                    </Col>
                                    <Col md={3}>
                                        <h6 className='text-blue mt-1 fw-6'>Quantity</h6>
                                        <input type="text" className='form-control rounded-4  mb-1' value={form.party_quantity || ''} name="party_quantity" placeholder='Quantity' onChange={handleinput} />
                                    </Col>
                                    <Col md={6}>
                                        <h6 className='text-blue mt-1 fw-6'> Calculated Freight Amount</h6>
                                        <input type="text" className='form-control rounded-4  mb-1' readOnly name="freight_amount" placeholder='Quantity' value={(parseInt(form?.party_rate) * parseInt(form?.party_quantity)) || ''} onChange={handleinput} />
                                    </Col>

                                </Row>
                            </Col>
                            <Col md={6} className='mt-1'>
                                <h6 className='text-blue fw-6'>Start Kms Reading</h6>
                                <input type="text" className='form-control rounded-4 mb-3 ' value={form.start_reading || ''} name="start_reading" placeholder='Start Readings  KMs' onChange={handleinput} />
                            </Col>
                        </Row> */}
                        <Row className='trip-details ps-4'>
                            <h4 className=' pt-2'>Billing Information</h4>
                            <Col md={12}>
                                {/* <h6 className='text-blue fw-6'>            {JSON.stringify(form)}</h6> */}
                            </Col>
                            <Col md={6}>
                                <h6 className='text-blue fw-6'>Party Billing Type</h6>
                                <Form.Select className='rounded-4' name="billing_type" value={form.billing_type || 'default'} onChange={handlebilling} >

                                    <option value="Per Tonne">Per Tonne</option>
                                    <option value="Per Kg">Per Kg</option>
                                    <option value="Per Km">Per Km</option>
                                    <option value="Per Trip">Per Trip</option>
                                    <option value="Per Day">Per Day</option>
                                    <option value="Per Hour">Per Hour</option>
                                    <option value="Per Litre">Per Litre</option>
                                    <option value="Per Bag">Per Bag</option>
                                </Form.Select>
                            </Col>
                            {/* <Col md={6}>
                                <h6 className='text-blue fw-6'>Party Freight Amount</h6>
                                <input type="text" className='form-control rounded-4 mb-1' value={form.freight_amount || ''} name="freight_amount" placeholder='Party Freight Amount' onChange={handleinput} />
                            </Col>
                            <Col md={12}>
                                <Row>
                                    <Col md={3}>
                                        <h6 className='text-blue mt-1 fw-6'>Rate/{quantityPara}</h6>
                                        <input type="text" className='form-control rounded-4  mb-1' value={form.party_rate || ''} name="party_rate" placeholder='Rate' onChange={handleinput} />
                                    </Col>
                                    <Col md={3}>
                                        <h6 className='text-blue mt-1 fw-6'>{quantityPara}</h6>
                                        <input type="text" className='form-control rounded-4  mb-1' value={form.party_quantity || ''} name="party_quantity" placeholder='Quantity' onChange={handleinput} />
                                    </Col>
                                    <Col md={6}>
                                        <h6 className='text-blue mt-1 fw-6'> Calculated Freight Amount</h6>
                                        <input type="text" className='form-control rounded-4  mb-1' readOnly name="freight_amount" placeholder='Quantity' value={(parseInt(form?.party_rate) * parseInt(form?.party_quantity)) || ''} onChange={handleinput} />
                                    </Col>

                                </Row>
                            </Col> */}
                            <Col md={6} className=''>
                                <h6 className='text-blue fw-6'>Action</h6>
                                <Button className={`btn btn-sm bg-blue w-100 p-2  rounded-4`} onClick={() => setManualFreight(!manualFreight)}>
                                    <h5 className='mb-0 text-white'>Enter Amount Manually</h5>
                                </Button>
                            </Col>
                            <Col md={12} className={`${manualFreight ? 'mt-2' : 'd-none'}`}>
                                <h6 className='text-blue fw-6'>Party Freight Amount</h6>
                                <input type="text" className='form-control rounded-4 mb-1' value={form.freight_amount || ''} name="freight_amount" placeholder='Party Freight Amount' onChange={handleinput} />
                            </Col>
                            <Col md={12} className={`${manualFreight ? 'd-none' : 'mt-1'}`}>
                                <Row>
                                    <Col md={3}>
                                        <h6 className='text-blue mt-1 fw-6'>Rate/{quantityPara}</h6>
                                        <input type="text" className='form-control rounded-4  mb-1' value={form.party_rate || ''} name="party_rate" placeholder='Rate' onChange={handleinput} />
                                    </Col>
                                    <Col md={3}>
                                        <h6 className='text-blue mt-1 fw-6'>{quantityPara}</h6>
                                        <input type="text" className='form-control rounded-4  mb-1' value={form.party_quantity || ''} name="party_quantity" placeholder='Quantity' onChange={handleinput} />
                                    </Col>
                                    <Col md={6}>
                                        <h6 className='text-blue mt-1 fw-6'> Calculated Freight Amount</h6>
                                        <input type="text" className='form-control rounded-4  mb-1' readOnly name="freight_amount" placeholder='Quantity' value={(parseInt(form?.party_rate) * parseInt(form?.party_quantity)) || ''} onChange={handleinput} />
                                    </Col>
                                </Row>
                            </Col>
                            {/* <Col md={6} className='mt-1'>
                                <h6 className='text-blue fw-6'>Start Kms Reading</h6>
                                <input type="text" className='form-control rounded-4 mb-3 ' value={form.start_reading || ''} name="start_reading" placeholder='Start Readings  KMs' onChange={handleinput} />
                            </Col> */}
                            <Col md={6} className='mt-1'>
                                <h6 className='text-blue fw-6'>Manual Weight (in {quantityPara === "Tonne" ? 'tonne' : 'kg'})</h6>
                                <input type="text" className='form-control rounded-4 mb-3' name="actual_weight" readOnly={quantityPara === 'Tonne' || quantityPara === 'Kg'} value={form.actual_weight || ''} placeholder='Manual Weight' onChange={handleinput} />
                            </Col>
                        </Row>
                        <Row className='trip-details ps-4'>
                            <h4 className=' pt-2'>Invoice</h4>
                            <Col md={6} className='mt-2'>
                                <div className="d-flex px-0 justify-content-between align-items-center">
                                    <h6 className='text-blue fw-6 '>Invoice Number</h6>
                                    <Button className='btn btn-sm bg-blue mb-1 ' onClick={() => Generate(1)}><h6 className='mb-0 text-white'>Generate</h6></Button>
                                </div>
                                <input className="rounded-4 form-control mb-2 " value={form.invoice_no || ''} type="text" name="invoice_no" id="dat" placeholder='Invoice Number' onChange={handleinput} readOnly={readonly2} />
                            </Col>
                            <Col md={6} className='mt-2'>
                                <h6 className='text-blue fw-6'>Invoice Date</h6>
                                <input className="rounded-4 form-control mb-2 " type="Date" name="invoice_date" value={form.invoice_date || ''} id="dat" placeholder='Invoice Date' max={maxDate} onChange={handleinput} />
                            </Col>
                            <Col md={6} className='mt-2'    >
                                <h6 className='text-blue fw-6'>Add Insurance</h6>
                                <Button data-bs-toggle="offcanvas" href="#offcanvasExample3" className='btn btn-sm bg-blue mb-1' onClick={handleShow6}><h6 className='mb-0 text-white'><IoIosAddCircleOutline className='me-2 fs-4 mb-1' />Add Insurance</h6></Button>

                            </Col>
                        </Row>
                        <Row className='trip-details ps-4 mt-2'>


                            <Col md={12}>
                                <h4 className='text-blue fw-6 '>Waybill Details</h4>
                                <h6 className=''>Description on waybill here</h6>

                            </Col>

                            <Col md={12}>
                                <div className="d-flex px-0 justify-content-between align-items-center">
                                    <h6 className='text-blue fw-6 '>E-Waybill No</h6>
                                    <Button className='btn btn-sm bg-blue mb-1 ' onClick={() => Generate(2)}><h6 className='mb-0 text-white'>Generate</h6></Button>
                                </div>
                                <input type="text" className='form-control rounded-4 mb-3 ' value={form.ewaybill_no || ''} name="ewaybill_no" placeholder='E-Waybill No' onChange={handleinput} readOnly={readonly3} />
                            </Col>

                            <Col md={6} className=''>
                                <h6 className='text-blue fw-6'>GST %</h6>
                                <input className="rounded-4 form-control mb-2" type="Number" value={form.gst_percent || ''} name="gst_percent" id="dat" placeholder='GST %' onChange={handleinput} />
                            </Col>
                            <Col md={6} className='mt-2'>
                                <h6 className='text-blue fw-6'>GST Paid By</h6>
                                <Form.Select className='rounded-4' name="gstpaidby" value={form.gstpaidby || ''} onChange={handlebilling} >
                                    <option value="Consignor">Consignor</option>
                                    <option value="Consignee">Consignee</option>
                                    <option value="Agent">Agent</option>

                                </Form.Select>

                            </Col>

                        </Row>
                        <Col md={12}>
                            <div className={`container-fluid d-flex justify-content-end  px-0 ${active ? "mt-0" : "mt-5"}`} >
                                <Button className='btn bg-white fs-5 fw-6 cancel me-3 rounded-4' onClick={handleprev}>Prev</Button>
                                <Button className='btn  bg-blue fs-5 fw-6 text-white me-4 rounded-4' onClick={handleStep}>Next</Button>
                            </div>
                        </Col>
                    </Row>

                </div> : <div>

                    <Row className=' add-trip '>
                        <h3 className='text-white fw-6 mb-3 bg-blue py-3 ps-4 '>Add Bilty</h3>
                        <Row className='trip-details ps-4 mb-3 g-3'>



                            <h4 className='py-2 '>Charges</h4>
                            <Col md={6} className=''>
                                <h6 className='text-blue fw-6'>Freight</h6>
                                <input className="rounded-4 form-control mb-2" type="Number" value={form3.freight || ''} name="freight" id="freight" placeholder='Pickup Charges' onChange={handleinput5} />
                            </Col>
                            <Col md={6} className=''>
                                <h6 className='text-blue fw-6'>Cartage</h6>
                                <input className="rounded-4 form-control mb-2" type="Number" value={form3.cartage_charges || ''} name="cartage_charges" id="pickup_charges" placeholder='Pickup Charges' onChange={handleinput5} />
                            </Col>

                            <Col md={6} className=''>
                                <h6 className='text-blue fw-6'>Hamali</h6>
                                <input className="rounded-4 form-control mb-2" type="Number" value={form3.hamali_charges || ''} name="hamali_charges" id="loading_charges" placeholder='Loading Charges' onChange={handleinput5} />
                            </Col>

                            <Col md={6} className=''>
                                <h6 className='text-blue fw-6'>Adm. Ch.</h6>
                                <input className="rounded-4 form-control mb-2" type="Number" value={form3.adm_charges || ''} name="adm_charges" id="packing_charges" placeholder='Packing Charges' onChange={handleinput5} />
                            </Col>

                            <Col md={6} className=''>
                                <h6 className='text-blue fw-6'>PF</h6>
                                <input className="rounded-4 form-control mb-2" type="Number" value={form3.pf_charges || ''} name="pf_charges" id="service_charges" placeholder='Service Charges' onChange={handleinput5} />
                            </Col>

                            <Col md={6} className=''>
                                <h6 className='text-blue fw-6'>Handling Ch.</h6>
                                <input className="rounded-4 form-control mb-2" type="Number" value={form3.handling_charges || ''} name="handling_charges" id="other_charges" placeholder='Other Charges' onChange={handleinput5} />
                            </Col>

                            <Col md={6} className=''>
                                <h6 className='text-blue fw-6'>St. Ch.</h6>
                                <input className="rounded-4 form-control mb-2" type="Number" value={form3.st_charges || ''} name="st_charges" id="other_charges" placeholder='Other Charges' onChange={handleinput5} />
                            </Col>
                            <Col md={12} className=''>
                                <h6 className='text-blue fw-6'>Total Charges</h6>
                                <input
                                    className="rounded-4 form-control mb-2"
                                    readOnly
                                    type="Number"
                                    value={form3.total_charges || ''}
                                    name="total_charges"
                                    id="total_charges"
                                    placeholder='Total Charges'
                                    onChange={handleinput5}
                                />
                            </Col>
                            {/* <Col md={12} className='mt-3'>
                                <h4 className='fw-6'>TDS Details</h4>
                                <div className="d-flex">
                                    <div className="form-check me-4">
                                        <input type="radio" id="addition" name="TDS" defaultValue='addition' className="form-check-input" onClick={handleinput} />
                                        <label className="form-check-label fw-5" htmlFor="addition">Addition</label>
                                    </div>
                                    <div className="form-check me-4">
                                        <input type="radio" id="deduction" name="TDS" className="form-check-input" defaultValue='deduction' onClick={handleinput} />
                                        <label className="form-check-label fw-5" htmlFor="deduction">Deduction</label>
                                    </div>
                                </div>
                            </Col> */}
                            {/* <Col md={6} className='mt-3'>
                                <select className='border w-100 p-1 fw-5 rounded-3 color-gray'>
                                    <option className='d-none' selected disabled value="1">TDS Amount</option>
                                    <option value="Option 1">Option 1</option>
                                    <option value="Option 2">Option 2</option>
                                    <option value="Option 3">Option 3</option>
                                    <option value="Option 4">Option 4</option>
                                    <option value="Option 5">Option 5</option>
                                </select>
                            </Col> */}
                        </Row>





                        <Col md={12}>
                            <div className={`container-fluid d-flex justify-content-end  px-0 ${active ? "mt-0" : "mt-5"}`} >
                                <Button className='btn bg-white fs-5 fw-6 cancel me-3 rounded-4' onClick={handleprev}>Prev</Button>
                                <Button onClick={handleFormSubmit} className='btn  bg-blue fs-5 fw-6 text-white me-4 rounded-4' >Submit</Button>
                            </div>
                        </Col>

                    </Row>

                </div>}
            </form>

            {/* <Modal show={show} onHide={handleClose} centered >
                <Modal.Header className='bg-blue rounded-top-4'>
                    <Modal.Title className='fs-4 text-white'>Add Insaurance</Modal.Title>


                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={handlesubmit}>
                        <Row className='px-3 g-2'>


                            <Col md={12}>
                                <h6 className='text-blue fw-6'>Insaurance Provider</h6>
                                <input type="text" className='form-control mb-3   rounded-4' name="name" placeholder='Insaurance Provider' onChange={handleinput} required />
                            </Col>


                            <Col md={12}>
                                <h6 className='text-blue fw-6'>Insaurance Policy Number</h6>
                                <input type="text" className='form-control mb-3   rounded-4' name="name" placeholder='Insaurance Policy Number' onChange={handleinput} required />
                            </Col>

                            <Col md={12}>
                                <h6 className='text-blue fw-6'>Insaurance On</h6>
                                <input type="date" className='form-control  mb-3   rounded-4' name="date" placeholder='Insaurance On' onChange={handleinput} max={maxDate} required />
                            </Col>

                            <Col md={12}>
                                <h6 className='text-blue fw-6'>Insaurance Value</h6>
                                <input type="Number" className='form-control  mb-3   rounded-4' name="phone" placeholder='Insaurance Value' onChange={handleinput} required />
                            </Col>

                            <Col md={12}>
                                <div className="container-fluid d-flex justify-content-end my-2 px-0" >
                                    <Button className='btn bg-danger fs-5 fw-6 text-white me-3 rounded-4 px-4' onClick={handleClose}>Close</Button>
                                    <Button className='btn bg-blue fs-5 fw-6 text-white rounded-4 px-4' type="submit">Save</Button>
                                </div>
                            </Col>
                        </Row>


                    </form>
                </Modal.Body>
            </Modal> */}

            {/* Add Party */}
            <Modal show={show3} onHide={handleClose3} centered >
                <Modal.Header className='bg-blue rounded-top-4'>
                    <Modal.Title className='fs-4 text-white'>Add Party Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form >
                        <Row className='px-3 g-2'>
                            <Col md={12} className=''>
                                {/* <img src={upload} alt="image--" className='rounded-5' height={250} /> */}
                                <div className='text-center mb-2' >
                                    <img
                                        src={imagePreview || upload} // Use the preview or default image
                                        alt="image-preview"
                                        className="rounded-5"
                                        height={170}

                                        style={{ cursor: 'pointer', maxWidth: "300px" }} // Make the image look clickable
                                        onClick={triggerFileInput}

                                    />
                                    <input
                                        type="file"
                                        id="fileInput"
                                        style={{ display: 'none' }}
                                        accept="image/*"
                                        name="image"
                                        // onChange={handleinput}
                                        onChange={handleinput2}
                                        required
                                    />

                                </div>
                            </Col>

                            <Col md={12}>
                                <h6 className='text-blue fw-6'>Party Name</h6>
                                <input type="text" className='form-control mb-3   rounded-4' name="name" placeholder='Enter Name' onChange={handleinput2} required />
                            </Col>
                            <Col md={6}>
                                <h6 className='text-blue fw-6'>Opening Balance</h6>
                                <input type="Number" className='form-control  mb-3   rounded-4' name="balance" placeholder='Opening Balance' onChange={handleinput2} required />
                            </Col>
                            <Col md={6}>
                                <h6 className='text-blue fw-6'>Date</h6>
                                <input type="date" className='form-control  mb-3   rounded-4' name="date" placeholder='Date' onChange={handleinput2} max={maxDate} required />
                            </Col>

                            <Col md={12}>
                                <h6 className='text-blue fw-6'>Mobile Number</h6>
                                <input type="Number" className='form-control  mb-3   rounded-4' name="phone" placeholder='+91 Enter Mobile Number' onChange={handleinput2} required />
                            </Col>

                            <Col md={12}>
                                <div className="container-fluid d-flex justify-content-end my-2 px-0" >
                                    <Button className='btn bg-danger fs-5 fw-6 text-white me-3 rounded-4 px-4' onClick={handleClose3}>Close</Button>
                                    <Button className='btn bg-blue fs-5 fw-6 text-white rounded-4 px-4' onClick={handlesubmit2} data-bs-toggle="offcanvas" href="#offcanvasExample2">Save</Button>
                                </div>
                            </Col>
                        </Row>


                    </form>
                </Modal.Body>
            </Modal>

            {/* Add Truck */}
            <Modal show={show4} onHide={handleClose4} centered >
                <Modal.Header className='bg-blue rounded-top-4 '>
                    <Modal.Title className='fs-4 text-white'>Add Truck</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form >
                        <Row className='px-3 g-2'>
                            <Col md={12}>
                                <h6 className='text-blue fw-6'>Register Number</h6>
                                <input type="text" className='form-control mb-3   rounded-4' name="reg_no" placeholder='Register Number' onChange={handleinput3} />
                            </Col>

                            <Col md={12} className='border-bottom border-1 border-dark px-0 mb-3 pb-3' >
                                <div className="d-flex justify-content-between align-items-center">
                                    <div className='btn btn-truck text-center  rounded-4 p-1' onClick={() => handletype("Mini Truck/LCV")}>
                                        <img src={mini} alt="" />
                                        <h6 className='text-blue fw-6'>Mini Truck/LCV</h6>
                                    </div>
                                    <div className='btn btn-truck text-center  rounded-4 p-1' onClick={() => handletype("Open Body Truck")}>
                                        <img src={openbody} alt="" />
                                        <h6 className='text-blue fw-6'>Open Body Truck</h6>
                                    </div>
                                    <div className='btn btn-truck text-center  rounded-4 p-1' onClick={() => handletype("Closed Container")}>
                                        <img src={closed} alt="" />
                                        <h6 className='text-blue fw-6'>Closed Container</h6>
                                    </div>
                                </div>
                                <div className="d-flex justify-content-around align-items-center mt-3">
                                    <div className='btn btn-truck text-center  rounded-4 p-1' onClick={() => handletype("Tank")}>
                                        <img src={tank} alt="" />
                                        <h6 className='text-blue fw-6'>Tank</h6>
                                    </div>
                                    <div className='btn btn-truck text-center  rounded-4 p-1' onClick={() => handletype("Heavy")}>
                                        <img src={heavy} alt="" />
                                        <h6 className='text-blue fw-6'>Heavy</h6>
                                    </div>
                                    <div className='btn btn-truck text-center  rounded-4 p-1' onClick={() => handletype("Bus")}>
                                        <img src={bus} alt="" />
                                        <h6 className='text-blue fw-6'>Bus</h6>
                                    </div>
                                </div>
                            </Col>
                            <Col md={12}>
                                <h6 className='text-blue fw-6'>Owner Ship</h6>
                                <div className="d-flex">
                                    <div className="form-check me-4">
                                        <input className="form-check-input" type="radio" value="Market Truck" name="ownership" id="flexCheckChecked" onChange={handleinput3} />
                                        <label className="form-check-label" for="flexCheckChecked">
                                            Market Truck
                                        </label>
                                    </div>
                                    <div className="form-check me-4">
                                        <input className="form-check-input" type="radio" value="My Truck" name="ownership" id="flexCheckChecked" onChange={handleinput3} />
                                        <label className="form-check-label" for="flexCheckChecked">
                                            My Truck
                                        </label>
                                    </div>
                                </div>
                            </Col>
                            <Col md={12} style={{ display: display }}>
                                {/* <h6 className='text-blue fw-6'>Select Driver</h6> */}
                                <select className='form-select rounded-4 my-2' name="driver" onChange={handleinput3}>
                                    <option value=" " selected>Select Driver</option>
                                    {driver.slice().reverse().map((item, index) => (
                                        <option value={item.id} key={index}>{item.name}</option>
                                    ))}

                                </select>
                            </Col>
                            <Col md={12} style={{ display: display2 }}>
                                {/* <h6 className='text-blue fw-6'>Select Driver</h6> */}
                                <select className='form-select rounded-4 my-2' name="supplier" onChange={handleinput3}>
                                    <option value=" " selected>Select Supplier</option>
                                    {supplier.slice().reverse().map((item, index) => (
                                        <option value={item.id} key={index}>{item.name}</option>
                                    ))}

                                </select>
                            </Col>
                            <Col md={12}>
                                <div className="container-fluid d-flex justify-content-end my-2 px-0" >
                                    <Button className='btn bg-danger fs-5 fw-6 text-white me-3 rounded-4 px-4' onClick={handleClose4}>Close</Button>
                                    <Button className='btn bg-blue fs-5 fw-6 text-white rounded-4 px-4' onClick={handlesubmit3} data-bs-toggle="offcanvas" href="#offcanvasExample2">Confirm</Button>
                                </div>
                            </Col>
                        </Row>

                    </form>

                </Modal.Body>
            </Modal>

            {/* Add Driver */}
            <Modal show={show5} onHide={handleClose5} centered >
                <Modal.Header className='bg-blue rounded-top-4'>
                    <Modal.Title className='fs-4 text-white'>Add Driver Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form>

                        <Row className='px-3 g-2'>

                            <Col md={12}>
                                <h6 className='text-blue fw-6'>Driver Name</h6>
                                <input type="text" className='form-control mb-3  rounded-4' name='name' placeholder='Enter Name' onChange={handleinput4} />
                            </Col>

                            <Col md={12}>
                                <h6 className='text-blue fw-6'>Mobile Number</h6>
                                <input type="Number" className='form-control  mb-3   rounded-4' name='phone' placeholder='+91 Enter Mobile Number' onChange={handleinput4} />
                            </Col>

                            <Col md={12}>
                                <div className="container-fluid d-flex justify-content-end my-2 px-0" >
                                    <Button className='btn bg-danger fs-5 fw-6 text-white me-3 rounded-4 px-4' onClick={handleClose5}>Close</Button>
                                    <Button className='btn bg-blue fs-5 fw-6 text-white rounded-4 px-4' onClick={handleSubmit4} data-bs-toggle="offcanvas" href="#offcanvasExample2">Save</Button>
                                </div>
                            </Col>
                        </Row>
                    </form>
                </Modal.Body>
            </Modal>

            <Modal show={show6} onHide={handleClose6} centered >
                <Modal.Header className='bg-blue rounded-top-4'>
                    <Modal.Title className='fs-4 text-white'>Add Insurance</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={{}}>
                        <Row className='px-3 g-2'>
                            <Col md={12}>
                                <h6 className='text-blue fw-6'>Company</h6>
                                <input type="text" className='form-control mb-3  rounded-4' name='company' value={form4.company || ''} placeholder='Enter Name' onChange={handleinputinsau} />
                            </Col>

                            <Col md={12}>
                                <h6 className='text-blue fw-6'>Policy No</h6>
                                <input type="Number" className='form-control  mb-3   rounded-4' name='policyno' value={form4.policyno || ''} placeholder='Enter Policy No' onChange={handleinputinsau} />
                            </Col>

                            <Col md={12}>
                                <h6 className='text-blue fw-6'>Date</h6>
                                <input type="date" className='form-control mb-3   rounded-4' name='date' value={form4.date || ''} onChange={handleinputinsau} />
                            </Col>

                            <Col md={12}>
                                <h6 className='text-blue fw-6'>Amount</h6>
                                <input type="number" className='form-control mb-3   rounded-4' name='amount' value={form4.amount || ''} placeholder='Enter Amount' onChange={handleinputinsau} />
                            </Col>

                            <Col md={12}>
                                <h6 className='text-blue fw-6'>Risk</h6>
                                <input type="text" className='form-control mb-3   rounded-4' name='risk' value={form4.risk || ''} placeholder='Enter Risk' onChange={handleinputinsau} />
                            </Col>

                            <Col md={12}>
                                <div className="container-fluid d-flex justify-content-end my-2 px-0" >
                                    <Button className='btn bg-danger fs-5 fw-6 text-white me-3 rounded-4 px-4' onClick={handleClose6}>Close</Button>
                                    <Button className='btn bg-blue fs-5 fw-6 text-white rounded-4 px-4' data-bs-toggle="offcanvas" href="#offcanvasExample3" onClick={handleClose6}>Save</Button>
                                </div>
                            </Col>
                        </Row>
                    </form>
                </Modal.Body>
            </Modal>
        </div>

    )

}

export default AddtripEdit
