import React, { useState } from 'react'
import { Container, Card, Button, InputGroup, Form, Row, Col, Modal } from 'react-bootstrap'
import { NavLink } from 'react-router-dom';
import Sidebar from './sidebar';
import user_logo from '../assests/user-logo.svg'
import user_img from '../assests/user_image.svg'
import { BsSearch } from "react-icons/bs";
import { RiDeleteBin6Line } from "react-icons/ri";
import { FaRegEye } from "react-icons/fa";
import { IoIosAddCircleOutline } from "react-icons/io";
import SidebarMob from './siderbar_mobile';
import { useNavigate } from 'react-router-dom';
import Usermenu from './user_menu';
function MontlyBal() {

    const nevigate = useNavigate()
    const [show, setShow] = useState(false);
    const [show2, setShow2] = useState(false);
    const [display, setDisplay] = useState('none');
    const [display2, setDisplay2] = useState('block');

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleClose2 = () => setShow2(false);
    const handleShow2 = () => setShow2(true);

    const [isDriverSelected, setIsDriverSelected] = useState(false);

    const handleCheckboxToggle = () => {
        setIsDriverSelected(!isDriverSelected);
    };

    const handletrips = () => {
        setDisplay('block')
        setDisplay2('none')
    }

    const handletrip = () => {
        nevigate('/partiesuser')
     }
    const handlepassbook = () => {
        nevigate('/passbook')
     }
    return (
        <div>
            <Container fluid className='px-0 vh-100 d-flex'>
                <Sidebar />
                <SidebarMob />
                <div className='m-0 p-0 w-100 padding-20'>
                    <Container fluid className='d-flex justify-content-between px-5 mt-4 align-items-center'>
                        <h1 className='text-blue fw-6'>Joe Rogan</h1>

                        <div className="d-flex justify-content-end align-items-center">
                            <Button className='btn bg-blue fs-5 fw-6 text-white me-3 h-50 rounded-4' onClick={handleShow}><IoIosAddCircleOutline className='me-2 fs-4 mb-1' />Add Payment</Button>
                            <NavLink to='/useradd' className='btn bg-black fs-5 fw-6 text-white me-3 h-50 rounded-4'><IoIosAddCircleOutline className='me-2 fs-4 mb-1' />Add Trip</NavLink>
                            <Usermenu/>
                        </div>
                    </Container>
                    <Card className='d-flex justify-content-between  mt-4 align-items-center mx-5 flex-row shadow shadow-sm rounded-4 px-2 py-4'>
                        <div className="d-flex">
                            <Button to='/useradd' className=' bg-white fs-5 fw-6 text-blue me-3 border border-dark  h-50 rounded-4' onClick={handletrip}>Trips</Button>
                            <Button to='/useradd' className=' bg-white fs-5 fw-6 text-blue  border border-dark me-3 h-50 rounded-4' onClick={handlepassbook}>Passbook</Button>
                            <Button to='/useradd' className='  bg-blue fs-5 fw-6 text-white me-3 h-50 rounded-4'>Monthly Balance</Button>
                            <Button to='/useradd' className=' bg-white fs-5 fw-6 text-blue border border-dark me-3 h-50 rounded-4'>Party Details</Button>
                        </div>
                        <div className="d-flex">
                            <Button to='/useradd' className=' bg-white fs-5 fw-6 text-blue border border-dark  h-50 rounded-4'>Collection Reminder</Button>

                        </div>

                    </Card>
                    <Card className=' mx-5 mt-2 pb-4 shadow shadow-md ' >
                        <div className='d-flex justify-content-between align-items-center mt-2 pe-3 '>
                            <div className="d-flex">
                                <Button className=' bg-white fs-5 fw-6 text-dark border border-dark ms-4 me-2 h-50 rounded-4'>Trip Party Balance <span className='text-blue ms-2'> $37,0258</span></Button>
                            
                            </div>
                            <div className='text-end  '>
                                <InputGroup className="mb-3 mt-3">
                                    <Form.Control
                                        placeholder="Search.."
                                        aria-label="search"
                                        aria-describedby="basic-addon2"
                                    />
                                    <InputGroup.Text id="basic-addon2"><BsSearch /></InputGroup.Text>
                                </InputGroup>
                            </div>
                        </div>
                    
                        <div className="table-responsive px-2 pt-4">

                            <table className='table table-striped  table-hover'>
                                <thead>
                                    <tr>
                                        <th><h4>#</h4></th>
                                        <th><h4>Month</h4></th>
                                        <th><h4>Trip Started</h4></th>
                                        <th><h4>Trip Settled</h4></th>
                                        <th><h4>Revenue</h4></th>
                                        <th><h4>Payment</h4></th>
                                        <th><h4>Balance</h4></th>
                                        <th><h4>Collection</h4></th>
                                        <th><h4>Action</h4></th>
                                    </tr>
                                </thead>
                                <tbody >
                                    <tr className='pt-4'>
                                        <td ><h5 className='mt-2'>1</h5></td>
                                        <td><h5 className='mt-2'> August 2024</h5></td>
                                        <td><h5 className='mt-2'>2</h5></td>
                                        <td><h5 className='mt-2'>1</h5></td>
                                        <td><h5 className='mt-2'>3750200</h5></td>
                                        <td><h5 className='mt-2'>3512000</h5></td>
                                        <td><h5 className='btn text-blue '>20552255</h5></td>
                                        <td><h5 className='btn text-blue '>94</h5></td>
                                        <td><h5 className='btn text-blue '>View PDF</h5></td>
                                    </tr>
                               
                                </tbody>
                            </table>
                        </div>

                        <div className="text-end">
                            <Button className=' bg-danger fs-5 fw-6 text-white me-3 rounded-4 mt-3'>View PDF</Button>
                        </div>
                    </Card>
                </div>
            </Container>

        </div>
    )
}

export default MontlyBal;