import React, { useEffect, useState } from 'react'
import { NavLink, useNavigate } from 'react-router-dom';
import user_logo from '../../assests/user-logo.svg'
import AddminLogo from '../../assests/admin_setting.svg'
import Rupee from '../../assests/rupee.svg';
import Notification from '../../assests/notification.svg';
import Logout from '../../assests/logout.svg';
import axios from 'axios';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { toast, ToastContainer } from 'react-toastify';


const AddStaffsalary = ({ isLoad, success }) => {
    const nevigate = useNavigate()
    const [staff, setStaff] = useState({
        staff_name: '',
        payment_mode: ''
    })
    const [supplier, setSuppleir] = useState({
        payment_mode: '',
        supplier: ''
    })
    const [expenses, setExpenses] = useState(
        {
            payment_mode: '',
            itemname: ''
        }
    )
    const [supplierdata, setSupplierdata] = useState([])
    const handleinput = (e) => {
        const { name, value } = e.target;
        // Convert value to a number if it's supposed to be numeric
        if (name === 'total_amount' || name === 'amount_paid') {
            const numericValue = Number(value);
            // Get current values from staff state
            const currentTotalAmount = staff?.total_amount || 0;
            const currentAmountPaid = staff?.amount_paid || 0;

            // Compute the balance amount based on the input field being updated
            let balance_amount = currentTotalAmount - currentAmountPaid;

            // Update the appropriate field based on the name
            if (name === 'total_amount') {
                balance_amount = numericValue - currentAmountPaid || 0;
            } else if (name === 'amount_paid') {
                balance_amount = currentTotalAmount - numericValue || 0;
            }

            // Create the updated form state object
            const updatedForm = { ...staff, [name]: numericValue, balance_amount };

            // Update the state
            setStaff(updatedForm);
        }
        else {
            const updatedForm = { ...staff, [name]: value };

            setStaff(updatedForm);
        }


    };
    const handleinputstaff = (e) => {
        const { name, value } = e.target;
        // Convert value to a number if it's supposed to be numeric
        if (name === 'total_amount' || name === 'amount_paid') {
            const numericValue = Number(value);
            // Get current values from staff state
            const currentTotalAmount = supplier?.total_amount || 0;
            const currentAmountPaid = supplier?.amount_paid || 0;

            // Compute the balance amount based on the input field being updated
            let balance_amount = currentTotalAmount - currentAmountPaid;

            // Update the appropriate field based on the name
            if (name === 'total_amount') {
                balance_amount = numericValue - currentAmountPaid || 0;
            } else if (name === 'amount_paid') {
                balance_amount = currentTotalAmount - numericValue || 0;
            }
            setSuppleir(prevState => ({ ...prevState, [name]: numericValue, balance_amount }));

        }
        else {
            setSuppleir(prevState => ({ ...prevState, [name]: value }));

        }


    };
    const handleinputexp = (e) => {
        const { name, value } = e.target;
        // Convert value to a number if it's supposed to be numeric
        if (name === 'total_amount' || name === 'amount_paid') {
            const numericValue = Number(value);
            // Get current values from staff state
            const currentTotalAmount = expenses?.total_amount || 0;
            const currentAmountPaid = expenses?.amount_paid || 0;

            // Compute the balance amount based on the input field being updated
            let balance_amount = currentTotalAmount - currentAmountPaid;

            // Update the appropriate field based on the name
            if (name === 'total_amount') {
                balance_amount = numericValue - currentAmountPaid || 0;
            } else if (name === 'amount_paid') {
                balance_amount = currentTotalAmount - numericValue || 0;
            }
            setExpenses(prevState => ({ ...prevState, [name]: numericValue, balance_amount }));

        }
        else {
            setExpenses(prevState => ({ ...prevState, [name]: value }));

        }


    };
    const handleStaff = (e) => {
        e.preventDefault()
        if (!staff?.total_amount || isNaN(staff.total_amount) || Number(staff.total_amount) <= 0) {
            toast.error("Total amount must be a positive number.");
            return;
        }
        if (!staff?.amount_paid || isNaN(staff.amount_paid) || Number(staff.amount_paid) < 0) {
            toast.error("Amount paid must be a non-negative number.");
            return;
        }

        if (!staff?.payment_mode.trim()) {
            toast.error("Payment Mode is required.");
            return;
        }
        if (!staff?.payment_mode.trim()) {
            toast.error("Payment Mode is required.");
            return;
        }
        if (!staff?.staff_name.trim()) {
            toast.error("Staff Name is required.");
            return;
        }
        if (staff?.total_amount && staff?.payment_mode && staff?.staff_name && staff?.amount_paid) {

            const newid = toast.loading("Please wait...", {
                type: "loading",
                isLoading: true,
                icon: true,
                autoClose: 3000,
                closeButton: true,
                closeOnClick: true,
            })
            axios.post('https://freight.pigc.ca/backend/public/addstaff', staff)
                .then((response) => {
                    if (response.data === "success") {

                        toast.update(newid, {
                            render: "Staff Added Successfully !",
                            type: "success",
                            isLoading: false,
                            icon: true,
                            autoClose: 3000,
                            closeButton: true,
                            closeOnClick: true,
                        })

                        setTimeout(() => {
                            window.location.reload();
                        }, 3000)
                    } else {
                        setTimeout(() => {
                            toast.update(newid, {
                                render: "Something went wrong !!",
                                type: "error",
                                isLoading: false,
                                icon: true,
                                autoClose: 3000,
                                closeButton: true,
                                closeOnClick: true,
                            })
                            window.location.reload();
                        }, 1000);
                        // notify_error()
                    }

                })
                .catch((error) => {
                    console.log(error)
                })
        }
    }
    const handleSupplier = (e) => {
        e.preventDefault()
        if (!supplier?.total_amount || isNaN(supplier.total_amount) || Number(supplier.total_amount) <= 0) {
            toast.error("Total amount must be a positive number.");
            return;
        }
        if (!supplier?.amount_paid || isNaN(supplier.amount_paid) || Number(supplier.amount_paid) < 0) {
            toast.error("Amount paid must be a non-negative number.");
            return;
        }

        if (!supplier?.payment_mode.trim()) {
            toast.error("Payment Mode is required.");
            return;
        }
        if (!supplier?.payment_mode.trim()) {
            toast.error("Payment Mode is required.");
            return;
        }
        // (r^(xrS)nv5l
        if (!supplier?.supplier.trim()) {
            toast.error(" Supplier Name is required.");
            return;
        }
        if (supplier?.total_amount && supplier?.payment_mode && supplier?.supplier && supplier?.amount_paid) {
            const newid = toast.loading("Please wait...", {
                type: "loading",
                isLoading: true,
                icon: true,
                autoClose: 3000,
                closeButton: true,
                closeOnClick: true,
            })
            axios.post(`https://freight.pigc.ca/backend/public/addsupplierpayment`, supplier)
                .then((response) => {
                    if (response.data === "success") {

                        toast.update(newid, {
                            render: "Staff Added Successfully !",
                            type: "success",
                            isLoading: false,
                            icon: true,
                            autoClose: 3000,
                            closeButton: true,
                            closeOnClick: true,
                        })

                        setTimeout(() => {
                            window.location.reload();
                        }, 3000)
                    } else {
                        setTimeout(() => {
                            toast.update(newid, {
                                render: "Something went wrong !!",
                                type: "error",
                                isLoading: false,
                                icon: true,
                                autoClose: 3000,
                                closeButton: true,
                                closeOnClick: true,
                            })
                            window.location.reload();
                        }, 1000);
                        // notify_error()
                    }

                })
                .catch((error) => {
                    console.log(error)
                })
        }
    }
    const getSupplier = async () => {
        await axios.get(`https://freight.pigc.ca/backend/public/getsupplier`)
            .then((response) => {
                if (response.data !== 'error')
                    setSupplierdata(response.data)
                else
                    setSupplierdata([])
                console.log(response.data, 'hjdddfk')
            })
            .catch((error) => {
                console.log("error", error)
            })
    }
    const handleExpenses = (e) => {
        e.preventDefault()
        if (!expenses?.total_amount || isNaN(expenses.total_amount) || Number(expenses.total_amount) <= 0) {
            toast.error("Total amount must be a positive number.");
            return;
        }
        if (!expenses?.amount_paid || isNaN(expenses.amount_paid) || Number(expenses.amount_paid) < 0) {
            toast.error("Amount paid must be a non-negative number.");
            return;
        }

        if (!expenses?.payment_mode.trim()) {
            toast.error("Payment Mode is required.");
            return;
        }
       
        if (!expenses?.itemname.trim()) {
            toast.error("Item Name is required.");
            return;
        }
        if (expenses?.total_amount && expenses?.payment_mode && expenses?.itemname && expenses?.amount_paid) {
            const newid = toast.loading("Please wait...", {
                type: "loading",
                isLoading: true,
                icon: true,
                autoClose: 3000,
                closeButton: true,
                closeOnClick: true,
            })
            axios.post(`https://freight.pigc.ca/backend/public/addexpenses`, expenses)
                .then((response) => {
                    if (response.data === "success") {

                        toast.update(newid, {
                            render: "Expenses Added Successfully !",
                            type: "success",
                            isLoading: false,
                            icon: true,
                            autoClose: 3000,
                            closeButton: true,
                            closeOnClick: true,
                        })

                        setTimeout(() => {
                            window.location.reload();
                        }, 3000)
                    } else {
                        setTimeout(() => {
                            toast.update(newid, {
                                render: "Something went wrong !!",
                                type: "error",
                                isLoading: false,
                                icon: true,
                                autoClose: 3000,
                                closeButton: true,
                                closeOnClick: true,
                            })
                            window.location.reload();
                        }, 1000);
                        // notify_error()
                    }

                })
                .catch((error) => {
                    console.log(error)
                })
        }
    }
    useEffect(() => {
        getSupplier()
    }, [])

    return (
        <>
            <ToastContainer
                position="top-left"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                theme="dark"
            />
            <div className='h-100 offcanvas w-50 offcanvas-end' tabIndex="-1" id="Addstaffsalary" aria-labelledby="offcanvasExampleLabel2">
                {/* 
                <form className=''>
                    <div >

                        <Row className=' add-trip '>
                            <h3 className='text-white fw-6 mb-3 bg-blue py-3 ps-4 '>Add Staff Salary</h3>
                            <Row className='trip-details ps-4 '>
                                <Row className='trip-details ps-4 '>
                                    <Col md={6}>
                                        <h6 className='text-blue fw-6'>Name</h6>
                                        <input type="text" className={`form-control rounded-4 mb-1 `} name="staff_name" onChange={handleinput} required value={staff?.staff_name || ''} placeholder='Enter Name' />
                                    </Col>
                                    <Col md={6}>
                                        <h6 className='text-blue fw-6'>Total Amount</h6>
                                        <input type="text" className={`form-control rounded-4 mb-1 `} name="total_amount" onChange={handleinput} required value={staff?.total_amount || ''} placeholder='Enter Amount' />
                                    </Col>
                                    <Col md={6}>
                                        <h6 className='text-blue fw-6'> Amount Paid</h6>
                                        <input type="text" className={`form-control rounded-4 mb-1 `} name="amount_paid" onChange={handleinput} required value={staff?.amount_paid || ''} placeholder='Enter Amount' />
                                    </Col>
                                    <Col md={6}>
                                        <h6 className='text-blue fw-6'>Balance Amount</h6>
                                        <input type="text" className={`form-control rounded-4 mb-1 `} name="balance_amount" readOnly value={staff?.balance_amount || ''} required onChange={handleinput} placeholder='Enter Amount' />
                                    </Col>
                                    <Col md={6}>
                                        <h6 className='text-blue fw-6'>Remarks</h6>
                                        <input type="text" className={`form-control rounded-4 mb-1 `} name="discription" value={staff?.discription || ''} required onChange={handleinput} placeholder='Enter Amount' />
                                    </Col>
                                    <Col md={6}>
                                        <h6 className='text-blue fw-6'>Payment Status</h6>
                                        <Form.Select className={`rounded-4 mt-1`} onChange={handleinput} name="status" value={staff?.status || ''} required  >\
                                            <option defaultValue='' disabled selected>Select Payment Status</option>
                                            <option value='paid'>Paid</option>
                                            <option value='unpaid'>UnPaid</option>
                                        </Form.Select>
                                    </Col>
                                    <Col md={6}>
                                        <h6 className='text-blue fw-6'>Payment Mode</h6>
                                        <Form.Select className={`rounded-4 mt-1`} onChange={handleinput} name="payment_mode" value={staff?.status || ''}  >
                                            <option defaultValue='' disabled selected>Select Mode</option>
                                            <option value='Online'>Online</option>
                                            <option value='Cash'>Cash</option>
                                        </Form.Select>
                                    </Col>

                                    <Col md={6}>
                                        <Button className='btn bg-blue mt-3 fs-5 fw-6 text-white rounded-4 px-4 me-0 ms-auto' onClick={handleStaff} type='submit'>Add Staff Details</Button>
                                    </Col>
                                </Row>
                            </Row>


                        </Row>

                    </div>

                </form> */}
                <form>
                    <div>
                        <Row className='add-trip'>
                            <h3 className='text-white fw-6 mb-3 bg-blue py-3 ps-4'>Add Staff Salary</h3>
                            <Row className='trip-details ps-4'>
                                <Col md={6}>
                                    <h6 className='text-blue fw-6'>Name</h6>
                                    <input
                                        type="text"
                                        className='form-control rounded-4 mb-1'
                                        name="staff_name"
                                        onChange={handleinput}
                                        value={staff?.staff_name || ''}
                                        placeholder='Enter Name'
                                        required
                                    />
                                </Col>
                                <Col md={6}>
                                    <h6 className='text-blue fw-6'>Total Amount</h6>
                                    <input
                                        type="text"
                                        className='form-control rounded-4 mb-1'
                                        name="total_amount"
                                        onChange={handleinput}
                                        value={staff?.total_amount || ''}
                                        placeholder='Enter Amount'
                                        required
                                    />
                                </Col>
                                <Col md={6}>
                                    <h6 className='text-blue fw-6'>Amount Paid</h6>
                                    <input
                                        type="text"
                                        className='form-control rounded-4 mb-1'
                                        name="amount_paid"
                                        onChange={handleinput}
                                        value={staff?.amount_paid || ''}
                                        placeholder='Enter Amount'
                                        required
                                    />
                                </Col>
                                <Col md={6}>
                                    <h6 className='text-blue fw-6'>Balance Amount</h6>
                                    <input
                                        type="text"
                                        className='form-control rounded-4 mb-1'
                                        name="balance_amount"
                                        readOnly
                                        value={staff?.balance_amount || ''}
                                        placeholder='Enter Amount'
                                    />
                                </Col>
                                <Col md={6}>
                                    <h6 className='text-blue fw-6'>Remarks</h6>
                                    <input
                                        type="text"
                                        className='form-control rounded-4 mb-1'
                                        name="discription"
                                        value={staff?.discription || ''}
                                        onChange={handleinput}
                                        placeholder='Enter Remarks'
                                    />
                                </Col>
                                <Col md={6}>
                                    <h6 className='text-blue fw-6'>Payment Status</h6>
                                    <Form.Select
                                        className='rounded-4 mt-1'
                                        onChange={handleinput}
                                        name="status"
                                        value={staff?.status || ''}
                                        required
                                    >
                                        <option defaultValue='' selected >Select Status</option>
                                        <option value='paid'>Paid</option>
                                        <option value='unpaid'>UnPaid</option>
                                    </Form.Select>
                                </Col>
                                <Col md={6}>
                                    <h6 className='text-blue fw-6'>Payment Mode</h6>
                                    <Form.Select
                                        className='rounded-4 mt-1'
                                        onChange={handleinput}
                                        name="payment_mode"
                                        value={staff?.payment_mode || ''}
                                        required
                                    >
                                        <option defaultValue='' selected >Select Payment Mode</option>
                                        <option value='Online'>Online</option>
                                        <option value='Cash'>Cash</option>
                                    </Form.Select>
                                </Col>
                                <Col md={6}></Col>
                                <Col md={6}>
                                    <Button
                                        className='btn bg-blue mt-4 fs-5 fw-6 text-white rounded-4 px-4 me-0 ms-auto'
                                        onClick={handleStaff}
                                        type='submit'
                                    >
                                        Add Staff Details
                                    </Button>
                                </Col>
                            </Row>
                        </Row>
                    </div>
                </form>

            </div>
            <div className='h-100 offcanvas w-50 offcanvas-end' tabIndex="-1" id="Addsuppliersalary" aria-labelledby="offcanvasExampleLabel2">
                <form className=''>
                    <div >

                        <Row className=' add-trip '>
                            <h3 className='text-white fw-6 mb-3 bg-blue py-3 ps-4 '>Add Supplier</h3>
                            <Row className='trip-details ps-4 '>
                                <Col md={6}>
                                    <h6 className='text-blue fw-6'>Supplier Name</h6>
                                    <Form.Select className={`rounded-4 mt-1`} onChange={handleinputstaff} name="supplier" value={supplier?.supplier || ''}  >

                                        <option value='' disabled>Select Mode</option>
                                        {supplierdata?.map((input, index) => (
                                            <option value={input?.id}>{input?.name}</option>
                                        ))}
                                        <option value='Cash'>Cash</option>
                                    </Form.Select>
                                </Col>
                                <Col md={6}>
                                    <h6 className='text-blue fw-6'>Total Amount</h6>
                                    <input type="text" className={`form-control rounded-4 mb-1 `} name="total_amount" onChange={handleinputstaff} value={supplier?.total_amount || ''} placeholder='Enter Amount' />
                                </Col>
                                <Col md={6}>
                                    <h6 className='text-blue fw-6'> Amount Paid</h6>
                                    <input type="text" className={`form-control rounded-4 mb-1 `} name="amount_paid" onChange={handleinputstaff} value={supplier?.amount_paid || ''} placeholder='Enter Amount' />
                                </Col>
                                <Col md={6}>
                                    <h6 className='text-blue fw-6'>Balance Amount</h6>
                                    <input type="text" className={`form-control rounded-4 mb-1 `} name="balance_amount" readOnly value={supplier?.balance_amount || 0} onChange={handleinputstaff} placeholder='Enter Amount' />
                                </Col>
                                <Col md={6}>
                                    <h6 className='text-blue fw-6'>Remarks</h6>
                                    <input type="text" className={`form-control rounded-4 mb-1 `} name="discription" value={supplier?.discription || ''} onChange={handleinputstaff} placeholder='Enter Amount' />
                                </Col>

                                <Col md={6}>
                                    <h6 className='text-blue fw-6'>Payment Mode</h6>
                                    <Form.Select className={`rounded-4 mt-1`} onChange={handleinputstaff} name="payment_mode" value={supplier?.status || ''}  >
                                        <option value='' disabled>Select Mode</option>
                                        <option value='Online'>Online</option>
                                        <option value='Cash'>Cash</option>
                                    </Form.Select>
                                </Col>

                                <Col md={6}>
                                    <Button className='btn bg-blue mt-3 fs-5 fw-6 text-white rounded-4 px-4 me-0 ms-auto' onClick={handleSupplier}>Add Supplier</Button>
                                </Col>
                            </Row>


                        </Row>

                    </div>

                </form>


            </div>
            <div className='h-100 offcanvas w-50 offcanvas-end' tabIndex="-1" id="OtherExpenses" aria-labelledby="offcanvasExampleLabel2">
                <form className=''>
                    <div >

                        <Row className=' add-trip '>
                            <h3 className='text-white fw-6 mb-3 bg-blue py-3 ps-4 '>Other Expenses</h3>
                            <Row className='trip-details ps-4 '>
                                <Col md={6}>
                                    <h6 className='text-blue fw-6'>Item Name</h6>
                                    <input type="text" className={`form-control rounded-4 mb-1 `} name="itemname" onChange={handleinputexp} value={expenses?.itemname || ''} placeholder='Enter Amount' />
                                </Col>
                                <Col md={6}>
                                    <h6 className='text-blue fw-6'>Total Amount</h6>
                                    <input type="text" className={`form-control rounded-4 mb-1 `} name="total_amount" onChange={handleinputexp} value={expenses?.total_amount || ''} placeholder='Enter Amount' />
                                </Col>
                                <Col md={6}>
                                    <h6 className='text-blue fw-6'> Amount Paid</h6>
                                    <input type="text" className={`form-control rounded-4 mb-1 `} name="amount_paid" onChange={handleinputexp} value={expenses?.amount_paid || ''} placeholder='Enter Amount' />
                                </Col>
                                <Col md={6}>
                                    <h6 className='text-blue fw-6'>Balance Amount</h6>
                                    <input type="text" className={`form-control rounded-4 mb-1 `} name="balance_amount" readOnly value={expenses?.balance_amount || 0} onChange={handleinputexp} placeholder='Enter Amount' />
                                </Col>
                                <Col md={6}>
                                    <h6 className='text-blue fw-6'>Remarks</h6>
                                    <input type="text" className={`form-control rounded-4 mb-1 `} name="discription" value={expenses?.discription || ''} onChange={handleinputexp} placeholder='Enter Amount' />
                                </Col>

                                <Col md={6}>
                                    <h6 className='text-blue fw-6'>Payment Mode</h6>
                                    <Form.Select className={`rounded-4 mt-1`} onChange={handleinputexp} name="payment_mode" value={expenses?.status || ''}  >
                                        <option value='' disabled>Select Mode</option>
                                        <option value='Online'>Online</option>
                                        <option value='Cash'>Cash</option>
                                    </Form.Select>
                                </Col>

                                <Col md={6}>
                                    <Button className='btn bg-blue mt-3 fs-5 fw-6 text-white rounded-4 px-4 me-0 ms-auto' onClick={handleExpenses}>Add Expenses</Button>
                                </Col>
                            </Row>


                        </Row>

                    </div>

                </form>


            </div>

        </>
    )
}

export default AddStaffsalary