import React, { useEffect, useState } from 'react'
import { Container, Card, Button, InputGroup, Form, Row, Col, Modal } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom';
import Sidebar from './sidebar';
import SidebarMob from './siderbar_mobile';
import { BsSearch } from "react-icons/bs";
import { FaRegEye } from "react-icons/fa";
import { IoIosAddCircleOutline } from "react-icons/io";
import { RiEdit2Fill } from "react-icons/ri";
import Addtrip from './addtrip';
import Usermenu from './user_menu';
import Addtrip1 from './addtrip_edit';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import axios from 'axios';
import { useAuth } from './AuthContext';
function Trips() {
    const [filteredBuilty, setFilteredBuilty] = useState([]);
    const { userData } = useAuth();
    const usertype = sessionStorage.getItem('user_type')
    const isAuth = sessionStorage.getItem('isAuth')
    const userid = sessionStorage.getItem('id');
    const nevigate = useNavigate()
    const [search, setSearch] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState(false)
    const [show, setShow] = useState(false);
    const [show2, setShow2] = useState(false);
    const [show3, setShow3] = useState();
    const [driver, setDriver] = useState([]);
    const [supplier, setSupplier] = useState([]);
    const [truck, setTruck] = useState([]);
    const [truckweight, setTruckweight] = useState({});
    const handleClose3 = () => setShow3(false);
    const handleShow3 = () => setShow3(true);

    const [user, setUser] = useState([]);
    const [builty, setBuilty] = useState([]);
    const [builtycount, setBiltyCount] = useState(-1);
    const [display, setDisplay] = useState('none');
    const [display2, setDisplay2] = useState('block');
    const [form, setForm] = useState({});
    const [addtruckdetail, setAddtruckdetail] = useState({});

    const [parties, setParties] = useState([]);
    const [BityId, setBityId] = useState('');
    const [selectedIds, setSelectedIds] = useState(new Set());
    const [currBiltyWeight, setCurrBiltyWeight] = useState(0);
    const [isHidden, setIsHidden] = useState(true);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleClose2 = () => setShow2(false);
    const handleShow2 = () => setShow2(true);

    const [isDriverSelected, setIsDriverSelected] = useState(false);

    const handleCheckboxToggle = () => {
        setIsDriverSelected(!isDriverSelected);
    };

    const handletrips = () => {
        setDisplay('block')
        setDisplay2('none')
    }
    const handletripone = (id) => {
        nevigate(`/tripsone/${id}`)
    }

    const handletruckdetail = (e) => {
        const updatedField = {
            [e.target.name]: e.target.value,
        };
        let updatedForm = {
            ...addtruckdetail,
            ...updatedField,  // Update the specific field
            "bilty_weight": currBiltyWeight,  // Update bilty_weight with currBiltyWeight
        };

        var i = 1;
        selectedIds.forEach((value, index) => {
            updatedForm[`id_${i}`] = value; // You can customize the key here as needed
            i++
        });


        setAddtruckdetail(updatedForm);


    };

    const handleCheckboxChange = (itemId, itemWeight) => {
        setSelectedIds(prevSelectedIds => {
            const updatedSelectedIds = new Set(prevSelectedIds);
            if (updatedSelectedIds.has(itemId)) {
                updatedSelectedIds.delete(itemId);
                setCurrBiltyWeight(parseInt(currBiltyWeight) - parseInt(itemWeight));
            } else {
                updatedSelectedIds.add(itemId);
                setCurrBiltyWeight(parseInt(currBiltyWeight) + parseInt(itemWeight));
            }
            return updatedSelectedIds;
        })
    };

    const handletruck = (e) => {

        const { name, value } = e.target;

        // Determine the value based on the field name
        let balanceAmount = form?.balance_amount || 0
        const updatedField = {
            [name]: name === 'reg_no' ? value.toUpperCase() : value
        };

        if (name === 'total_amount') {

            balanceAmount = Number(value) - parseInt(form?.advance_paid);
        }
        if (name === 'advance_paid') {
            if (Number(value) > parseInt(form?.total_amount)) {
                // alert("Advance paid must be less than total amount.");
                return; // Prevent state update
            }
            balanceAmount = parseInt(form?.total_amount) - Number(value);

        }
        let updatedForm = {
            ...form,
            ...updatedField,
            'userid': userid,
            'balance_amount': balanceAmount
            // Update the specific field
            // "bilty_weight": currBiltyWeight,  // Update bilty_weight with currBiltyWeight
        };

        var i = 1;
        selectedIds.forEach((value, index) => {
            updatedForm[`id_${i}`] = value; // You can customize the key here as needed
            i++
        });


        setForm(updatedForm);

    };



    const handlesubmittruck = async () => {
        // console.log("truckweight", truckweight.capacity)
        // console.log("bilty_weight", truckweight.bilty_weight)
        // console.log("currBiltyWeight", currBiltyWeight)
        var avail_weight = truckweight.capacity - truckweight.bilty_weight
        await axios.post('https://freight.pigc.ca/backend/public/addbiltytruck', form)
            .then((response) => {
                if (response.data === 'error') {
                    // setParties([])
                }
                else
                    alert('success');
                getBuilty();
                setCurrBiltyWeight(0);

            })
            .catch((error) => {
                console.log(error);
            })
        // if (avail_weight >= currBiltyWeight) {
        // alert("truck success")
        // await axios.post('https://freight.pigc.ca/backend/public/addbiltytruck', form)
        //     .then((response) => {
        //         if (response.data === 'error') {
        //             // setParties([])
        //         }
        //         else
        //             alert('success');
        //         getBuilty();
        //         setCurrBiltyWeight(0);

        //     })
        //     .catch((error) => {
        //         console.log(error);
        //     })
        // } else {
        //     alert("truck capacity exceeded")
        // }
    }
    const getBuilty = async () => {
        await axios.get(`https://freight.pigc.ca/backend/public/getbuilty/`).then((response) => {
            console.log("bilty data on time", response.data)
            if (response.data === 'error') {
                setBuilty([])
            }
            else
                setBuilty(response.data)
            let count = 0;
            for (var i = 0; i < response.data.length; i++) {
                if (response.data[i].status === '1') {
                    count++
                }
            }
            setBiltyCount(count);
        })
            .catch((error) => {
                console.log(error);
            })
    }
    const getparties = async () => {
        await axios.get('https://freight.pigc.ca/backend/public/getparties')
            .then((response) => {
                if (response.data === 'error') {
                    setParties([])
                }
                else
                    setParties(response.data)
            })
            .catch((error) => {
                console.log(error);
            })
    }
    const getuser = () => {
        axios.get(`https://freight.pigc.ca/backend/public/getuserone/${parseInt(userid)}`)

            .then((response) => {
                delete response.data[0].password

                // console.log("userdata", response.data);
                setUser(response.data[0])
            })
            .catch((error) => {
                console.log(error)
            })
    }
    const gettruck = async () => {
        await axios.get('https://freight.pigc.ca/backend/public/gettruck2')
            .then((response) => {
                if (response.data === 'error') {
                    setTruck([])
                }
                else
                    setTruck(response.data)
            })
            .catch((error) => {
                console.log(error);
            })
    }
    // const gettruckone = async (id) => {
    //     console.log(id, "truck_reg_no")
    //     await axios.get(`https://freight.pigc.ca/backend/public/gettruckone/${id}`)
    //         .then((response) => {
    //             console.log(response.data, "truckweight")
    //             if (response.data === 'error') {
    //                 setTruckweight([])
    //             }
    //             else
    //                 setTruckweight({
    //                     'capacity': response.data[0].capacity,
    //                     'bilty_weight': response.data[0].bilty_weight
    //                 })
    //         })
    //         .catch((error) => {
    //             console.log(error);
    //         })
    // }
    const getdriver = async () => {
        await axios.get('https://freight.pigc.ca/backend/public/getdriver')
            .then((response) => {
                if (response.data === 'error') {
                    setDriver([])
                }
                else
                    setDriver(response.data)
            })
            .catch((error) => {
                console.log(error);
            })
    }
    const getsupplier = async () => {
        await axios.get('https://freight.pigc.ca/backend/public/getsupplier')
            .then((response) => {
                if (response.data === 'error') {
                    setSupplier([])
                }
                else
                    setSupplier(response.data)
            })
            .catch((error) => {
                console.log(error);
            })
    }
    const handleEdit = (id) => {
        setBityId(id)
    }
    useEffect(() => {
        getBuilty()
        getparties()
        getdriver()
        gettruck()
        getsupplier()
    }, [])
    useEffect(() => {
        getuser()
    }, [userid])
    useEffect(() => {

        // gettruckone(form.reg_no)
    }, [form])

    useEffect(() => {
        setIsHidden(selectedIds.size === 0);
        // console.log(isHidden, 'isHidded');
        // console.log(selectedIds, 'selectedIds');
    }, [selectedIds]);
    const listToFilter = usertype === '1' && filteredBuilty.length > 0 ? filteredBuilty : builty;
    const filteredUsers = listToFilter.filter(user => {

        const lowerCaseSearch = search.toLowerCase();

        // Check if user properties are defined and convert to lower case before checking inclusion
        const startDate = user.start_date ? user.start_date.toLowerCase() : '';
        const lrNo = user.lr_no ? user.lr_no.toLowerCase() : '';
        const regNo = user.reg_no ? user.reg_no.toLowerCase() : '';
        const name = user.name ? user.name.toLowerCase() : '';
        const startLoc = user.start_loc ? user.start_loc.toLowerCase() : '';
        const destination = user.destination ? user.destination.toLowerCase() : '';


        return (
            startDate.includes(lowerCaseSearch) ||
            lrNo.includes(lowerCaseSearch) ||
            regNo.includes(lowerCaseSearch) ||
            name.includes(lowerCaseSearch) ||
            destination.includes(lowerCaseSearch) ||
            startLoc.includes(lowerCaseSearch)
        );
    });

    const [filter, setFilter] = useState('All Months');

    // Function to handle filter selection
    const handleFilterChange = (event) => {
        setFilter(event.target.textContent);
    };


    // Function to apply filter to users
    const getFilteredUsers = () => {
        const now = new Date();
        const newFilteredUser = filteredUsers.filter(user => {
            const userDate = new Date(user.start_date);

            switch (filter) {
                case 'Today':
                    return userDate.toDateString() === now.toDateString();
                case 'This Week':
                    const startOfWeek = now.setDate(now.getDate() - now.getDay());
                    return userDate >= new Date(startOfWeek);

                case 'This Month':
                    return userDate.getMonth() === now.getMonth() && userDate.getFullYear() === now.getFullYear();
                case 'This Year':
                    return userDate.getFullYear() === now.getFullYear();
                case 'All Months':
                default:
                    return true;
            }
        });

        return newFilteredUser;
    };
    const [respo, setRespo] = useState(false)
    const handlesubmittrucknew = (e) => {
        e.preventDefault();
        setIsLoading(true)

        axios.post('https://freight.pigc.ca/backend/public/addbiltytrucknew', form)
            .then((response) => {
                if (response.data === 'error') {
                    // setParties([]) 
                    setIsLoading(false)
                    setTimeout(() => {
                        setError(true)
                    }, 3000);
                    return
                }
                else {
                    setRespo(true)
                    getBuilty();
                    setCurrBiltyWeight(0);
                    setTimeout(() => {
                        setForm(null)
                        setRespo(false)
                        handleClose3()
                    }, 3000);
                }
            })
            .catch((error) => {
                console.log(error);
                setIsLoading(false)
                setTimeout(() => {
                    setError(true)
                }, 3000);
            }).finally(() => {
                setIsLoading(false)
            })

        // try {
        //     const response = await axios.post('https://freight.pigc.ca/backend/public/addbiltytrucknew', form);

        //     if (response.data === 'error') {
        //         toast.update(newid, {
        //             render: "Something went wrong!",
        //             type: "error",
        //             isLoading: false,
        //             icon: false,
        //             autoClose: 2000,
        //             closeButton: true,
        //             closeOnClick: true,
        //         });
        //     } else {
        //         toast.update(newid, {
        //             render: "Truck Assigned Successfully!",
        //             type: "success",
        //             isLoading: false,
        //             icon: false,
        //             autoClose: 2000,
        //             closeButton: true,
        //             closeOnClick: true,
        //         });
        //         getBuilty();
        //         setCurrBiltyWeight(0);
        //     }
        // } catch (error) {
        //     console.error(error);
        //     toast.update(newid, {
        //         render: "An error occurred. Please try again!",
        //         type: "error",
        //         isLoading: false,
        //         icon: false,
        //         autoClose: 2000,
        //         closeButton: true,
        //         closeOnClick: true,
        //     });
        // }
    };
    // const handlesubmittrucknew = async (e) => {
    //     e.preventDefault()
    //     // console.log("truckweight", truckweight.capacity)
    //     // console.log("bilty_weight", truckweight.bilty_weight)
    //     // console.log("currBiltyWeight", currBiltyWeight)
    //     const newid = toast.loading("Please wait...")

    //     // alert("truck success")
    //     await axios.post('https://freight.pigc.ca/backend/public/addbiltytrucknew', form)
    //         .then((response) => {
    //             if (response.data === 'error') {
    //                 // setParties([]) 
    //                 toast.update(newid, {
    //                     render: "Something went wrong !",
    //                     type: "error",
    //                     isLoading: false,
    //                     icon: false,
    //                     autoClose: 2000,
    //                     closeButton: true,
    //                     closeOnClick: true,
    //                 });
    //                 return
    //             }
    //             else {
    //                 toast.update(newid, {
    //                     render: "Truck Assign Successfully !",
    //                     type: "success",
    //                     isLoading: false,
    //                     icon: false,
    //                     autoClose: 2000,
    //                     closeButton: true,
    //                     closeOnClick: true,
    //                 });
    //                 getBuilty();
    //                 setCurrBiltyWeight(0);
    //             }
    //         })
    //         .catch((error) => {
    //             console.log(error);
    //         })

    // }

    useEffect(() => {
        if (usertype === '1') {
            // Find the relevant 'builty' entry based on the user's address
            const userBuilty = builty.filter(item =>
                userData.address === item.consignee_address ||
                userData.address === item.consignor_address ||
                userData.address === item.consignor_city ||
                userData.address === item.consignee_city
            );
            // console.log('User Data:', userData);
            // console.log('Found Builty:', userBuilty);

            // Update state with the filtered 'builty' item
            setFilteredBuilty(userBuilty);
        }

    }, [userData, builty, usertype]);

    const filteredUsersData = getFilteredUsers();

    // advance_paid
    // : 
    // "g"
    // balance_amount
    // : 
    // "g"
    // id_1
    // : 
    // "8"
    // name
    // : 
    // "g"
    // owership
    // : 
    // "Market Truck"
    // payment_mode
    // : 
    // "cash"
    // phone
    // : 
    // "g"
    // reg_no
    // : 
    // "G"
    // supplier
    // : 
    // "1"
    // total_amount
    // : 
    // "g"
    // truck_type
    // : 
    // "Heavy"
    // userid
    // : 
    // "1"
    return (
        <div>
            {console.log(form, 'loading')}
            {isLoading ? <div className='loading-container w-100 position-fixed top-0 start-0'>
                <div className="d-flex justify-content-center align-items-center  " style={{ height: '100vh' }}>
                    <div className="spinner-border " role="status">
                        {/* <span className="sr-only">Loading...</span> */}
                    </div>
                </div>
            </div> : ''}
            {respo ? <div className='loading-container success-container w-100 position-fixed top-0 start-0'>
                <div className="d-flex justify-content-center align-items-center text-center " style={{ height: '100vh' }}>
                    <div>

                        <svg xmlns="http://www.w3.org/2000/svg" width={70} height={70} fill="currentColor" className="bi bi-check-circle-fill text-success" viewBox="0 0 16 16">
                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                        </svg>
                        <div className='text-success'>Data Submitted </div>
                    </div>


                </div>
            </div> : ''}
            {error ? <div className='loading-container success-container w-100 position-fixed top-0 start-0'>
                <div className="d-flex justify-content-center align-items-center text-center " style={{ height: '100vh' }}>
                    <div>

                        <svg xmlns="http://www.w3.org/2000/svg" width={70} height={70} fill="currentColor" className="bi bi-exclamation-triangle text-danger text-bold" viewBox="0 0 16 16">
                            <path d="M7.938 2.016A.13.13 0 0 1 8.002 2a.13.13 0 0 1 .063.016.15.15 0 0 1 .054.057l6.857 11.667c.036.06.035.124.002.183a.2.2 0 0 1-.054.06.1.1 0 0 1-.066.017H1.146a.1.1 0 0 1-.066-.017.2.2 0 0 1-.054-.06.18.18 0 0 1 .002-.183L7.884 2.073a.15.15 0 0 1 .054-.057m1.044-.45a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767z" />
                            <path d="M7.002 12a1 1 0 1 1 2 0 1 1 0 0 1-2 0M7.1 5.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0z" />
                        </svg>

                        <div className='text-danger text-bold'>Something Went wrong</div>
                    </div>


                </div>
            </div> : ''}
            {/* {console.log(filteredBuilty, 'file')} */}
            {/* {usertype==='1'} */}
            {/* {JSON.stringify(form)} */}
            {userData?.is_verified === '1' || usertype === '0' ? <Container fluid className='px-0 vh-100 d-flex'>
                <Sidebar />
                <SidebarMob />
                <div className='m-0 p-0 w-100 padding-20 '>

                    <Container fluid className='d-flex justify-content-between px-5 mt-4 align-items-center'>
                        <h1 className='text-blue fw-6'>Bilty</h1>

                        <div className="d-flex justify-content-end align-items-center">
                            <button className={`btn bg-blue fs-5 fw-6 text-white me-3 h-50 rounded-4  ${isHidden ? 'd-none' : ''}`} onClick={() => setShow3(true)}><IoIosAddCircleOutline className='me-2 fs-4 mb-1' />Add Trucks</button>
                            <button to='/useradd' className='btn bg-blue fs-5 fw-6 text-white me-3 h-50 rounded-4' data-bs-toggle="offcanvas" href="#offcanvasExample2" aria-controls="offcanvasExample2"><IoIosAddCircleOutline className='me-2 fs-4 mb-1' />Add Bilty</button>
                            <Usermenu />
                        </div>
                    </Container>
                    <Card className=' mx-5 mt-4 pb-4 shadow shadow-md ' >
                        <div className='d-flex justify-content-between align-items-center mt-4 pe-3 border-bottom '>
                            <div className="d-flex ">

                                <div className='dropdown border border-dark rounded-4 ms-4 me-2'>
                                    <Button className='bg-white fs-5 fw-6 text-dark h-50 dropdown-toggle p-2' type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                        {filter}
                                    </Button>
                                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">

                                        <li><a className="dropdown-item" href="#" onClick={handleFilterChange}>All Months</a></li>
                                        <li><a className="dropdown-item" href="#" onClick={handleFilterChange}>Today</a></li>
                                        <li><a className="dropdown-item" href="#" onClick={handleFilterChange}>This Week</a></li>
                                        {/* <li><a className="dropdown-item" href="#" onClick={handleFilterChange}>Last Week</a></li> */}
                                        <li><a className="dropdown-item" href="#" onClick={handleFilterChange}>This Month</a></li>
                                        <li><a className="dropdown-item" href="#" onClick={handleFilterChange}>This Year</a></li>
                                    </ul>
                                </div>
                                <div className='dropdown border border-dark rounded-4 ms-2 me-2'>
                                    <div className='fs-5 fw-6 text-dark h-50 p-2'>Bilty Weight: {currBiltyWeight} Tonnes</div>
                                </div>
                                <div className='dropdown border border-dark rounded-4 ms-2 me-2'>
                                    <div className='fs-5 fw-6 text-dark h-50 p-2'>Bilty In Transit: {builtycount} </div>
                                </div>
                            </div>
                            <div className='text-end'>
                                <InputGroup className="mb-3 mt-3">
                                    <Form.Control
                                        placeholder="Search.."
                                        aria-label="search"
                                        aria-describedby="basic-addon2"
                                        onChange={e => setSearch(e.target.value)}
                                    />
                                    <InputGroup.Text id="basic-addon2"><BsSearch /></InputGroup.Text>
                                </InputGroup>
                            </div>
                        </div>
                        <div className="table-responsive px-2 pt-4 overflow-auto" style={{ height: '100vh' }} >

                            <table className='table table-striped  table-hover ' style={{ overflowY: 'scroll' }}>
                                <thead>
                                    <tr>
                                        <th><h4>Select</h4></th>
                                        <th><h4>#</h4></th>
                                        <th><h4>Start Date</h4></th>
                                        <th><h4>Actual Weight</h4></th>
                                        <th><h4>LR Number</h4></th>
                                        <th><h4>Party Name</h4></th>
                                        {/* <th><h4>Truck No</h4></th> */}
                                        <th><h4>Route</h4></th>
                                        <th><h4>Trip Status</h4></th>
                                        <th><h4>Party Trip Balance</h4></th>
                                        <th><h4>Action</h4></th>
                                    </tr>
                                </thead>
                                <tbody >

                                    {filteredUsersData.slice().reverse().map((item, index) =>
                                    (
                                        <tr className='pt-4' key={index}>
                                            <td className='text-center'>

                                                <input className={`form-check-input mt-2 ${item.status === '1' || item.status === '2' ? 'd-none' : ''}`} type="checkbox"
                                                    checked={selectedIds.has(item.id)}
                                                    onChange={() => handleCheckboxChange(item.id, item.actual_weight)}
                                                    id={`checkbox-${item.id}`} />
                                            </td>
                                            <td ><h5 className='mt-2'>{index + 1}</h5></td>
                                            <td><h5 className='mt-2'>{item.start_date}</h5></td>
                                            <td className='text-center'><h5 className='mt-2'>{item.actual_weight ? item.actual_weight : ''} Tones</h5></td>
                                            <td><h5 className='mt-2'>{item.lr_no}</h5></td>
                                            {/* <td><h5 className='mt-2'> {item.name} </h5></td> */}
                                            <td><h5 className='mt-2'> {parties.find(partyItem => partyItem.id === item.party)?.name || ""}</h5></td>
                                            <td><h5 className='mt-2'>{item.start_loc} to {item.destination}</h5></td>
                                            <td><h5 className='btn text-blue '>{item.status === '0' ? "Started" : item.status === '1' ? "In Transit" : item.status === '2' ? "Completed" : ""}</h5></td>
                                            <td><h5 className='btn text-blue '>Rs {item.party_trip_balance}</h5></td>
                                            <td><div className="d-flex">
                                                <Button className='mt-2 bg-blue text-white btn-md me-2' onClick={() => handletripone(item.id)}><FaRegEye /></Button>
                                                {usertype === '1' && user.trips === '0' ? "" : <Button className='mt-2 bg-danger text-white btn-md ' data-bs-toggle="offcanvas" href="#offcanvasExample3" role="button" aria-controls="offcanvasExample2" onClick={() => handleEdit(item.id)}><RiEdit2Fill /> </Button>}

                                            </div></td>
                                        </tr>
                                    ))}

                                </tbody>
                            </table>
                        </div>
                    </Card>
                </div>
            </Container> : nevigate('/notverify')}


            <Addtrip />
            <Addtrip1 id={BityId} />

            <Modal show={show3} onHide={handleClose3} centered size="lg" >
                <Modal.Header className='bg-blue rounded-top-4'>
                    <Modal.Title className='fs-4 text-white'>Add Truck</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={handlesubmittrucknew} >
                        <Row className='px-3 g-2 gx-3'>
                            <Col md={6}>
                                <h6 className='text-blue fw-6'>Truck Registration No.</h6>
                                <input type="text" className={`form-control rounded-4 mb-1 `} name="reg_no" placeholder='Enter Truck Number' onChange={handletruck} value={form?.reg_no || ''} required />
                            </Col>
                            <Col md={6}>
                                <h6 className='text-blue fw-6'>Driver Name</h6>
                                <input type="text" className={`form-control rounded-4 mb-1 `} name="name" placeholder='Enter Driver Name' onChange={handletruck} value={form?.name || ''} required />
                            </Col>
                            <Col md={6}>
                                <h6 className='text-blue fw-6'>Driver Number</h6>
                                <input type="text" className={`form-control rounded-4 mb-1 `} name="phone" placeholder='Enter Driver Number' onChange={handletruck} value={form?.phone || ''} required />
                            </Col>
                            <Col md={6}>
                                <h6 className='text-blue fw-6'>Total Trip Cost</h6>
                                <input type="text" className={`form-control rounded-4 mb-1 `} name="total_amount" placeholder='Enter Trip Cost' onChange={handletruck} value={form?.total_amount || ''} required />
                            </Col>
                            <Col md={6}>
                                <h6 className='text-blue fw-6'>Advance Paid</h6>
                                <input type="text" className={`form-control rounded-4 mb-1 `} name="advance_paid" placeholder='Enter Trip Cost' onChange={handletruck} value={form?.advance_paid || ''} required />
                            </Col>
                            <Col md={6}>
                                <h6 className='text-blue fw-6'>Balance</h6>
                                <input type="text" className={`form-control rounded-4 mb-1 `} name="balance_amount" placeholder='Enter Trip Cost' readOnly value={form?.balance_amount || ''} />
                            </Col>
                            <Col md={6}>
                                <h6 className='text-blue fw-6'>Truck Type</h6>
                                <Form.Select className={`rounded-4 mt-3`} required onChange={handletruck} name="truck_type"  >
                                    <option value='' >Select Truck Type</option>
                                    <option value='Mini Truck/LCV'>Mini Truck/LCV</option>
                                    <option value='Open Body Truck'>Open Body Truck</option>
                                    <option value='Heavy'>Heavy</option>
                                    <option value='Bus'>Bus</option>
                                    <option value='Tank'>Tank</option>
                                    <option value='Closed Container'>Closed Container</option>
                                </Form.Select>
                            </Col>
                            <Col md={6}>
                                <h6 className='text-blue fw-6'>Truck Owership</h6>
                                <Form.Select className={`rounded-4 mt-3`} required onChange={handletruck} name="owership"  >
                                    <option value=''>Select Truck Owership</option>
                                    <option value='My Truck'>My Truck</option>
                                    <option value='Market Truck'>Market Truck</option>

                                </Form.Select>
                            </Col>
                            <Col md={6}>
                                <h6 className='text-blue fw-6'>Payment Mode</h6>
                                <Form.Select className={`rounded-4 mt-3`} onChange={handletruck} name="payment_mode"  >
                                    <option defaultValue='' disabled>Payment Mode</option>
                                    <option value='cash'>Cash</option>
                                    <option value='online'>Online</option>
                                </Form.Select>
                            </Col>
                            <Col md={6} className={`${form?.owership === 'My Truck' ? 'd-none' : ''}`}>
                                <h6 className='text-blue fw-6'>Select Supplier</h6>
                                <Form.Select className={`rounded-4 mt-3`} required onChange={handletruck} name="supplier"  >
                                    <option value='' disabled>Select Supplier</option>
                                    {supplier?.map((item, index) => (
                                        <option key={index} value={item.id}>{item.name}</option>
                                    ))}
                                </Form.Select>
                            </Col>
                            <Col md={12}> </Col>
                            <Col md={12}>
                                <div className="container-fluid d-flex justify-content-end my-2 px-0" >
                                    <Button className='btn bg-danger fs-5 fw-6 text-white me-3 rounded-4 px-4' onClick={handleClose3}>Close</Button>
                                    <button className='btn bg-blue fs-5 fw-6 text-white rounded-4 px-4' type='submit' >Save</button>
                                </div>
                            </Col>
                        </Row>
                    </form>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default Trips;