import React, { useState } from 'react'
import { Button, Card, Col, Container, Modal, Row } from 'react-bootstrap'
import Form from 'react-bootstrap/Form';
import camera_pic from '../../assests/new2/camera.svg';
import { TbCameraPlus } from "react-icons/tb";


export default function Document_book() {
    const [truckRegNo, settruckRegNo] = useState('HR-03S-0421');
    const [selectParty, setselectParty] = useState('Joe Rogan');
    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);

    const [addDoc, setAddDoc] = useState(false);
    const handleShowAddDoc = () => setAddDoc(true);
    const handleCloseAddDoc = () => setAddDoc(false);

    const handleSelectChangeReg = (e) => {
        settruckRegNo(e.target.value);
    };
    return (
        <>
            <div className='h-100 offcanvas w-50 offcanvas-end document_book md-w-100' tabIndex="-1" id="offcanvasExample7" aria-labelledby="offcanvasExampleLabel7">
                <h3 className='text-white fw-6 mb-3 bg-blue py-3 ps-5 '>Document Book</h3>
                <h5 className='fw-5 text-gray ps-5 mb-1 text-blue fw-6'>({truckRegNo})</h5>
                <div className='px-5 overflowy'>
                    <Row className='mt-1 g-3 justify-content-between overflowScroll'>
                        <Col lg={6}>
                            <div className="d-flex align-items-center gap-4 py-3 px-3 rounded-4 shadow shadow-md document_book_card" onClick={handleShow}>
                                <div className=" document_book_imgs_div py-3 px-2 rounded-4">
                                    <img width={40} src={camera_pic} alt='image--' className='text-blue document_book_imgs' />
                                </div>
                                <div className="document_book_card_desc">
                                    <h4 className='mb-0'>Insurance</h4>
                                    <h6>Add  Expiry</h6>
                                    <div className="fs-4 px-2 rounded-3 d-none document_book_card_desc_expiry">Expiring on 27th Aug 2025</div>
                                </div>
                            </div>
                        </Col>
                        <Col lg={6}>
                            <div className="d-flex align-items-center gap-4 py-3 px-3 rounded-4 shadow shadow-md document_book_card" onClick={handleShow}>
                                <div className=" document_book_imgs_div py-3 px-2 rounded-4">
                                    <img width={40} src={camera_pic} alt='image--' className='text-blue document_book_imgs' />
                                </div>
                                <div className="document_book_card_desc">
                                    <h4 className='mb-0'>Rc Permit</h4>
                                    <h6>Add  Expiry</h6>
                                    <div className="fs-4 px-2 rounded-3 d-none document_book_card_desc_expiry">Expiring on 27th Aug 2025</div>
                                </div>
                            </div>
                        </Col>

                        <Col lg={6}>
                            <div className="d-flex align-items-center gap-4 py-3 px-3 rounded-4 shadow shadow-md document_book_card" onClick={handleShow}>
                                <div className=" document_book_imgs_div py-3 px-2 rounded-4">
                                    <img width={40} src={camera_pic} alt='image--' className='text-blue document_book_imgs' />
                                </div>
                                <div className="document_book_card_desc">
                                    <h4 className='mb-0'>Registration</h4>
                                    <h6>Add  Expiry</h6>
                                    <div className="fs-4 px-2 rounded-3 d-none document_book_card_desc_expiry">Expiring on 27th Aug 2025</div>
                                </div>
                            </div>
                        </Col>
                        <Col lg={6}>
                            <div className="d-flex align-items-center gap-4 py-3 px-3 rounded-4 shadow shadow-md document_book_card" onClick={handleShow}>
                                <div className=" document_book_imgs_div py-3 px-2 rounded-4">
                                    <img width={40} src={camera_pic} alt='image--' className='text-blue document_book_imgs' />
                                </div>
                                <div className="document_book_card_desc">
                                    <h4 className='mb-0'>National Permit</h4>
                                    <h6>Add  Expiry</h6>
                                    <div className="fs-4 px-2 rounded-3 d-none document_book_card_desc_expiry">Expiring on 27th Aug 2025</div>
                                </div>
                            </div>
                        </Col>

                        <Col lg={6}>
                            <div className="d-flex align-items-center gap-4 py-3 px-3 rounded-4 shadow shadow-md document_book_card" onClick={handleShow}>
                                <div className=" document_book_imgs_div py-3 px-2 rounded-4">
                                    <img width={40} src={camera_pic} alt='image--' className='text-blue document_book_imgs' />
                                </div>
                                <div className="document_book_card_desc">
                                    <h4 className='mb-0'>Road Tax</h4>
                                    <h6>Add  Expiry</h6>
                                    <div className="fs-4 px-2 rounded-3 d-none document_book_card_desc_expiry">Expiring on 27th Aug 2025</div>
                                </div>
                            </div>
                        </Col>
                        <Col lg={6}>
                            <div className="d-flex align-items-center gap-4 py-3 px-3 rounded-4 shadow shadow-md document_book_card" onClick={handleShow}>
                                <div className=" document_book_imgs_div py-3 px-2 rounded-4">
                                    <img width={40} src={camera_pic} alt='image--' className='text-blue document_book_imgs' />
                                </div>
                                <div className="document_book_card_desc">
                                    <h4 className='mb-0'>Fitness Cert.</h4>
                                    <h6>Add  Expiry</h6>
                                    <div className="fs-4 px-2 rounded-3 d-none document_book_card_desc_expiry">Expiring on 27th Aug 2025</div>
                                </div>
                            </div>
                        </Col>

                        <Col lg={6}>
                            <div className="d-flex align-items-center gap-4 py-3 px-3 rounded-4 shadow shadow-md document_book_card" onClick={handleShow}>
                                <div className=" document_book_imgs_div py-3 px-2 rounded-4">
                                    <img width={40} src={camera_pic} alt='image--' className='text-blue document_book_imgs' />
                                </div>
                                <div className="document_book_card_desc">
                                    <h4 className='mb-0'>Drivers Licn.</h4>
                                    <h6>Add  Expiry</h6>
                                    <div className="fs-4 px-2 rounded-3 d-none document_book_card_desc_expiry">Expiring on 27th Aug 2025</div>
                                </div>
                            </div>
                        </Col>
                        <Col lg={6}>
                            <div className="d-flex align-items-center gap-4 py-3 px-3 rounded-4 shadow shadow-md document_book_card" onClick={handleShow}>
                                <div className=" document_book_imgs_div py-3 px-2 rounded-4">
                                    <img width={40} src={camera_pic} alt='image--' className='text-blue document_book_imgs' />
                                </div>
                                <div className="document_book_card_desc">
                                    <h4 className='mb-0'>Drivers Licn.</h4>
                                    <h6>Add  Expiry</h6>
                                    <div className="fs-4 px-2 rounded-3 d-none document_book_card_desc_expiry">Expiring on 27th Aug 2025</div>
                                </div>
                            </div>
                        </Col>

                        <Col lg={6}>
                            <div className="d-flex align-items-center gap-4 py-3 px-3 rounded-4 shadow shadow-md document_book_card" onClick={handleShow}>
                                <div className=" document_book_imgs_div py-3 px-2 rounded-4">
                                    <img width={40} src={camera_pic} alt='image--' className='text-blue document_book_imgs' />
                                </div>
                                <div className="document_book_card_desc">
                                    <h4 className='mb-0'>Pollution Cert.</h4>
                                    <h6>Add  Expiry</h6>
                                    <div className="fs-4 px-2 rounded-3 d-none document_book_card_desc_expiry">Expiring on 27th Aug 2025</div>
                                </div>
                            </div>
                        </Col>
                        <Col lg={6}>
                            <div className="d-flex align-items-center gap-4 py-3 px-3 rounded-4 shadow shadow-md document_book_card" onClick={handleShow}>
                                <div className=" document_book_imgs_div py-3 px-2 rounded-4">
                                    <img width={40} src={camera_pic} alt='image--' className='text-blue document_book_imgs' />
                                </div>
                                <div className="document_book_card_desc">
                                    <h4 className='mb-0'>Other Doc.</h4>
                                    <h6>Add  Expiry</h6>
                                    <div className="fs-4 px-2 rounded-3 d-none document_book_card_desc_expiry">Expiring on 27th Aug 2025</div>
                                </div>
                            </div>
                        </Col>

                        <Col md={12}>
                            <div className='d-flex justify-content-end my-3 pe-0 gap-3'>
                                <button className="btn py-2 border_btn px-4 rounded-4 " type="button" data-bs-toggle="offcanvas" href="#offcanvasExample7" role="button" aria-controls="offcanvasExample7">
                                    Close
                                </button>
                                <button className="btn btn-dark px-4 py-2 rounded-4" type="button" onClick={handleShowAddDoc} >
                                    Add Other Doc
                                </button>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div >

            <Modal show={show} onHide={handleClose} centered>
                <Form onSubmit={console.log('submitted')}>
                    <Modal.Header className='bg-blue text-white' >
                        <h2 className='fs-1'>Add Insurance</h2>
                    </Modal.Header>

                    <div className='p-4'>
                        <div className="bg-blue text-light opacity-50 rounded-4 mb-4">
                            <Row className='justify-content-between px-3 py-1'>
                                <Col xs={5}>Truck Info <br />{truckRegNo}</Col>
                                <Col xs={2}>Trailer</Col>
                            </Row>
                        </div>

                        <label htmlFor="expiry-date" className="fw-5 text-gray ">Expiry Date</label>
                        <input type="date" className="w-100 p-2 border-1 rounded-4 mb-4" placeholder='Expiry Date' id="expiry-date" />

                        <label htmlFor="policy-number" className="fw-5 text-gray ">Policy No</label>
                        <input type='number' className="w-100 p-2 border-1 rounded-4 mb-4" placeholder='Enter Policy Number' id="policy-number" />

                        <Button className='bg-blue '><TbCameraPlus /> Add Image</Button>
                        <Button className='bg-white text-blue border_btn ms-1 px-2 '>Insurance Expense</Button>
                    </div>
                    <Modal.Footer className='border-0'>
                        <Button className='bg-white py-1 px-4 text-blue border_btn' onClick={handleClose}>
                            Close
                        </Button>
                        <Button className='bg-blue py-1 px-4' onClick={handleClose}>
                            Save
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>


            <Modal show={addDoc} onHide={handleCloseAddDoc} centered>
                <Modal.Header className='bg-blue text-white' >
                    <h2 className='fs-1'>Add Insurance</h2>
                </Modal.Header>
                <div className="p-4 pb-0">

                    <div className="bg-blue text-light opacity-50 rounded-4 mb-4 px-2 py-2">

                        <h6 className='mb-1'>Truck Info </h6>
                        <div className='d-flex justify-content-between'>
                            <h5>HP 03 D 0324</h5>
                            <h5>Trailer</h5>


                        </div>

                    </div>
                </div>
                <Form onSubmit={console.log('submitted')}>

                    <div className='p-4 pt-0'>

                        <label htmlFor="document-name" className="fw-5 text-gray ">Document Name</label>
                        <input type="text" className="w-100 p-2 border-1 rounded-4 mb-4" placeholder='Document Name' id="document-name" />

                        <label htmlFor="expiry-date" className="fw-5 text-gray ">Expiry Date</label>
                        <input type="date" className="w-100 p-2 border-1 rounded-4 mb-4" placeholder='Expiry Date' id="expiry-date" />

                        <label htmlFor="policy-number" className="fw-5 text-gray ">Policy No</label>
                        <input type='number' className="w-100 p-2 border-1 rounded-4 mb-4" placeholder='Enter Policy Number' id="policy-number" />

                        <Button className='bg-blue'><TbCameraPlus /> Add Image</Button>
                        <Button className='bg-white text-blue border_btn ms-1 px-2'>Insurance Expense</Button>
                    </div>
                    <Modal.Footer className='border-0'>
                        <Button className='bg-white py-1 px-4 text-blue border_btn' onClick={handleCloseAddDoc}>
                            Close
                        </Button>
                        <Button className='bg-blue py-1 px-4' onClick={handleClose}>
                            Save
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
        </>
    )
}
