import React, { useEffect, useState } from 'react'
import { Container, Card, Button, InputGroup, Form, Row, Col, Modal } from 'react-bootstrap'
import { NavLink, useParams } from 'react-router-dom';
import Sidebar from './sidebar';
import { useNavigate } from 'react-router-dom';
import user_img from '../assests/user_image.svg'
import { BsSearch } from "react-icons/bs";
import { RiDeleteBin6Line } from "react-icons/ri";
import { FaRegEye } from "react-icons/fa";
import { IoIosAddCircleOutline } from "react-icons/io";
import SidebarMob from './siderbar_mobile';
import Usermenu from './user_menu';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


function User() {
    const nevigate = useNavigate()
    const [search, setSearch] = useState('')
    const [user, setUser] = useState([])
    const [show, setShow] = useState(false)
    const [deleteid, setdeleteid] = useState(-1)

    const notify = () => toast("User Deleted Successfully !")
    const notify_error = () => toast("Something Went Wrong !!")


    const handleShow = (id) => {
        setShow(true)
        setdeleteid(id)
    }
    const handleClose = () => {
        setShow(false)
    }

    const handledelete = (id) => {
        console.log("deleted id", id)
        setShow(false)
        axios.post(`https://freight.pigc.ca/backend/public/deleteuser/${id}`)
            .then((response) => {
                console.log("deleted noti", response)
                if (response.data === "success") {
                    notify();
                    setTimeout(() => {
                        // window.location.reload();
                        getuser()
                    }, 3000)
                } else {
                    notify_error()
                }

            })
            .catch((error) => {
                console.log(error);
            })
    }
    const getuser = () => {
        axios.get('https://freight.pigc.ca/backend/public/getuser')
            .then((response) => {
                if (response.data === 'error') {
                    setUser([])
                }
                else
                    setUser(response.data)
            })
            .catch((error) => {
                console.log(error);
            })
    }

    const userview = (id) => {
        nevigate(`/useredit/${id}`)
    }
    useEffect(() => {
        getuser();
    }, [])
    const filteredUsers = user.filter(user =>
        user.name.toLowerCase().includes(search.toLowerCase()) ||
        user.email.toLowerCase().includes(search.toLowerCase()) ||
        user.uid.toLowerCase().includes(search.toLowerCase()) ||
        user.phone.includes(search)
    );
    const [activeCompleted, setActiveCompleted] = useState('Verified');
    return (
        <div>
            <Container fluid className='px-0 vh-100 d-flex'>
                <Sidebar />
                <SidebarMob />
                <ToastContainer
                    position="top-right"
                    autoClose={3000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    
                    theme="dark"

                />

                <div className='m-0 p-0 w-100 padding-20'>
                    <Container fluid className='d-flex justify-content-between px-5 mt-4 align-items-center'>
                        <h1 className='text-blue fw-6'>Users</h1>
                        <div className='d-flex justify-content-between align-items-center'>
                            <NavLink to='/useradd' className='btn bg-blue fs-5 fw-6 text-white'><IoIosAddCircleOutline className='me-2 fs-4 mb-1' />Add New User</NavLink>
                            <Usermenu />
                        </div>
                    </Container>
                    <Card className=' mx-5 mt-4 pb-4 shadow shadow-md ' >
                        <div className='d-flex justify-content-between align-items-center mt-4 pe-3 border-bottom '>
                            <div className="d-flex mb-4">
                                <button className={`btn fs-5 fw-6 me-1 h-50 rounded-4 p-2 px-3 ms-2 ${activeCompleted === 'Verified' ? 'bg-blue text-white' : ''}`} onClick={() => setActiveCompleted('Verified')}>
                                    Verified
                                </button>
                                <button className={`btn fs-5 fw-6 me-1 h-50 rounded-4 p-2 px-3 ms-2 ${activeCompleted === 'Request' ? 'bg-blue text-white' : ''}`} onClick={() => setActiveCompleted('Request')}>
                                    Request
                                </button>
                            </div>
                            <div className='ms-auto mt-4 me-4'>

                                <div className='text-end'>

                                    <InputGroup className="mb-3 mt-3">
                                        <Form.Control
                                            placeholder="Search.."
                                            aria-label="search"
                                            aria-describedby="basic-addon2"
                                            value={search}
                                            onChange={e => setSearch(e.target.value)}
                                        />
                                        <InputGroup.Text id="basic-addon2"><BsSearch /></InputGroup.Text>
                                    </InputGroup>
                                </div>
                            </div>
                        </div>
                     
                        {activeCompleted === 'Verified' ? <Row className='mx-3 mt-2 g-3 overflowScroll' style={{ maxHeight: '60vh' }}>

                            {user && user.length > 0 ? (

                                filteredUsers.slice().reverse().map((item, index) => item.is_verified==='1' &&  (
                                    <Col md={12} lg={6} key={index}>
                                        {/* {JSON.stringify(item.is_verified)} */}
                                        <Card className='shadow shadow-md usercard p-3 rounded rounded-4 flex-wrap'>
                                            <img src={item.image ? `https://smarto.s3.ap-south-1.amazonaws.com/freight/user/${item.image}` : user_img} className="card-img" height='100px' width='102px' alt={item.name} />
                                            <div className="card-body border-0 py-0">
                                                <h4 className='card-title'>{item.name}</h4>
                                                <div className="card text border-0 py-0">
                                                    <h6>UID: {item.uid}</h6>
                                                    <h6>{item.phone}</h6>
                                                    <h6>{item.email}</h6>
                                                </div>
                                            </div>
                                            <div className="card-footer bg-transparent border-0 px-0 d-flex flex-column gap-2 h-100">
                                                <button className='btn bg-danger text-white rounded-3 mb-3 h-100' onClick={() => handleShow(item.id)}>
                                                    <RiDeleteBin6Line className='fs-6 me-1 mb-1' />Delete
                                                </button>
                                                <button className='btn bg-blue text-white rounded-3 h-100' onClick={() => userview(item.id)}>
                                                    <FaRegEye className='fs-6 me-1 mb-1' />View
                                                </button>
                                            </div>
                                        </Card>
                                    </Col>
                                ))
                            ) : (
                                <div className="container-fluid text-center">
                                    <h3>No user found</h3>
                                </div>
                            )}
                        </Row> : <Row className='mx-3 mt-2 g-3 overflowScroll' style={{ maxHeight: '60vh' }}>

                            {user && user.length > 0 ? (

                                filteredUsers.slice().reverse().map((item, index) => item.is_verified==='0' &&  (
                                    <Col md={12} lg={6} key={index}>
                                        <Card className='shadow shadow-md usercard p-3 rounded rounded-4 flex-wrap'>
                                            <img src={item.image ? `https://smarto.s3.ap-south-1.amazonaws.com/freight/user/${item.image}` : user_img} className="card-img" height='100px' width='102px' alt={item.name} />
                                            <div className="card-body border-0 py-0">
                                                <h4 className='card-title'>{item.name}</h4>
                                                <div className="card text border-0 py-0">
                                                    <h6>UID: {item.uid}</h6>
                                                    <h6>{item.phone}</h6>
                                                    <h6>{item.email}</h6>
                                                </div>
                                            </div>
                                            <div className="card-footer bg-transparent border-0 px-0 d-flex flex-column gap-2 h-100">
                                                <button className='btn bg-danger text-white rounded-3 mb-3 h-100' onClick={() => handleShow(item.id)}>
                                                    <RiDeleteBin6Line className='fs-6 me-1 mb-1' />Delete
                                                </button>
                                                <button className='btn bg-blue text-white rounded-3 h-100' onClick={() => userview(item.id)}>
                                                    <FaRegEye className='fs-6 me-1 mb-1' />View
                                                </button>
                                            </div>
                                        </Card>
                                    </Col>
                                ))
                            ) : (
                                <div className="container-fluid text-center">
                                    <h3>No user found</h3>
                                </div>
                            )}
                        </Row>}

                    </Card>
                </div>
            </Container>
            <Modal show={show} onHide={handleClose} centered >
                <Modal.Header className='bg-danger rounded-top-4 ' style={{ height: '10px' }}>
                    <Modal.Title className='fs-4 text-white'></Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <div className="text-center">
                        <h2 className='text-danger'>Delete User</h2>
                        <h4>Are you sure you want to delete ?</h4>
                        <div className="container-fluid d-flex justify-content-center mt-4 mb-2 px-0" >
                            <Button className='btn bg-secondary fs-5 fw-6 text-dark me-5 rounded-4 px-4' onClick={handleClose} >Cancle</Button>
                            <Button className='btn bg-danger fs-5 fw-6 text-white rounded-4 px-4' onClick={() => handledelete(deleteid)} >Delete</Button>
                        </div>
                    </div>

                </Modal.Body>
            </Modal>
        </div>
    )
}

export default User;