import React, { useEffect, useState } from 'react'
import { Button, Container, Card, Row, Col, InputGroup, Form, ToastContainer, Modal } from 'react-bootstrap'
import { NavLink, useNavigate, useParams } from 'react-router-dom';

import Sidebar from '../sidebar'
import SidebarMob from '../siderbar_mobile'

import { IoIosAddCircleOutline } from "react-icons/io";
import Addtrip from '../addtrip';
import Trip_book from '../truck_component/trip_book';
import Fuel_book from '../truck_component/fuel_book';
import Add_fuel_expense from '../truck_component/add_fuel_expense';
import Emi_book from '../truck_component/emi_book';
import Document_book from '../truck_component/document_book';
import Maintenance_book from '../truck_component/maintenance_book';
import Add_expense_purchase from '../truck_component/add_expense_purchase';
import Driver_other_expenses from '../truck_component/driver_other_expenses';
import Add_driver_expense from '../truck_component/Add_driver_other_expense';
import Usermenu from '../user_menu';
import axios from 'axios';
import AddStaffsalary from './AddStaffsalary';

const Expenses = () => {
    const [activeCompleted, setActiveCompleted] = useState('StaffSalary');
    const navigate = useNavigate()
    const [staff, setStaff] = useState([])
    const [supplier, setSupplier] = useState([])
    const [expenses, setExpenses] = useState([])

    const getStaff = async () => {
        await axios.get(`https://freight.pigc.ca/backend/public/getstaff`)
            .then((response) => {
                if (response.data !== 'error')
                    setStaff(response.data)
                else
                    setStaff([])
                console.log(response.data, 'hjfk')
            })
            .catch((error) => {
                console.log("error", error)
            })
    }
    const getSupplier = async () => {
        await axios.get(`https://freight.pigc.ca/backend/public/getsupplier`)
            .then((response) => {
                if (response.data !== 'error')
                    setSupplier(response.data)
                else
                    setSupplier([])
                console.log(response.data, 'hjfk')
            })
            .catch((error) => {
                console.log("error", error)
            })
    }
    const getExpenses = async () => {
        await axios.get(`https://freight.pigc.ca/backend/public/getexpenses`)
            .then((response) => {
                if (response.data !== 'error')
                    setExpenses(response.data)
                else
                    setExpenses([])
                console.log(response.data, 'hjfk')
            })
            .catch((error) => {
                console.log("error", error)
            })
    }
    useEffect(() => {
        getStaff()
        getSupplier()
        getExpenses()
    }, [])


    return (
        <>
            <Container fluid className='px-0 vh-100 d-flex'>
                <Sidebar />
                <SidebarMob />
                <div className='m-0 p-0 w-100 padding-20'>
                    <Container fluid className='d-flex justify-content-between px-5  mt-4 align-items-center truck-driver-info'>
                        <h3 className='reg-no_headed text-blue fw-6 media-fs'>Expenses</h3>
                        <div className="d-flex justify-content-end align-items-center truck-driver-header">
                            <button className='btn bg-black fs-5 fw-6 text-white me-3 h-50 rounded-4 margin-btn-add-trip ' data-bs-toggle="offcanvas" href="#Addstaffsalary" aria-controls="Addstaffsalary"><IoIosAddCircleOutline className='me-2 fs-4 mb-1' />Add Staff salary</button>
                            <button className='btn bg-black fs-5 fw-6 text-white me-3 h-50 rounded-4 margin-btn-add-trip ' data-bs-toggle="offcanvas" href="#Addsuppliersalary" aria-controls="Addsuppliersalary"><IoIosAddCircleOutline className='me-2 fs-4 mb-1' />Add Supplier salary</button>
                            <button className='btn bg-black fs-5 fw-6 text-white me-3 h-50 rounded-4 margin-btn-add-trip ' data-bs-toggle="offcanvas" href="#OtherExpenses" aria-controls="OtherExpenses"><IoIosAddCircleOutline className='me-2 fs-4 mb-1' />Other Expenses</button>

                            <AddStaffsalary />
                        </div>
                    </Container>
                    <Card className=' mx-5 mt-4 pb-4 shadow shadow-md ' >
                        <div className='d-flex justify-content-between align-items-center mt-4 pe-3 border-bottom '>
                            <div className="d-flex mb-4">
                                <button className={`btn fs-5 fw-6 me-1 h-50 rounded-4 p-2 px-3 ms-2 ${activeCompleted === 'StaffSalary' ? 'bg-blue text-white' : ''}`} onClick={() => setActiveCompleted('StaffSalary')}>
                                    Staff Salary
                                </button>
                                <button className={`btn fs-5 fw-6 me-1 h-50 rounded-4 p-2 px-3 ms-2 ${activeCompleted === 'SupplierSalary' ? 'bg-blue text-white' : ''}`} onClick={() => setActiveCompleted('SupplierSalary')}>
                                    Supplier Salary
                                </button>
                                <button className={`btn fs-5 fw-6 me-1 h-50 rounded-4 p-2 px-3 ms-2 ${activeCompleted === 'OtherExpenses' ? 'bg-blue text-white' : ''}`} onClick={() => setActiveCompleted('OtherExpenses')}>
                                    Other Expenses
                                </button>
                            </div>

                        </div>
                        {activeCompleted === 'StaffSalary' ?
                            <div className="table-responsive px-2 pt-4">
                                <table className='table table-striped table-hover'>
                                    <thead className='text-center align-middle'>
                                        <tr>
                                            <th rowSpan={2}><h4>SR. No.</h4></th>
                                            <th rowSpan={2}><h4>Name</h4></th>
                                            <th rowSpan={2}><h4>Total Amount</h4></th>
                                            <th rowSpan={2}><h4>Amount Paid</h4></th>
                                            <th rowSpan={2}><h4>Balance</h4></th>
                                            <th rowSpan={2}><h4>Status</h4></th>
                                            <th rowSpan={2}><h4>Date of Payment</h4></th>
                                            <th rowSpan={2}><h4>Discription</h4></th>
                                            <th rowSpan={2}><h4>Payment History</h4></th>

                                        </tr>

                                    </thead>
                                    <tbody className='text-center'>
                                        {staff.length > 0 && staff?.map((item, index) =>
                                        (
                                            <tr className='pt-4' key={index}>
                                                <td><h4>{index + 1}</h4></td>
                                                <td><h4>{item?.staff_name}</h4></td>
                                                <td><h4>{item?.total_amount}</h4></td>
                                                <td><h4>{item?.amount_paid}</h4></td>
                                                <td><h4>{item?.total_amount - item?.amount_paid}</h4></td>
                                                <td><h4>{item?.status}</h4></td>
                                                <td><h4> {new Date(item?.date_of_payment).toLocaleDateString('en-US', {
                                                    month: 'short',
                                                    day: '2-digit',
                                                    year: 'numeric'
                                                })}</h4></td>
                                                <td><h4>{item?.discription} </h4></td>
                                                {/* <td><h4>{item?.}</h4></td> */}

                                                <td><Button className='mt-2 bg-blue text-white btn-sm rounded-4' onClick={() => navigate(`/expenses/${item?.staffId}`)}>View Details</Button></td>
                                            </tr>

                                        ))}

                                    </tbody>
                                </table>
                            </div>
                            : activeCompleted === 'SupplierSalary' ? <div className="table-responsive px-2 pt-4">
                                <table className='table table-striped table-hover'>
                                    <thead className='text-center align-middle'>
                                        <tr>
                                            <th rowSpan={2}><h4>SR. No.</h4></th>
                                            <th rowSpan={2}><h4>Name</h4></th>
                                            <th rowSpan={2}><h4>Contact</h4></th>
                                            <th rowSpan={2}><h4>Total Amount</h4></th>
                                            <th rowSpan={2}><h4>Amount Paid</h4></th>
                                            <th rowSpan={2}><h4>Balance</h4></th>
                                            <th rowSpan={2}><h4>Action</h4></th>

                                        </tr>

                                    </thead>
                                    <tbody className='text-center'>
                                        {/* {JSON.stringify(supplier[0])} */}
                                        {supplier.length > 0 && supplier?.map((item, index) =>
                                        (
                                            <tr className='pt-4' key={index}>
                                                <td><h4>{index + 1}</h4></td>
                                                <td><h4>{item?.name}</h4></td>
                                                <td><h4>{item?.phone}</h4></td>
                                                <td><h4>{item?.total_amount}</h4></td>
                                                <td><h4>{item?.amount_paid}</h4></td>
                                                <td><h4>{item?.balance_amount}</h4></td>


                                                {/* <td><h4>{item?.}</h4></td> */}

                                                <td><Button className='mt-2 bg-blue text-white btn-sm rounded-4' onClick={() => navigate(`/expenses/supplier/${item?.id}`)}>View Details</Button></td>
                                            </tr>

                                        ))}


                                    </tbody>
                                </table>
                            </div> : <div className="table-responsive px-2 pt-4">
                                <table className='table table-striped table-hover'>
                                    <thead className='text-center align-middle'>
                                        <tr>
                                            <th rowSpan={2}><h4>SR. No.</h4></th>
                                            <th rowSpan={2}><h4>Name</h4></th>
                                            <th rowSpan={2}><h4>Total Expenses</h4></th>
                                            <th rowSpan={2}><h4>Amount Paid</h4></th>
                                            <th rowSpan={2}><h4>Amount Balance</h4></th>

                                            <th rowSpan={2}><h4>Date of Payment</h4></th>
                                            <th rowSpan={2}><h4>Discription</h4></th>
                                            <th rowSpan={2}><h4>Action</h4></th>

                                        </tr>

                                    </thead>
                                    <tbody className='text-center'>
                                        {expenses.length > 0 && expenses?.map((item, index) =>
                                        (
                                            <tr className='pt-4' key={index}>
                                                <td><h4>{index + 1}</h4></td>
                                                <td><h4>{item?.staff_name}</h4></td>
                                                <td><h4>{item?.total_amount}</h4></td>
                                                <td><h4>{item?.amount_paid}</h4></td>
                                                <td><h4>{item?.balance_amount}</h4></td>

                                                <td><h4> {new Date(item?.date_of_payment).toLocaleDateString('en-US', {
                                                    month: 'short',
                                                    day: '2-digit',
                                                    year: 'numeric'
                                                })}</h4></td>
                                                <td><h4>{item?.description}</h4></td>

                                                {/* <td><h4>{item?.}</h4></td> */}

                                                <td><Button className='mt-2 bg-blue text-white btn-sm rounded-4' onClick={() => navigate(`/expenses/other/${item?.staffId}`)}>View Details</Button></td>
                                            </tr>

                                        ))}

                                        <tr className='pt-4' >
                                            <td><h4>1</h4></td>
                                            <td><h4>Ramsvaroop</h4></td>
                                            <td><h4>10000</h4></td>
                                            <td><h4>Paid</h4></td>
                                            <td><h4>12 Aug 2024</h4></td>
                                            <td><h4>dis</h4></td>

                                        </tr>

                                    </tbody>
                                </table>
                            </div>}

                    </Card>
                </div>
            </Container>

        </>
    )
}

export default Expenses