import React, { useState } from 'react'
import { Card, Col, Container, Row } from 'react-bootstrap'
import Form from 'react-bootstrap/Form';
import camera_pic from '../../assests/new2/camera.svg'



export default function Add_fuel_expense() {
    const [truckRegNo, settruckRegNo] = useState('HR-03S-0421');
    const [selectParty, setselectParty] = useState('Joe Rogan');

    const handleSelectChangeReg = (e) => {
        settruckRegNo(e.target.value);
    };
    return (
        <>
            <div className='h-100 offcanvas w-50 offcanvas-end md-w-100' tabIndex="-1" id="offcanvasExample5" aria-labelledby="offcanvasExampleLabel5">
                <h3 className='text-white fw-6 mb-3 bg-blue py-3 ps-5 '>Add Fuel Expense</h3>
                <div className='px-5 overflowScroll'>
                    <Row >
                        <Col md={6}>
                            <h6 className='text-gray mt-1 fw-6'>Expense Amount</h6>
                            <input type="text" className='form-control rounded-4  mb-1' placeholder='Enter Amount' />
                        </Col>
                        <Col md={6}></Col>
                        <Col md={6}>
                            <h6 className='text-gray mt-2 fw-6'>Fuel Quantity </h6>
                            <Form.Select className='rounded-4' value={selectParty} onChange={handleSelectChangeReg}>
                                <option>Fuel Quantity</option>
                                <option value="party1">HR-03S-04</option>
                                <option value="party2">HR-03S-0422</option>
                                <option value="party3">HR-03S-0423</option>
                                <option value="party4">HR-03S-0424</option>
                                <option value="party5">HR-03S-0425</option>
                            </Form.Select>
                        </Col>
                        <Col md={6}>
                            <h6 className='text-gray mt-2 fw-6'>Rate Per Liter</h6>
                            <input type="text" className='form-control rounded-4  mb-1' placeholder='Rate Per Liter' />
                        </Col>
                        <Col md={12}>
                            <div className='d-flex my-1'>
                                <h4 className='fw-5 mt-1'>I have filled full tank</h4>
                                <Form.Check type="switch" id="custom-switch" label="" className=' ms-2 mt-1 ' />
                            </div>
                        </Col>
                        <Col md={6}>
                            <h6 className='text-gray mt-0 fw-6'>Current KM Reading</h6>
                            <input type="text" className='form-control rounded-4  mb-2' placeholder='Current KM Reading' />
                        </Col>
                        <Col md={6}></Col>
                        <Col md={12}>
                            <div className='d-flex gap-1 my-2'>
                                <img src={camera_pic} alt='image--' className='' />
                                <h6 className='text-gray pt-2'>Upload here</h6>
                            </div>
                        </Col>
                        <Col md={12}>
                            <div className='my-1'>
                                <h4>Payment Mode</h4>
                                <div className='d-flex gap-3 mt-3 flex-wrap'>
                                    <input type="radio" name='payment_mode' className='form-control rounded-4 d-none mb-1' placeholder='Enter Amount' id='cash1' />
                                    <label form='cash1' className='cash_bg px-3 py-2'>cash</label>
                                    <input type="radio" name='payment_mode' className='form-control rounded-4 d-none mb-1' placeholder='Enter Amount' id='cash2' />
                                    <label form='cash2' className='cash_bg px-3 py-2'>Credit</label>
                                    <input type="radio" name='payment_mode' className='form-control rounded-4 d-none mb-1' placeholder='Enter Amount' id='cash3' />
                                    <label form='cash3' className='cash_bg px-3 py-2'>Payed By Driver</label>
                                    <input type="radio" name='payment_mode' className='form-control rounded-4 d-none mb-1' placeholder='Enter Amount' id='cash4' />
                                    <label form='cash4' className='cash_bg px-3 py-2'>Online</label>
                                </div>
                            </div>
                        </Col>
                        <Col md={6}>
                            <h4 className=' mt-2    fw-6'>Expense Date</h4>
                            <input type="text" className='form-control rounded-4  mb-2' placeholder='12-08-2024' />
                        </Col>
                        <Col md={6}></Col>
                        <Col md={6}>
                            <h6 className='text-gray fw-5 mt-3 fw-6'>Gas Station Name</h6>
                            <input type="text" className='form-control rounded-4  mb-2' placeholder='Type Here' />
                        </Col>
                        <Col md={6}></Col>

                    </Row>

                </div>
                <div className=' d-flex justify-content-end my-3 buttons_postion gap-3'>
                    <button className="btn   py-2 border_btn px-4 rounded-4 " type="button" data-bs-toggle="offcanvas" href="#offcanvasExample5" >
                        Close
                    </button>
                    <button className="btn btn-dark px-4 py-2 rounded-4 " type="button" >
                        Save
                    </button>
                </div>
            </div >
        </>
    )
}
