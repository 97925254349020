import React, { useState } from 'react'
import Form from 'react-bootstrap/Form';
import { Container, ListGroup, Row, Col, Card } from 'react-bootstrap'
import { NavLink } from "react-router-dom";
import distance from '../../assests/new2/distance.svg';
import RightArrow from '../../assests/new2/right_arrow.svg';

export default function Trip_book() {
    return (
        <>
            <div className='h-100 offcanvas w-50 offcanvas-end md-w-100' tabIndex="-1" id="offcanvasExample3" aria-labelledby="offcanvasExampleLabel3">
                <h3 className='text-white fw-6 mb-3 bg-blue py-3 ps-5 '>Bilty Book</h3>
                <h5 className='fw-5 text-gray ps-5 mb-1'>Date Range</h5>
                <div className='d-flex justify-content-between mb-1' >
                    <div className="dropdown ps-5">
                        <button className="btn month_btn py-1 " type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                            All Months
                        </button>
                        <ul className="dropdown-menu months " aria-labelledby="dropdownMenuButton1">
                            <li className='text-blue'> Date</li>
                            <li><NavLink className="dropdown-item" href="#">Today</NavLink></li>
                            <li><NavLink className="dropdown-item" href="#">This Week</NavLink></li>
                            <li><NavLink className="dropdown-item" href="#">last Week</NavLink></li>
                            <li><NavLink className="dropdown-item" href="#">This Month</NavLink></li>
                            <li><NavLink className="dropdown-item" href="#">This Year</NavLink></li>
                            <li><NavLink className="dropdown-item" href="#">All Months</NavLink></li>



                        </ul>
                    </div>

                    <div className='d-flex justify-content-end pe-4'>
                        <button className="btn month_btn py-1 bg-btn-color " type="button">
                            View PDF
                        </button>
                    </div>

                </div>
                <Card className="pt-3  pb-2 px-2  mx-4 mt-2 mb-1 shadow_trip_card rounded-4 border-0">

                    <div className='d-flex justify-content-between'>
                        <div className=' text-center'>
                            <h4 className=' '>Total Revenue</h4>
                            <h4 className=''>₹37,50,750</h4>

                        </div>

                        <div className=' text-center'>
                            <h4 className=' '>Trip Expenses</h4>
                            <h4 className=''>₹12,000</h4>

                        </div>

                        <div className=' text-center'>
                            <h4 className=' '>Trip Profit</h4>
                            <h4 className='text-gray'>₹37,50,750</h4>

                        </div>
                    </div>

                </Card>
                <Row className=' overflowScroll'>

                    <Col md={12}>
                        <Card className="py-2 px-4  mx-4 mt-3 shadow_trip_card rounded-4 border-0 d-flex flex-row justify-content-between" >
                            <div className=''>
                                <h4>Joe Rogan</h4>
                                <div>
                                    <img src={distance} alt='image---' className='' />
                                    <div className='d-flex justify-content-between'>
                                        <h6 className='fs-3 mb-0 text-gray'>Shimla</h6>
                                        <h6 className='fs-3 text-gray'>Chennai</h6>

                                    </div>
                                </div>


                            </div>
                            <div className='d-flex flex-column justify-content-between align-items-end'>
                                <h6 className='fs-3 text-gray'>06 Aug 20234</h6>
                                <NavLink className="btn text-blue py-1  px-0  view_color  " type="button" >
                                    View
                                    <img src={RightArrow} alt='image--' className='ps-1' />
                                </NavLink>
                            </div>

                        </Card>
                    </Col>
                    <Col md={12}>
                        <Card className="py-2 px-4  mx-4 mt-3 shadow_trip_card rounded-4 border-0 d-flex flex-row justify-content-between" >
                            <div className=''>
                                <h4>Joe Rogan</h4>
                                <div>
                                    <img src={distance} alt='image---' className='' />
                                    <div className='d-flex justify-content-between'>
                                        <h6 className='fs-3 mb-0 text-gray'>Shimla</h6>
                                        <h6 className='fs-3 text-gray'>Chennai</h6>
                                    </div>
                                </div>


                            </div>
                            <div className='d-flex flex-column justify-content-between align-items-end'>
                                <h6 className='fs-3 text-gray'>06 Aug 20234</h6>
                                <NavLink className="btn py-1 view_color  px-0 view_color   " type="button" >
                                    View
                                    <img src={RightArrow} alt='image--' className='ps-1' />
                                </NavLink>
                            </div>

                        </Card>
                    </Col>
                    <Col md={12}>
                        <Card className="py-2 px-4  mx-4 mt-3 shadow_trip_card rounded-4 border-0 d-flex flex-row justify-content-between" >
                            <div className=''>
                                <h4>Joe Rogan</h4>
                                <div>
                                    <img src={distance} alt='image---' className='' />
                                    <div className='d-flex justify-content-between'>
                                        <h6 className='fs-3 mb-0 text-gray'>Shimla</h6>
                                        <h6 className='fs-3 text-gray'>Chennai</h6>

                                    </div>
                                </div>


                            </div>
                            <div className='d-flex flex-column justify-content-between align-items-end'>
                                <h6 className='fs-3 text-gray'>06 Aug 20234</h6>
                                <NavLink className="btn text-blue py-1  px-0  view_color  " type="button" >
                                    View
                                    <img src={RightArrow} alt='image--' className='ps-1' />
                                </NavLink>
                            </div>

                        </Card>
                    </Col>
                    <Col md={12}>
                        <Card className="py-2 px-4  mx-4 mt-3 shadow_trip_card rounded-4 border-0 d-flex flex-row justify-content-between" >
                            <div className=''>
                                <h4>Joe Rogan</h4>
                                <div>
                                    <img src={distance} alt='image---' className='' />
                                    <div className='d-flex justify-content-between'>
                                        <h6 className='fs-3 mb-0 text-gray'>Shimla</h6>
                                        <h6 className='fs-3 text-gray'>Chennai</h6>

                                    </div>
                                </div>


                            </div>
                            <div className='d-flex flex-column justify-content-between align-items-end'>
                                <h6 className='fs-3 text-gray'>06 Aug 20234</h6>
                                <NavLink className="btn text-blue py-1  px-0  view_color  " type="button" >
                                    View
                                    <img src={RightArrow} alt='image--' className='ps-1' />
                                </NavLink>
                            </div>

                        </Card>
                    </Col>
                    <Col md={12}>
                        <div className=' d-flex justify-content-end mt-3 pe-5 gap-3'>
                            <button className="btn   py-2 border_btn px-4 rounded-4 " type="button" data-bs-toggle="offcanvas" href="#offcanvasExample3">
                                Close
                            </button>
                            <button className="btn btn-dark px-4  py-2 rounded-4 " type="button" >
                                Add Bilty
                            </button>
                        </div>
                    </Col>


                </Row>

            </div>
        </>
    )
}
