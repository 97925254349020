import React from 'react'
import { NavLink } from "react-router-dom";
import fuel_pump from '../../assests/new2/fuel_pump.svg'
import { Card, Col, Row } from 'react-bootstrap';
import { CiEdit } from "react-icons/ci";
import { RiDeleteBin6Line } from "react-icons/ri";

export default function Fuel_book() {
    return (
        <>
            <div className='h-100 offcanvas w-50 offcanvas-end md-w-100' tabIndex="-1" id="offcanvasExample4" aria-labelledby="offcanvasExampleLabel4">
                <h3 className='text-white fw-6 mb-3 bg-blue py-3 ps-5 '>Fuel Book</h3>
                <div className='d-flex justify-content-between px-5'>
                    <h5 className='fw-5 text-gray  mb-1'>Date Range</h5>
                    <h4 className='text-blue'>(HP 03 D 0324)</h4></div>
                <div className="dropdown ps-5">
                    <button className="btn month_btn py-1 " type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                        All Months
                    </button>
                    <ul className="dropdown-menu months " aria-labelledby="dropdownMenuButton1">
                        <li className='text-blue'> Date</li>
                        <li><NavLink className="dropdown-item" href="#">Today</NavLink></li>
                        <li><NavLink className="dropdown-item" href="#">This Week</NavLink></li>
                        <li><NavLink className="dropdown-item" href="#">last Week</NavLink></li>
                        <li><NavLink className="dropdown-item" href="#">This Month</NavLink></li>
                        <li><NavLink className="dropdown-item" href="#">This Year</NavLink></li>
                        <li><NavLink className="dropdown-item" href="#">All Months</NavLink></li>
                    </ul>
                </div>
                <Row className=' overflowScroll'>
                    <Col md={12}>
                        <Card className="d-none  py-4 px-2  mx-4 mt-2 shadow_trip_card rounded-4 border-0">
                            <Row>
                                <Col md={4}>
                                    <div className=' text-center'>
                                        <h4 className=' '>Refuel Qty.</h4>
                                        <h4 className=''>50L</h4>

                                    </div>
                                </Col>
                                <Col md={4}>
                                    <div className=' text-center'>
                                        <h4 className=' '>Mileage</h4>
                                        <h4 className=''>-</h4>

                                    </div>
                                </Col>
                                <Col md={4}>
                                    <div className=' text-center'>
                                        <h4 className=' '>Total Cost</h4>
                                        <h4 className='text-gray'>₹5000</h4>

                                    </div>
                                </Col>

                            </Row>
                        </Card>
                    </Col>
                    <Col md={12}>
                        <Card className="d-none py-3 px-4  mx-4 mt-3 shadow_trip_card rounded-4 border-0 d-flex flex-column justify-content-between" >
                            <div className=' d-flex justify-content-between '>
                                <h4 className='text-gray mb-0'> Quantity  <span className='text-dark'> 50L</span></h4>
                                <div className='d-flex fs-2 '>
                                    <div><CiEdit /> <RiDeleteBin6Line className='text-danger' /></div>
                                </div>
                            </div>
                            <div>
                                <h4 className='text-gray '> Rate/Liter  <span className='text-dark'> ₹100</span></h4>

                            </div>
                            <div className=' d-flex justify-content-between mt-1'>
                                <h6 className='text-gray  fs-3'>06 Aug 20234</h6>
                                <h4 className='text-gray  ms-1 fw-5'>₹5000</h4>
                            </div>

                        </Card>
                    </Col>
                </Row>
                <div className=' d-flex  flex-column justify-content-center align-items-center h-100 '>
                    <img src={fuel_pump} alt='image--' className='driver-option-img' />
                    <h3 className='w-50 text-center mt-3 fw-5 text-gray driver-option-img-text'>Add your Fuel expenses to get monthly Truck Profit</h3>
                </div>

                <div className=' d-flex justify-content-end my-3  gap-3 buttons_postion '>
                    <button className="btn   py-2 border_btn px-4 rounded-4 " type="button" data-bs-toggle="offcanvas" href="#offcanvasExample4">
                        Close
                    </button>
                    <button className="btn btn-dark px-4  py-2 rounded-4 " type="button" data-bs-toggle="offcanvas" href="#offcanvasExample5" role="button" aria-controls="offcanvasExample5" >
                        Add Fuel Expenses
                    </button>
                </div>

            </div >
        </>
    )
}
